// типы элементов
export const elementTypes = [
    {item_uuid: 'Труба', name: 'Труба'}
]

// способ сварки
export const weldTypes = [
    {item_uuid: 'Ручная дуговая сварка', name: 'Ручная дуговая сварка'},
    {item_uuid: 'Автоматическая сварка', name: 'Автоматическая сварка'},
    {item_uuid: 'Полуавтоматическая сварка', name: 'Полуавтоматическая сварка'},
    {item_uuid: 'Аргоновая сварка', name: 'Аргоновая сварка'},
]

// типы дефектов
export const defectTypes = [
    {item_uuid: 'Aa', name: 'Aa', caption: 'Пора'},
    {item_uuid: 'Ba', name: 'Ba', caption: 'Неметаллические (шлаковые) включения'},
]

export const weldQualities:string[] = [
    'A',
    'B',
    'C'
]

// справочник по диаметрам для труб и участков
export const diameters:number[] = [
    1720,
    1420,
    1220,
    1120,
    1020,
    920,
    820,
    720,
    630,
    530,
    426,
    377,
    325,
    273,
    219,
    159,
    118,
    108,
    89,
    57,
    32,
    23,
    14
]