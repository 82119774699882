import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRadiographyCumulative } from './actions';
import options from './options';

const { name } = options;

type RadiographyCumulativeState = {
    isLoading: boolean
    error: string,
    radiographyCumulative:any,
};

const initialState: RadiographyCumulativeState = {
    isLoading: false,
    error: '',
    radiographyCumulative: null
};

export const radiographyCumulativeSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearWeldbookCumulative(state) {
            state.radiographyCumulative = null;
        },
    },
    extraReducers: {
        [getRadiographyCumulative.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getRadiographyCumulative.fulfilled.type]: (state, {payload}: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.radiographyCumulative = payload.results
        },
        [getRadiographyCumulative.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
    }
});

export default radiographyCumulativeSlice.reducer;
