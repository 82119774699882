import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { Container } from '@material-ui/core';

type AppContainerPropsType = {
    children: React.ReactElement
};

const useStyles = makeStyles(() => ({
    mainContainer: {
        width: 'inherit',
        flex: 1,
    },
}));

export const AppContainer: React.FC<AppContainerPropsType> = ({ children }) => {
    const classes = useStyles();

    return (
        <Container
            component="div"
            className={classes.mainContainer}
            maxWidth="xl"
        >
            {children}
        </Container>
    );
};
