import axios from 'axios';

const instance = axios.create({
    baseURL: '',
    responseType: 'json',
});

const HttpClient = {
    instance,
    async get(...args: any[]) {
        try {
            // @ts-ignore
            const res = await instance.get.apply(this, args);
            // если получаем блоб - нужен весь ответ
            // @ts-ignore
            return args[1]?.responseType === 'blob' ? res : res.data;
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error('[API ERROR]', e);
            throw e;
        }
    },
    async post(...args: any[]) {
        try {
            // @ts-ignore
            const res = await instance.post.apply(this, args);
            // если получаем блоб - нужен весь ответ
            // @ts-ignore
            return args[1]?.responseType === 'blob' ? res : res.data;
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error('[API ERROR]', e);
            throw e;
        }
    },
    async put(...args: any[]) {
        try {
            // @ts-ignore
            const res = await instance.put.apply(this, args);
            // @ts-ignore
            return res.data;
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error('[API ERROR]', e);
            throw e;
        }
    },
    async patch(...args: any[]) {
        try {
            // @ts-ignore
            const res = await instance.patch.apply(this, args);

            // @ts-ignore
            return res.data;
        } catch (e) {
            // tslint:disable-next-line:no-console
            // console.error('[API ERROR]', e);
            throw e;
        }
    },
    async delete(...args: any[]) {
        try {
            // @ts-ignore
            const res = await instance.delete.apply(this, args);
            // @ts-ignore
            return res.data;
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error('[API ERROR]', e);
            throw e;
        }
    },
};

export default HttpClient;
