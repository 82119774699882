import React, { ReactElement, useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import {
    DictWorkerBaseType,
    DictWorkerResponseType,
} from 'models/dictionaries/dict-worker/types';
import {
    DictWorkGroupResponseType,
    WorkgroupTableDataType,
} from 'models/dictionaries/dict-workgroup/types';
import { getSectorsListByDictWorkGroup } from 'models/objects/sector/api';
import { SectorResponseType } from 'models/objects/sector/types';
import { WorkgroupBaseType } from 'models/objects/workgroup/types';

interface IWorkGroupDialogProps {
    workerList: DictWorkerResponseType[];
    formData: any;
    workgroupList: DictWorkGroupResponseType[];
    onClose: () => void;
    onCreate?: (item: WorkgroupTableDataType, sectorsData: any) => void;
    onUpdate?: (
        item_uuid: string,
        item: WorkgroupTableDataType,
        sectorsData: any
    ) => void;
    sectorsList: any[];
}

const dictWorkGroupInitialValues: WorkgroupTableDataType = {
    name: '',
    pressmark: '',
    chief: null,
    brigadier: null,
    welders: [],
};

export const WorkgroupDialog = ({
    workerList,
    formData,
    workgroupList,
    onClose,
    onCreate,
    onUpdate,
    sectorsList,
}: IWorkGroupDialogProps): ReactElement => {
    const [visible, setVisible] = useState<boolean>(false);
    const [chiefList, setChiefList] = useState<DictWorkerResponseType[]>([]);
    const [brigadierList, setBrigadierList] = useState<
        DictWorkerResponseType[]
    >([]);
    const [selectedSectors, setSelectedSectors] = useState<any[]>([]);
    const [savedSectors, setSavedSectors] = useState<any[]>([]);
    const [welderList, setWelderList] = useState<DictWorkerResponseType[]>([]);

    const formik = useFormik({
        initialValues: formData || dictWorkGroupInitialValues,
        validateOnBlur: true,
        validate: (data) => {
            const errors: any = {};
            if (data.name.trim() === '') {
                errors.name = 'Обязательное поле ввода';
            }
            if (data.name.length > 255) {
                errors.name =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }
            if (data.pressmark.length > 255) {
                errors.pressmark =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            const filtereredWorkgroupList = formData
                ? workgroupList.filter(
                      (w: DictWorkGroupResponseType) =>
                          w.item_uuid !== data.item_uuid
                  )
                : workgroupList;

            if (
                data.name?.trim() &&
                filtereredWorkgroupList.some(
                    (w: DictWorkGroupResponseType) =>
                        w.name === data.name.trim()
                )
            ) {
                errors.name = 'Такая бригада уже существует';
            }
            return errors;
        },
        onSubmit: (values: any) => {
            const dictWgData = { ...values };
            delete dictWgData.sectors;
            if (formData) {
                onUpdate &&
                    onUpdate(
                        formData.item_uuid,
                        dictWgData,
                        compareSectorsToSaveWg(false)
                    );
            } else {
                onCreate && onCreate(dictWgData, compareSectorsToSaveWg(true));
            }
        },
    });

    const compareSectorsToSaveWg = (isNewDictWorkgroup: boolean) => {
        // подготовка данных для добавления и удаления бригад научастках
        const sectorsData: any = {
            dataToCreate: [],
            dataToDelete: [],
        };

        if (isNewDictWorkgroup) {
            // если справочник вновь созданный, то бригады на участках только добавляем
            for (const sector of selectedSectors) {
                const newWorkGroup: WorkgroupBaseType = {
                    ...formik.values,
                    sector_item: sector.item_uuid,
                };
                sectorsData.dataToCreate.push(newWorkGroup);
            }
        } else {
            // поиск участков, из которых нужно удалить бригады
            for (const sector of savedSectors) {
                if (
                    !selectedSectors.some(
                        (s) => s.sectorName === sector.sectorName
                    )
                ) {
                    sectorsData.dataToDelete.push(sector);
                }
            }
            // поиск участков, в которые нужно добавить бригады
            for (const sector of selectedSectors) {
                if (
                    !savedSectors.some(
                        (s) => s.sectorName === sector.sectorName
                    )
                ) {
                    const newWorkGroup: WorkgroupBaseType = {
                        ...formik.values,
                        sector_item: sector.item_uuid,
                    };
                    sectorsData.dataToCreate.push(newWorkGroup);
                }
            }
        }
        return sectorsData;
    };

    const isFormFieldInvalid = (name: any) =>
        !!(
            formik.touched[name as keyof DictWorkerBaseType] &&
            formik.errors[name as keyof DictWorkerBaseType]
        );

    const isFormInvalid = () => {
        if (
            formik.values.name?.trim() &&
            !formik.errors.name &&
            formik.values.chief &&
            formik.values.brigadier &&
            formik.values.welders.length > 0 &&
            formik.values.pressmark?.length <= 255
        ) {
            return false;
        } else {
            return true;
        }
    };

    const workerTemplate = (
        option: DictWorkerResponseType,
        workerposition: string
    ) => {
        if (option) {
            if (workerposition === 'chief') {
                return (
                    <div className="flex align-items-center">
                        <div>{`${option.second_name} ${option.first_name} ${
                            option.third_name ?? ''
                        }`}</div>
                    </div>
                );
            } else if (
                workerposition === 'welder' ||
                workerposition === 'brigadier'
            ) {
                return (
                    <div className="flex align-items-center">
                        <div>{`${option.stamp} - ${option.second_name} ${
                            option.first_name
                        } ${option.third_name ?? ''}`}</div>
                    </div>
                );
            } else {
                return '';
            }
        }
        return '';
    };

    const sectorTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{`${option.sectorName}`}</div>
            </div>
        );
    };

    const handleEditSelectedSectors = (e: MultiSelectChangeEvent) => {
        e.stopPropagation();
        setSelectedSectors(e.value);
    };

    useEffect(() => {
        if (workerList.length > 0) {
            setChiefList(
                workerList.filter(
                    (w: DictWorkerResponseType) => w.position === 'master'
                )
            );
            setBrigadierList(
                workerList.filter(
                    (w: DictWorkerResponseType) => w.position === 'brigadier'
                )
            );
            setWelderList(
                workerList.filter(
                    (w: DictWorkerResponseType) => w.position === 'welder'
                )
            );
        }

        setVisible(true);
    }, []);

    useEffect(() => {
        if (sectorsList.length > 0 && formData) {
            const sectorsByDictWg: any[] = [];
            getSectorsListByDictWorkGroup(formData.item_uuid).then(
                (response: any) => {
                    if (response.results.length > 0) {
                        response.results.forEach(
                            (sector: SectorResponseType) => {
                                const sectorByWg = sectorsList.filter(
                                    (s: any) => s.item_uuid === sector.item_uuid
                                )[0];
                                if (sectorByWg)
                                    sectorsByDictWg.push(sectorByWg);
                            }
                        );
                    }
                    setSelectedSectors(sectorsByDictWg);
                    setSavedSectors([...sectorsByDictWg]);
                }
            );
        }
    }, [sectorsList, formData]);

    return (
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
            <Dialog
                header={`${formData ? 'Редактирование' : 'Добавление'} бригады`}
                visible={visible}
                style={{ width: '40vw' }}
                onHide={onClose}
            >
                <div className="grid align-items-end">
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="name">
                                Название бригады{' '}
                                <span className="required">*</span>
                            </label>
                            <InputText
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'name',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('name'),
                                })}
                                tooltip={formik.errors.name as string}
                                tooltipOptions={{
                                    disabled: !isFormFieldInvalid('name'),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="pressmark">Шифр</label>
                            <InputText
                                id="pressmark"
                                name="pressmark"
                                value={formik.values.pressmark}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'pressmark',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('pressmark'),
                                })}
                                tooltip={formik.errors.pressmark as string}
                                tooltipOptions={{
                                    disabled: !isFormFieldInvalid('pressmark'),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="chief">
                                Отв.исполнитель{' '}
                                <span className="required">*</span>
                            </label>
                            <Dropdown
                                value={formik.values.chief}
                                onChange={(e) =>
                                    formik.setFieldValue('chief', e.value)
                                }
                                options={chiefList}
                                placeholder="Выберите ответственного исполнителя"
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('chief'),
                                })}
                                emptyMessage="Ответственные исполнители отсутствуют"
                                optionLabel="second_name"
                                itemTemplate={(item) =>
                                    workerTemplate(item, 'chief')
                                }
                                valueTemplate={(item) =>
                                    workerTemplate(item, 'chief')
                                }
                                appendTo="self"
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="brigadier">
                                Бригадир <span className="required">*</span>
                            </label>
                            <Dropdown
                                value={formik.values.brigadier}
                                onChange={(e) =>
                                    formik.setFieldValue('brigadier', e.value)
                                }
                                options={brigadierList}
                                placeholder="Выберите бригадира"
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('brigadier'),
                                })}
                                emptyMessage="Бригадиры отсутствуют"
                                optionLabel="second_name"
                                itemTemplate={(item) =>
                                    workerTemplate(item, 'brigadier')
                                }
                                valueTemplate={(item) =>
                                    workerTemplate(item, 'brigadier')
                                }
                                appendTo="self"
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="welders">
                                Сварщики <span className="required">*</span>
                            </label>
                            <MultiSelect
                                value={formik.values.welders}
                                onChange={(e: MultiSelectChangeEvent) => {
                                    e.stopPropagation();
                                    formik.setFieldValue('welders', e.value);
                                }}
                                options={welderList}
                                display="chip"
                                placeholder="Выберите сварщиков"
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('welder'),
                                })}
                                optionLabel="second_name"
                                itemTemplate={(item) =>
                                    workerTemplate(item, 'welder')
                                }
                                filter
                                filterBy="stamp,second_name,first_name,third_name"
                                filterMatchMode="contains"
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="sectors">Участки</label>
                            <MultiSelect
                                value={selectedSectors}
                                onChange={(e: MultiSelectChangeEvent) =>
                                    handleEditSelectedSectors(e)
                                }
                                options={sectorsList}
                                display="chip"
                                placeholder={
                                    sectorsList.length === 0
                                        ? 'Загрузка участков...'
                                        : 'Выберите участок'
                                }
                                optionLabel="sectorName"
                                itemTemplate={(item) => sectorTemplate(item)}
                                filter
                                filterBy="sectorName"
                                filterMatchMode="contains"
                                disabled={sectorsList.length === 0}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-content-end mt-3">
                    <Button
                        outlined
                        onClick={onClose}
                        label="Отмена"
                        className="mr-3"
                    />
                    {!formData && (
                        <Button
                            type="submit"
                            label="Добавить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                    {formData && (
                        <Button
                            type="submit"
                            label="Сохранить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                </div>
            </Dialog>
        </form>
    );
};
