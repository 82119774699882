import request from 'utils/http/request';
import {JobBaseType, JobResponseType} from './types';
import { getUrl } from '../../utils';
import options from './options';
import {IPagingSearch} from '../../../interfaces';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getJobsListApi = async (weldbook_uuid:string): Promise<IPagingSearch<JobResponseType>> => {
    return request.get(url(`?weldbook_item=${weldbook_uuid}`));
};

export const getJobByIdApi = async (item_uuid: string): Promise<any> => {
    return request.get(url(`${item_uuid}/`));
}

export const createJobApi = async (body: JobBaseType): Promise<JobResponseType> => {
    return request.post(url(''), body);
}

export const deleteJobApi = async (item_uuid: string): Promise<any> => {
    return request.delete(url(`${item_uuid}/`));
}

export const updateJobApi = async (item_uuid: string, body: JobBaseType): Promise<JobResponseType> => {
    return request.patch(url(`${item_uuid}/`), body);
}
