import request from 'utils/http/request';
import {
    DictRadiographyEquipBaseType,
    DictRadiographyEquipResponseType,
} from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;
const url = getUrl(apiUrl);

export const getDictRadiographyEquipListApi = async (): Promise<
    DictRadiographyEquipResponseType[]
> => {
    return request.get(url(''));
};

export const getDictRadiographyEquipByIdApi = async (
    equip_id: string
): Promise<DictRadiographyEquipResponseType> => {
    return request.get(url(equip_id));
};

export const createDictRadiographyEquipApi = async (
    body: DictRadiographyEquipBaseType
): Promise<DictRadiographyEquipResponseType> => {
    return request.post(url(''), body);
};

export const updateDictRadiographyEquipApi = async (
    uuid: string,
    body: DictRadiographyEquipBaseType
): Promise<DictRadiographyEquipResponseType> => {
    return request.patch(url(`${uuid}/`), body);
};

export const deleteDictRadiographyEquipApi = async (
    uuid: string
): Promise<any> => {
    return request.delete(url(`${uuid}/`));
};
