import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getDictTechnicalSheetListApi,
    getDictTechnicalSheetByIdApi,
    createDictTechnicalSheetApi,
    updateDictTechnicalSheetApi,
    deleteDictTechnicalSheetApi,
} from './api';
import options from './options';
import { DictTechnicalSheetBaseType } from './types';

const { name } = options;

export const getDictTechnicalSheetList = createAsyncThunk(
    `${name}/getDictTechnicalSheetList`,
    async (_, thunkApi) => {
        try {
            return await getDictTechnicalSheetListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getDictTechnicalSheetById = createAsyncThunk(
    `${name}/getDictTechnicalSheetById`,
    async (object_id: string, thunkApi) => {
        try {
            return await getDictTechnicalSheetByIdApi(object_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createDictTechnicalSheet = createAsyncThunk(
    `${name}/createDictTechnicalSheet`,
    async (body: DictTechnicalSheetBaseType, thunkApi) => {
        try {
            return await createDictTechnicalSheetApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateDictTechnicalSheet = createAsyncThunk(
    `${name}/updateDictTechnicalSheet`,
    async (
        { uuid, body }: { uuid: string; body: DictTechnicalSheetBaseType },
        thunkApi
    ) => {
        try {
            return await updateDictTechnicalSheetApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDictTechnicalSheet = createAsyncThunk(
    `${name}/deleteDictTechnicalSheet`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteDictTechnicalSheetApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
