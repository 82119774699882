import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDictWorkGroupListApi, getDictWorkGroupByIdApi, createDictWorkGroupApi, updateDictWorkGroupApi, deleteDictWorkGroupApi 
} from './api';
import options from './options';
import {DictWorkGroupBaseType} from "./types";

const {name} = options

export const getDictWorkGroupList = createAsyncThunk(
    `${name}/getDictWorkGroupList`,
    async (_, thunkApi) => {
        try {
            return await getDictWorkGroupListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getDictWorkGroupById = createAsyncThunk(
    `${name}/getDictWorkGroupById`,
    async (object_id:string, thunkApi) => {
        try {
            return await getDictWorkGroupByIdApi(object_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createDictWorkGroup = createAsyncThunk(
    `${name}/createDictWorkGroup`,
    async (body: DictWorkGroupBaseType, thunkApi) => {
        try {
            return await createDictWorkGroupApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateDictWorkGroup = createAsyncThunk(
    `${name}/updateDictWorkGroup`,
    async ({ uuid, body }: { uuid: string, body: DictWorkGroupBaseType }, thunkApi) => {
        try {
            return await updateDictWorkGroupApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDictWorkGroup = createAsyncThunk(
    `${name}/deleteDictWorkGroup`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteDictWorkGroupApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
