import React, { useContext, useRef, useState, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { buildingInfo } from './building-info';

import AuthContext from '../../contexts/auth';
import { MenuItem } from 'primereact/menuitem';
const fs = require('fs');

export const UserMenu: React.FC = () => {
    const menu = useRef<Menu>(null);
    const { onLogout } = useContext(AuthContext);
    const [items, setItems] = useState<MenuItem[]>([
        {
            label: 'Выход',
            icon: 'pi pi-sign-out',
            command: () => onLogoutClick(),
        },
    ]);
    const onLogoutClick = () => {
        onLogout && onLogout();
    };

    useEffect(() => {
        // const data = fs.readFileSync('package.json');
        // const jsonData = JSON.parse(data);
        // console.log(jsonData);
        if (buildingInfo.build_id) {
            const newItems = [...items];
            newItems.unshift({
                label: `Номер сборки: ${buildingInfo.build_id}`,
                disabled: true,
            });
            setItems(newItems);
        }
    }, []);

    return (
        <div>
            <i
                className="pi pi-user cursor-pointer text-700"
                onClick={(event) => menu!.current!.toggle(event)}
            />
            <Menu
                model={items}
                popup
                ref={menu}
                id="user-menu"
                popupAlignment="left"
            />
        </div>
    );
};
