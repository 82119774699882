import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPagingSearch } from 'interfaces';
import { getWorkgroupsList, getWorkgroupById, createWorkgroup, updateWorkgroup, deleteWorkgroup } from './actions';
import options from './options';
import { WorkgroupResponseType } from './types';

const { name } = options;

type WorkgroupState = {
    isLoading: boolean
    isTreeUpdate:boolean
    error: string,
    workgroupList:WorkgroupResponseType[],
    workgroupById: null | WorkgroupResponseType
};

const initialState: WorkgroupState = {
    isLoading: false,
    isTreeUpdate: false,
    error: '',
    workgroupList: [],
    workgroupById: null
};

export const workgroupSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearWorkgroupsList(state) {
            state.workgroupList = [];
        },
        clearWorkgroupById(state) {
            state.workgroupById = null;
        }
    },
    extraReducers: {
        [getWorkgroupsList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getWorkgroupsList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<WorkgroupResponseType>>) => {
            state.isLoading = false;
            state.workgroupList = payload.results;
            state.error = '';
        },
        [getWorkgroupsList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.workgroupList = [];
            state.error = payload;
        },
        [getWorkgroupById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getWorkgroupById.fulfilled.type]: (state, { payload }: PayloadAction<WorkgroupResponseType>) => {
            state.isLoading = false;
            state.workgroupById = payload;
            state.error = '';
        },
        [getWorkgroupById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.workgroupById = null;
            state.error = payload;
        },
        [createWorkgroup.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false
        },
        [createWorkgroup.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
            state.isTreeUpdate = true
        },
        [createWorkgroup.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateWorkgroup.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false
        },
        [updateWorkgroup.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
            state.isTreeUpdate = true
        },
        [updateWorkgroup.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteWorkgroup.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false
        },
        [deleteWorkgroup.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
            state.isTreeUpdate = true
        },
        [deleteWorkgroup.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default workgroupSlice.reducer;
