import request from 'utils/http/request';
import { WorkgroupBaseType, WorkgroupResponseType } from './types';
import { getUrl } from '../../utils';
import options from './options';
import {IPagingSearch} from "../../../interfaces";

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getWorkgroupListApi = async (sector_id:string): Promise<IPagingSearch<WorkgroupResponseType>> => {
    return request.get(url(`?sector_item=${sector_id}`));
}

export const getWorkgroupByIdApi = async (workgroup_id:string): Promise<WorkgroupResponseType> => {
    return request.get(url(workgroup_id));
};

export const createWorkgroupApi = async (body: WorkgroupBaseType): Promise<WorkgroupResponseType> => {
    return request.post(url(''), body);
}

export const updateWorkgroupApi = async (uuid: string, body: WorkgroupBaseType): Promise<WorkgroupResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteWorkgroupApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}

export const getAllWorkgroupsListApi = async (): Promise<WorkgroupResponseType[]> => {
    return request.get(url(''));
}