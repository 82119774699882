import request from 'utils/http/request';
import {ObjectBaseType, ObjectResponseType} from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getObjectsListApi = async (): Promise<ObjectResponseType[]> => {
    return request.get(url(''));
}

export const getObjectByIdApi = async (object_id:string): Promise<ObjectResponseType> => {
    return request.get(url(object_id));
};

export const createObjectApi = async (body: ObjectBaseType): Promise<ObjectResponseType> => {
    return request.post(url(''), body);
}

export const updateObjectApi = async (uuid: string, body: ObjectBaseType): Promise<ObjectResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteObjectApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}
