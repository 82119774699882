import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSeamByRadiographyIdApi, createSeamApi, deleteSeamApi } from "./api";
import options from './options';
import { SeamBaseType } from './types';

const { name } = options;

export const getSeamByRadiographyId = createAsyncThunk(
    `${name}/getSeamByRadiographyId`,
    async (radiography_id: string, { rejectWithValue }) => {
        try {
            return await getSeamByRadiographyIdApi(radiography_id);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);

export const createSeam = createAsyncThunk(
    `${name}/createSeam`,
    async (body: SeamBaseType, thunkApi) => {
        try {
            return await createSeamApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteSeam = createAsyncThunk(
    `${name}/deleteSeam`,
    async (seam_id: string, { rejectWithValue }) => {
        try {
            return await deleteSeamApi(seam_id);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);