import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWeldbookReportApi } from './api';
import options from './options';

const { name } = options;

export const getWeldbookReport = createAsyncThunk(
    `${name}/getWeldbookReport`,
    async (body: any, thunkApi) => {
        try {
            return await getWeldbookReportApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);