import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferencesResponseType } from './types';
import { getReferencesByWeldQualityId } from './actions';
import options from './options';

const { name } = options;

type WeldState = {
    isLoading: boolean
    references: ReferencesResponseType[] | null
    error: string
};

const initialState: WeldState = {
    isLoading: false,
    references: [],
    error: ''
};

export const referencesSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [getReferencesByWeldQualityId.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getReferencesByWeldQualityId.fulfilled.type]: (state, action: PayloadAction<ReferencesResponseType[]>) => {
            state.isLoading = false;
            state.references = action.payload;
            state.error = '';
        },
        [getReferencesByWeldQualityId.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.references = [];
            state.error = action.payload;
        }
    }
});

export default referencesSlice.reducer;
