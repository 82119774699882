import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import {generateConclusiontApi} from "./api";
import {ConclusionType} from "./types";

const { name } = options;


export const generateConclusion = createAsyncThunk(
    `${name}/generateConclusion`,
    async (body: ConclusionType, thunkApi) => {
        try {
            return await generateConclusiontApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);