import React, { useEffect, useState } from 'react';
import { AppBar, makeStyles, Theme, Toolbar } from '@material-ui/core';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import { Link } from 'react-router-dom';
import { WbBreadcrumbs } from 'components';

import { useAppSelector } from 'store/hooks';
import { selectObjectState } from 'models/objects/object/selectors';
import { selectSectorState } from 'models/objects/sector/selectors';
import { selectWeldBookState } from 'models/weldbooks/weldbook/selectors';
import { selectRadiographyState } from 'models/weldbooks/radiography/selectors';
import { AppContainer } from '../app-container/app-container';
import { UserMenu } from './user-menu';
import { MainMenu } from './main-menu';
import { getJobByIdApi } from 'models/weldbooks/job/api';
import { getWeldBookByIdApi } from 'models/weldbooks/weldbook/api';
import { getSectorByIdApi } from 'models/objects/sector/api';
import { selectWorkgroupState } from 'models/objects/workgroup/selectors';
import { displayNumberByUser } from 'pages/gas-pipeline/pages/weldbook/forms/helpers';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    title: {
        flexGrow: 1,
    },
    barContainer: {
        backgroundColor: 'var(--main-surface-background)',
        borderBottom: '1px solid var(--divider-tabs)',
        boxShadow: 'none',
        position: 'relative',
        zIndex: 1,
    },
}));

export const AppTopBar: React.FC = () => {
    const classes = useStyles();
    const [items, setItems] = useState<MenuItem[]>([]);
    const { objectById, isLoading: isObjectLoading } =
        useAppSelector(selectObjectState);
    const { sectorById, isLoading: isSectorLoading } =
        useAppSelector(selectSectorState);
    const { workgroupById, isLoading: workgroupIsLoading } =
        useAppSelector(selectWorkgroupState);
    const {
        weldBookDetail,
        isLoading: isweldBookLoading,
        isTreeUpdate: isBreadCrumbUpdate,
    } = useAppSelector(selectWeldBookState);
    const { radiographyById } = useAppSelector(selectRadiographyState);
    const page = window.location.href;

    const navigateItemTemplate = (item: MenuItem, options: MenuItemOptions) => (
        <Link color="inherit" to={item.url!} key={item.label}>
            {item.label}
        </Link>
    );

    const listenPages = async () => {
        // получаем родительский объект строительства
        if (objectById && page.indexOf('gas-pipeline/sectors') !== -1) {
            setItems([
                {
                    label: 'Объекты',
                    url: '/gas-pipeline',
                    template: navigateItemTemplate,
                },
                { label: ` Объект ${objectById.name}` },
            ]);
        } else if (sectorById && page.indexOf('gas-pipeline/weldbook') !== -1) {
            let data: any = [
                {
                    label: 'Объекты',
                    url: '/gas-pipeline',
                    template: navigateItemTemplate,
                },
                {
                    label: `Объект ${sectorById.object_item.name}`,
                    url: `/gas-pipeline/sectors?object_id=${
                        sectorById ? sectorById.object_item.item_uuid : ''
                    }`,
                    template: navigateItemTemplate,
                },
            ];
            if (page.indexOf('?workgroup_id=') !== -1 && workgroupById) {
                data.push({
                    label: `Участок ${sectorById.begin! / 1000} - ${
                        sectorById.end! / 1000
                    }`,
                    url: `/gas-pipeline/weldbook?sector_id=${
                        sectorById ? sectorById.item_uuid : ''
                    }`,
                    template: navigateItemTemplate,
                });
                data.push({
                    label: `Бригада ${workgroupById?.name} - ${workgroupById?.brigadier}`,
                });
            } else {
                data.push({
                    label: `Участок ${sectorById.begin! / 1000} - ${
                        sectorById.end! / 1000
                    }`,
                });
            }
            setItems(data);
        } else if (
            weldBookDetail &&
            sectorById &&
            page.indexOf('gas-pipeline/passport') !== -1
        ) {
            setItems([
                {
                    label: 'Объекты',
                    url: '/gas-pipeline',
                    template: navigateItemTemplate,
                },
                {
                    label: `Объект ${sectorById.object_item.name}`,
                    url: `/gas-pipeline/sectors?object_id=${
                        sectorById ? sectorById.object_item.item_uuid : ''
                    }`,
                    template: navigateItemTemplate,
                },
                {
                    label: `Участок ${sectorById.begin! / 1000} - ${
                        sectorById.end! / 1000
                    }`,
                    url: `/gas-pipeline/weldbook?sector_id=${
                        sectorById ? sectorById.item_uuid : ''
                    }`,
                    template: navigateItemTemplate,
                },
                { label: `КСС № ${displayNumberByUser(weldBookDetail)}` },
            ]);
        } else if (radiographyById && page.indexOf('editor') !== -1) {
            const job = await getJobByIdApi(radiographyById.job_item);
            const wb = await getWeldBookByIdApi(job.weldbook_item);
            const sector = await getSectorByIdApi(wb.sector_item!);

            setItems([
                {
                    label: 'Объекты',
                    url: '/gas-pipeline',
                    template: navigateItemTemplate,
                },
                {
                    label: `Участки ${sector.object_item.name}`,
                    url: `/gas-pipeline/sectors?object_id=${
                        sector ? sector.object_item.item_uuid : ''
                    }`,
                    template: navigateItemTemplate,
                },
                { label: `...` },
                {
                    label: `КСС № ${displayNumberByUser(wb)}`,
                    url: `/gas-pipeline/passport?weldbook_id=${wb.item_uuid}&sector_id=${wb.sector_item}`,
                    template: navigateItemTemplate,
                },
                { label: radiographyById.original_file_name },
            ]);
        } else {
            setItems([]);
        }
    };
    useEffect(() => {
        listenPages();
    }, [
        objectById,
        sectorById,
        weldBookDetail,
        isBreadCrumbUpdate,
        radiographyById,
        workgroupById,
    ]);

    return (
        <AppBar position="static" className={classes.barContainer}>
            <AppContainer>
                <Toolbar disableGutters>
                    <MainMenu />
                    <WbBreadcrumbs items={items} />
                    <UserMenu />
                </Toolbar>
            </AppContainer>
        </AppBar>
    );
};
