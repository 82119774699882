import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDataImageByRadiographyIdApi, createDataImageApi, deleteDataImageApi } from "./api";
import options from './options';
import { DataImageBaseType } from './types';

const { name } = options;

export const getDataImageByRadiographyId = createAsyncThunk(
    `${name}/getDataImage`,
    async (radiography_id: string, { rejectWithValue }) => {
        try {
            return await getDataImageByRadiographyIdApi(radiography_id);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);

export const createDataImage = createAsyncThunk(
    `${name}/createDataImage`,
    async (body: DataImageBaseType, thunkApi) => {
        try {
            return await createDataImageApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDataImage = createAsyncThunk(
    `${name}/deleteDataImage`,
    async (item_uuid: string, { rejectWithValue }) => {
        try {
            return await deleteDataImageApi(item_uuid);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);