import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import {Autocomplete} from "@material-ui/lab";
import TextField from "components/text-field/text-field";
import {ReactElement} from "react";

interface ITableAutocompleteProps {
    cell: any
    options: any[]
    valueProperty?: string
    captionProperty?: string
    editFieldClassname?: string
    autocompleteClassname?: string
    width?: string
}

const styles = () => ({
    autocomplete: {
        "& .MuiAutocomplete-paper": {
            fontSize: "inherit"
        },
        "& .MuiAutocomplete-inputRoot": {
            padding: "2px !important",
            paddingRight: "50px !important"
        },
        "& .MuiFormControl-root": {
            margin: "8px"
        },
        "& .MuiInput-root:before": {
            // borderBottom: (error: any) => error ? "2px solid red" : ""
        }
    },
    editField: {
        "marginLeft": "0 !important",
        "& .MuiInputBase-root": {
            fontSize: "0.875rem"
        }
    }
});

const useStyles = makeStyles(styles);

export const TableAutocomplete = ({
                                              cell,
                                              options,
                                              valueProperty = "item_uuid",
                                              captionProperty= "name",
                                              editFieldClassname,
                                              autocompleteClassname,
                                              width = "100%"

                                          }: ITableAutocompleteProps): ReactElement => {
    const classes = useStyles(cell.error);

    const getCellValue = (val: any) => options.find((elem: any) => elem[valueProperty] === val);

    const onTagsChange = (e: any, value: any) => {
        cell.onChange(value ? value[valueProperty] : undefined);
    }

    const rootClassName = clsx(
        classes.autocomplete,
        autocompleteClassname,
    );

    const editClassName = clsx(
        classes.editField,
        editFieldClassname,
    );

    return (
        <div style={{ width }}>
            <Autocomplete
                className={rootClassName}
                size="small"
                value={getCellValue(cell.value) || ""}
                options={options}
                getOptionLabel={(option) => option[captionProperty] || ""}
                onChange={onTagsChange}
                disableClearable={!!cell.error}
                noOptionsText = "Нет совпадений"
                renderInput={(params) =>
                    <TextField
                        {...params}
                        error={cell.error}
                        variant="standard"
                        className={editClassName}
                    />}
            />
        </div>
    )
}
