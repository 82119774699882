import {createAsyncThunk} from '@reduxjs/toolkit';
import {getResponseErrorMessage} from 'utils/http/utils';
import * as dicomDataApi from './api';

export const getDicomData = createAsyncThunk(
    'http://10.100.9.81:30153/stateless/data',
    async (body: any, thunkApi) => {
        try {
            return await dicomDataApi.getDicomDataApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(getResponseErrorMessage(e));
        }
    }
);