import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import { generateRegistyApi } from './api';
import { RegistryType } from './types';

const { name } = options;

export const generateRegisty = createAsyncThunk(
    `${name}/generateRegisty`,
    async (body: RegistryType, thunkApi) => {
        try {
            return await generateRegistyApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
