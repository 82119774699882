import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDictFactoryIndexListApi, getDictFactoryIndexByIdApi, createDictFactoryIndexApi, updateDictFactoryIndexApi, deleteDictFactoryIndexApi 
} from './api';
import options from './options';
import {DictFactoryIndexBaseType} from "./types";

const {name} = options

export const getDictFactoryIndexList = createAsyncThunk(
    `${name}/getDictFactoryIndexList`,
    async (_, thunkApi) => {
        try {
            return await getDictFactoryIndexListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getDictFactoryIndexById = createAsyncThunk(
    `${name}/getDictFactoryIndexById`,
    async (pipe_id:string, thunkApi) => {
        try {
            return await getDictFactoryIndexByIdApi(pipe_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createDictFactoryIndex = createAsyncThunk(
    `${name}/createDictFactoryIndex`,
    async (body: DictFactoryIndexBaseType, thunkApi) => {
        try {
            return await createDictFactoryIndexApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateDictFactoryIndex = createAsyncThunk(
    `${name}/updateDictFactoryIndex`,
    async ({ uuid, body }: { uuid: string, body: DictFactoryIndexBaseType }, thunkApi) => {
        try {
            return await updateDictFactoryIndexApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDictFactoryIndex = createAsyncThunk(
    `${name}/deleteDictFactoryIndex`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteDictFactoryIndexApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
