import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefectsByRadiographyId } from './actions';
import options from './options';
import { DefectBaseType, DefectResponseType } from './types';

const { name } = options;

type DefectState = {
    isLoading: boolean
    defects: DefectResponseType | null,
    error: string
};

const initialState: DefectState = {
    isLoading: false,
    defects: null,
    error: ''
};

export const defectSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [getDefectsByRadiographyId.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDefectsByRadiographyId.fulfilled.type]: (state, action: PayloadAction<DefectResponseType>) => {
            state.isLoading = false;
            state.defects = action.payload;
            state.error = '';
        },
        [getDefectsByRadiographyId.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.defects = null;
            state.error = action.payload;
        }
    }
});

export default defectSlice.reducer;
