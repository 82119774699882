import request from 'utils/http/request';
import { ReferencesBaseType, ReferencesResponseType } from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getReferencesByWeldQualityIdApi = async (weldQualityId: string): Promise<any> => {
    return request.get(url(`?weld_quality_item=${weldQualityId}`));
}

export const createReferenceApi = async (body: ReferencesBaseType): Promise<ReferencesResponseType> => {
    return request.post(url(''), body);
}

export const deleteReferenceApi = async (reference: string): Promise<any> => {
    return request.delete(url(`${reference}/`));
}