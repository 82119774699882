import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { DictWorkGroupResponseType } from './types';
import {
    createDictWorkGroup, deleteDictWorkGroup, getDictWorkGroupById, getDictWorkGroupList, updateDictWorkGroup
} from "./actions";
import { IPagingSearch } from "../../../interfaces";

const {name} = options

type DictWorkgroupState = {
    dictWorkgroupList:DictWorkGroupResponseType[],
    isLoading: boolean
    error: string
};

const initialState: DictWorkgroupState = {
    dictWorkgroupList: [],
    isLoading: false,
    error: ''
};

export const dictWorkgroupSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearDictWorkGroupListList(state) {
            state.dictWorkgroupList = [];
        }
    },
    extraReducers: {
        [getDictWorkGroupList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictWorkGroupList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<DictWorkGroupResponseType>>) => {
            state.isLoading = false;
            state.dictWorkgroupList = payload.results;
            state.error = '';
        },
        [getDictWorkGroupList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.dictWorkgroupList = [];
            state.error = payload;
        },
        [getDictWorkGroupById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictWorkGroupById.fulfilled.type]: (state, { payload }: PayloadAction<DictWorkGroupResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [getDictWorkGroupById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createDictWorkGroup.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createDictWorkGroup.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [createDictWorkGroup.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateDictWorkGroup.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateDictWorkGroup.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateDictWorkGroup.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteDictWorkGroup.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteDictWorkGroup.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteDictWorkGroup.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default dictWorkgroupSlice.reducer;