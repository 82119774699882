import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {DictWorkerFormType, DictWorkerResponseType} from './types';
import {
    createWorker, deleteWorker, getWorkerById, getWorkerList, updateWorker
} from './actions';
import { IPagingSearch } from '../../../interfaces';
import {convertWorker2Client, convertWorkerList2Client} from './converters';

const {name} = options

type DictWorkerState = {
    workerList: DictWorkerFormType[],
    isLoading: boolean
    error: string
};

const initialState: DictWorkerState = {
    workerList: [],
    isLoading: false,
    error: ''
};

export const workerDictionarySlice = createSlice({
    name,
    initialState,
    reducers: {
        clearWorkerList(state) {
            state.workerList = [];
        }
    },
    extraReducers: {
        [getWorkerList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getWorkerList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<DictWorkerResponseType>>) => {
            state.isLoading = false;
            state.workerList = convertWorkerList2Client(payload.results);
            state.error = '';
        },
        [getWorkerList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.workerList = [];
            state.error = payload;
        },
        [getWorkerById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getWorkerById.fulfilled.type]: (state, { payload }: PayloadAction<DictWorkerResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [getWorkerById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createWorker.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createWorker.fulfilled.type]: (state, { payload }: PayloadAction<DictWorkerResponseType>) => {
            state.isLoading = false;
            state.workerList.push(convertWorker2Client(payload));
            state.workerList.sort((a: DictWorkerFormType, b: DictWorkerFormType) => a.second_name.localeCompare(b.second_name));
            state.error = '';
        },
        [createWorker.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateWorker.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateWorker.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateWorker.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteWorker.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteWorker.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteWorker.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default workerDictionarySlice.reducer;
