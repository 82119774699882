import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDictSteelGradeListApi, getDictSteelGradeByIdApi, createDictSteelGradeApi, updateDictSteelGradeApi, deleteDictSteelGradeApi 
} from './api';
import options from './options';
import {DictSteelGradeBaseType} from "./types";

const {name} = options

export const getDictSteelGradeList = createAsyncThunk(
    `${name}/getDictSteelGradeList`,
    async (_, thunkApi) => {
        try {
            return await getDictSteelGradeListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getDictSteelGradeById = createAsyncThunk(
    `${name}/getDictSteelGradeById`,
    async (pipe_id:string, thunkApi) => {
        try {
            return await getDictSteelGradeByIdApi(pipe_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createDictSteelGrade = createAsyncThunk(
    `${name}/createDictSteelGrade`,
    async (body: DictSteelGradeBaseType, thunkApi) => {
        try {
            return await createDictSteelGradeApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateDictSteelGrade = createAsyncThunk(
    `${name}/updateDictSteelGrade`,
    async ({ uuid, body }: { uuid: string, body: DictSteelGradeBaseType }, thunkApi) => {
        try {
            return await updateDictSteelGradeApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDictSteelGrade = createAsyncThunk(
    `${name}/deleteDictSteelGrade`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteDictSteelGradeApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
