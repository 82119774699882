import request from 'utils/http/request';
import { SeamBaseType, SeamResponseType } from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getSeamByRadiographyIdApi = async (radiography_id: string): Promise<any> => {
    return request.get(url(`?radiography_item=${radiography_id}`));
}

export const createSeamApi = async (body: SeamBaseType): Promise<SeamResponseType> => {
    return request.post(url(''), body);
}

export const deleteSeamApi = async (seam_id: string): Promise<any> => {
    return request.delete(url(`${seam_id}/`));
}