import request from 'utils/http/request';
import { DictFactoryIndexBaseType, DictFactoryIndexResponseType } from './types';
import { IPagingSearch } from 'interfaces';
import { getUrl } from '../../utils';
import options from './options';

const {apiUrl} = options
const url = getUrl(apiUrl);

export const getDictFactoryIndexListApi = async (): Promise<IPagingSearch<DictFactoryIndexResponseType>> => {
    return request.get(url(''));
}

export const getDictFactoryIndexByIdApi = async (dictFactoryIndex_id:string): Promise<DictFactoryIndexResponseType> => {
    return request.get(url(dictFactoryIndex_id));
};

export const createDictFactoryIndexApi = async (body: DictFactoryIndexBaseType): Promise<DictFactoryIndexResponseType> => {
    return request.post(url(''), body);
}

export const updateDictFactoryIndexApi = async (uuid: string, body: DictFactoryIndexBaseType): Promise<DictFactoryIndexResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteDictFactoryIndexApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}
