import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {createRadiography, deleteRadiography, downloadDcomFile, getRadiographyById, getRadiographyListByJobId, updateRadiography} from "./actions";
import {RadiographyResponseType} from "./types";
import {IPagingSearch} from "../../../interfaces";

const { name } = options;

type RadiographyState = {
    isLoading: boolean
    fileDownloading: boolean
    radiographyList: RadiographyResponseType[] | null // для одного weldbook должен быть только один radiography, поэтому тут массив из одного элемента
    error: string
    radiographyById:RadiographyResponseType | null
};

const initialState: RadiographyState = {
    isLoading: false,
    fileDownloading: false,
    radiographyList: null,
    radiographyById: null,
    error: ''
};

export const radiographySlice = createSlice({
    name,
    initialState,
    reducers: {
        clearRadiographyList(state) {
            state.radiographyList = null;
        },
        clearRadiographyById(state) {
            state.radiographyById = null;
        }
    },
    extraReducers: {
        [getRadiographyListByJobId.pending.type]: (state,) => {
            state.isLoading = true;
        },
        [getRadiographyListByJobId.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<RadiographyResponseType>>) => {
            const sortedRadiographies = payload.results.sort((a, b) => {
                if (a.original_file_name.toLowerCase() < b.original_file_name.toLowerCase()) {
                    return -1;
                } else if (a.original_file_name.toLowerCase() > b.original_file_name.toLowerCase()) {
                    return 1;
                } else {
                    return a.original_file_name.localeCompare(b.original_file_name, 'ru', {sensitivity: 'accent', numeric: true});
                }
            });
            state.isLoading = false;
            state.radiographyList = sortedRadiographies;
            state.error = '';
        },
        [getRadiographyListByJobId.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [getRadiographyById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getRadiographyById.fulfilled.type]: (state, { payload }: PayloadAction<RadiographyResponseType>) => {
            state.isLoading = false;
            state.radiographyById = payload;
            state.error = '';
        },
        [getRadiographyById.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [createRadiography.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createRadiography.fulfilled.type]: (state, { payload }: PayloadAction<RadiographyResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [createRadiography.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [updateRadiography.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateRadiography.fulfilled.type]: (state, { payload }: PayloadAction<RadiographyResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateRadiography.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [downloadDcomFile.pending.type]: (state) => {
            state.fileDownloading = true;
        },
        [downloadDcomFile.fulfilled.type]: (state) => {
            state.fileDownloading = false;
            state.error = '';
        },
        [downloadDcomFile.rejected.type]: (state, action: PayloadAction<string>) => {
            state.fileDownloading = false;
            state.error = action.payload;
        },
        [deleteRadiography.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteRadiography.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteRadiography.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default radiographySlice.reducer;
