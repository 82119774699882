import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDictElementTypeListApi, getDictElementTypeByIdApi, createDictElementTypeApi, updateDictElementTypeApi, deleteDictElementTypeApi 
} from './api';
import options from './options';
import {DictElementTypeBaseType} from "./types";

const {name} = options

export const getDictElementTypeList = createAsyncThunk(
    `${name}/getDictElementTypeList`,
    async (_, thunkApi) => {
        try {
            return await getDictElementTypeListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getDictElementTypeById = createAsyncThunk(
    `${name}/getDictElementTypeById`,
    async (pipe_id:string, thunkApi) => {
        try {
            return await getDictElementTypeByIdApi(pipe_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createDictElementType = createAsyncThunk(
    `${name}/createDictElementType`,
    async (body: DictElementTypeBaseType, thunkApi) => {
        try {
            return await createDictElementTypeApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateDictElementType = createAsyncThunk(
    `${name}/updateDictElementType`,
    async ({ uuid, body }: { uuid: string, body: DictElementTypeBaseType }, thunkApi) => {
        try {
            return await updateDictElementTypeApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDictElementType = createAsyncThunk(
    `${name}/deleteDictElementType`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteDictElementTypeApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
