import request from 'utils/http/request';
import { DictSteelGradeBaseType, DictSteelGradeResponseType } from './types';
import { IPagingSearch } from 'interfaces';
import { getUrl } from '../../utils';
import options from './options';

const {apiUrl} = options
const url = getUrl(apiUrl);

export const getDictSteelGradeListApi = async (): Promise<IPagingSearch<DictSteelGradeResponseType>> => {
    return request.get(url(''));
}

export const getDictSteelGradeByIdApi = async (dictSteelGrade_id:string): Promise<DictSteelGradeResponseType> => {
    return request.get(url(dictSteelGrade_id));
};

export const createDictSteelGradeApi = async (body: DictSteelGradeBaseType): Promise<DictSteelGradeResponseType> => {
    return request.post(url(''), body);
}

export const updateDictSteelGradeApi = async (uuid: string, body: DictSteelGradeBaseType): Promise<DictSteelGradeResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteDictSteelGradeApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}
