import request from 'utils/http/request';
import {ConclusionType} from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const generateConclusiontApi = async (body: ConclusionType): Promise<any> => {
    return request.post(url(''), body, {responseType: 'blob'});
}
