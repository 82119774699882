import request from 'utils/http/request';
import { IPagingSearch } from 'interfaces';
import { DictStandardDocumentBaseType, DictStandardDocumentResponseType } from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;
const url = getUrl(apiUrl);

export const getDictStandardDocumentListApi = async (): Promise<IPagingSearch<DictStandardDocumentResponseType>> => request.get(url(''));

export const getDictStandardDocumentByIdApi = async (dictStandardDocument_id:string): Promise<DictStandardDocumentResponseType> => request.get(url(dictStandardDocument_id));

export const createDictStandardDocumentApi = async (body: DictStandardDocumentBaseType): Promise<DictStandardDocumentResponseType> => request.post(url(''), body);

export const updateDictStandardDocumentApi = async (uuid: string, body: DictStandardDocumentBaseType): Promise<DictStandardDocumentResponseType> => request.patch(url(`${uuid}/`), body);

export const deleteDictStandardDocumentApi = async (uuid: string): Promise<any> => request.delete(url(`${uuid}/`));
