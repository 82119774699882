import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {WeldBookResponseType} from './types';
import {createWeldBook, deleteWeldBook, getWeldBookById, getWeldBookListByWorkgroupId, getWeldBookListBySectorId, updateWeldBook} from './actions';
import {IPagingSearch} from "../../../interfaces";

const { name } = options;

type WeldBookState = {
    isLoading: boolean
    isTreeUpdate:boolean
    weldBookList: any
    weldBookDetail: WeldBookResponseType | null
    error: string,
    tableRowIndex: number
};

const initialState: WeldBookState = {
    isLoading: false,
    isTreeUpdate: false,
    weldBookList: [],
    weldBookDetail: null,
    error: '',
    tableRowIndex: 0
};

export const weldBookSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearWeldBookList(state) {
            state.weldBookList = [];
        },
        clearWeldBookDetail(state) {
            state.weldBookDetail = null;
        },
        setTableRowIndex(state, { payload } : PayloadAction<number>) {
            state.tableRowIndex = payload % 9; // todo тк 9 картинок
        }
    },
    extraReducers: {
        [getWeldBookListByWorkgroupId.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getWeldBookListByWorkgroupId.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<WeldBookResponseType>>) => {
            state.isLoading = false;
            state.weldBookList = payload.results;
            state.error = '';
        },
        [getWeldBookListByWorkgroupId.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [getWeldBookListBySectorId.pending.type]: (state) => {
            // state.isLoading = true;
        },
        [getWeldBookListBySectorId.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<WeldBookResponseType>>) => {
            const sortedWeldbooks = payload.results.sort((a, b) => {
                if (a.number === null) {
                    return 1;
                } else if (b.number === null) {
                    return -1;
                } else {
                    return a.number! - b.number!
                }
            })
            state.isLoading = false;
            state.weldBookList = sortedWeldbooks;
            state.error = '';
        },
        [getWeldBookListBySectorId.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [getWeldBookById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getWeldBookById.fulfilled.type]: (state, { payload }: PayloadAction<WeldBookResponseType>) => {
            state.isLoading = false;
            state.weldBookDetail = payload;
            state.error = '';
        },
        [getWeldBookById.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.weldBookDetail = null;
            state.error = action.payload;
        },
        [createWeldBook.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createWeldBook.fulfilled.type]: (state, { payload }: PayloadAction<WeldBookResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [createWeldBook.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [updateWeldBook.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false
        },
        [updateWeldBook.fulfilled.type]: (state, { payload }: PayloadAction<WeldBookResponseType>) => {
            state.isLoading = false;
            state.isTreeUpdate = true;
            state.error = '';
            // state.weldBookDetail = payload;
        },
        [updateWeldBook.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [deleteWeldBook.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteWeldBook.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteWeldBook.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export default weldBookSlice.reducer;
