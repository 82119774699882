import {
    WeldBookBaseType,
    WeldBookResponseType,
} from 'models/weldbooks/weldbook/types';
import * as yup from 'yup';
import { ElementBaseType } from 'models/weldbooks/element/types';
import { JobBaseType, JobResponseType } from 'models/weldbooks/job/types';
import { createDictFactoryNameApi } from 'models/dictionaries/dict-factory-name/api';
import { createDictSteelGradeApi } from 'models/dictionaries/dict-steel-grade/api';
import { createDictStandardDocumentApi } from 'models/dictionaries/dict-standard-document/api';
import { createDictElementTypeApi } from 'models/dictionaries/dict-element-type/api';
import { SectorResponseType } from 'models/objects/sector/types';
import { compareDates, formatDate } from 'utils/date';
import { WeldbookCumulativeType } from 'models/cumulative/weldbook_cumulative/types';

// журналы сварки
export const weldbookCreateInitialValues: WeldBookBaseType = {
    number_by_user: '',
    datetime: null,
    additional_info: '',
    quality_category: '',
    suffix: '',
    base_weldbook: '',
    item_uuid: '',
    status: '',
    stage: '',
};

export const weldBookInfoValidate = (
    value: WeldBookBaseType | WeldBookResponseType,
    form: any,
    weldBookList: any[],
    sectorById: SectorResponseType | null
) => {
    const errors: any = {};
    if (!value.quality_category && form.touched.quality_category) {
        errors.quality_category = 'Обязательное поле ввода';
    }
    if (!value.number_by_user && form.touched.number_by_user) {
        errors.number_by_user = 'Обязательное поле ввода';
    }
    if (value.number_by_user) {
        const filtereredWeldBookList = value.item_uuid
            ? weldBookList.filter((w: any) => w.item_uuid !== value.item_uuid)
            : weldBookList;

        if (
            filtereredWeldBookList.some(
                (w: any) =>
                    w.number_by_user?.toString() ===
                    value.number_by_user!.toString()
            )
        ) {
            errors.number_by_user = 'КСС с таким номером стыка уже существует';
        }
        if (value.number_by_user.length > 255) {
            errors.number_by_user =
                'Вы достигли лимита символов. Максимальное количество: 255';
        }
    }
    if (value.additional_info!.length > 4000) {
        errors.additional_info =
            'Вы достигли лимита символов. Максимальное количество: 4000';
    }
    if (value.datetime && sectorById?.start_date) {
        if (
            !compareDates(
                formatDate(new Date(value.datetime), 'dd.MM.yyyy'),
                formatDate(new Date(sectorById.start_date), 'dd.MM.yyyy'),
                'start_date'
            )
        ) {
            errors.datetime =
                'Дата сварки не должна быть раньше, чем дата начала строительства участка';
        }
    }
    return errors;
};

// элементы
export const elementCreateInitialValues: ElementBaseType = {
    name: '',
    diameter: null,
    side_width: null,
    length: null,
    sertificate: '',
    steel_type: '',
    factory_name: '',
    factory_index: '',
    item_uuid: '',
};

export const elementValidate = (
    value: ElementBaseType,
    form: any,
    anotherForm: any
) => {
    const errors: any = {};
    if (
        form.touched.name &&
        (!value.name || (value.name.length > 0 && value.name.trim() === ''))
    ) {
        errors.name = 'Обязательное поле ввода';
    }
    if (value.name?.length > 255) {
        errors.name =
            'Вы достигли лимита символов. Максимальное количество: 255';
    }
    if (value.factory_index?.length > 255) {
        errors.factory_index =
            'Вы достигли лимита символов. Максимальное количество: 255';
    }
    if (value.sertificate && value.sertificate.length > 255) {
        errors.sertificate =
            'Вы достигли лимита символов. Максимальное количество: 255';
    }
    if (value.factory_name && value.factory_name.length > 255) {
        errors.factory_name =
            'Вы достигли лимита символов. Максимальное количество: 255';
    }
    if (value.steel_type && value.steel_type.length > 255) {
        errors.steel_type =
            'Вы достигли лимита символов. Максимальное количество: 255';
    }
    if (form.touched.diameter && !value.diameter) {
        errors.diameter = 'Обязательное поле ввода';
    }
    if (
        value.diameter &&
        anotherForm.values.diameter &&
        value.diameter !== anotherForm.values.diameter
    ) {
        errors.diameter = 'Диаметры элементов должны совпадать';
    }
    if (form.touched.side_width && value.side_width === null) {
        errors.side_width = 'Обязательное поле ввода';
    }
    if (form.touched.side_width && typeof +value.side_width! !== 'number') {
        errors.side_width = 'Введите число';
    }
    if (form.touched.length && value.length === null) {
        errors.length = 'Обязательное поле ввода';
    }
    if (form.touched.length && typeof +value.length! !== 'number') {
        errors.length = 'Введите число';
    }
    if (
        form.touched.side_width &&
        value.side_width &&
        (Number(value.side_width) > 99 || Number(value.side_width) < 1)
    ) {
        errors.side_width = 'Значение должно быть в диапазоне от 1 до 99';
    }
    if (
        form.touched.length &&
        value.length &&
        (Number(value.length) > 999 || Number(value.length) < 1)
    ) {
        errors.length = 'Значение должно быть в диапазоне от 1 до 999';
    }
    if (
        form.touched.factory_index &&
        (!value.factory_index ||
            (value.factory_index.length > 0 &&
                value.factory_index.trim() === ''))
    ) {
        errors.factory_index = 'Обязательное поле ввода';
    }
    return errors;
};

export const saveNewDictionaryData = async (
    dictionaryData: any
): Promise<boolean> => {
    let isNewValues: boolean = false;
    const newDictionaryData: any = {
        dictFactoryNameList: [],
        dictElementTypeList: [],
        dictStandardDocumentList: [],
        dictSteelGradeList: [],
    };
    dictionaryData.currentDictionaries.dictFactoryNameList.forEach(
        (d: string) => {
            if (
                d.trim() !== '' &&
                dictionaryData.existedDictionaries.dictFactoryNameList.filter(
                    (f: any) => f.value === d
                ).length === 0
            ) {
                isNewValues = true;
                newDictionaryData.dictFactoryNameList.push(d.trim());
            }
        }
    );
    dictionaryData.currentDictionaries.dictElementTypeList.forEach(
        (d: string) => {
            if (
                d.trim() !== '' &&
                dictionaryData.existedDictionaries.dictElementTypeList.filter(
                    (e: any) => e.value === d
                ).length === 0
            ) {
                isNewValues = true;
                newDictionaryData.dictElementTypeList.push(d.trim());
            }
        }
    );
    dictionaryData.currentDictionaries.dictStandardDocumentList.forEach(
        (d: string) => {
            if (
                d.trim() !== '' &&
                dictionaryData.existedDictionaries.dictStandardDocumentList.filter(
                    (s: any) => s.value === d
                ).length === 0
            ) {
                isNewValues = true;
                newDictionaryData.dictStandardDocumentList.push(d.trim());
            }
        }
    );
    dictionaryData.currentDictionaries.dictSteelGradeList.forEach(
        (d: string) => {
            if (
                d.trim() !== '' &&
                dictionaryData.existedDictionaries.dictSteelGradeList.filter(
                    (s: any) => s.value === d
                ).length === 0
            ) {
                isNewValues = true;
                newDictionaryData.dictSteelGradeList.push(d.trim());
            }
        }
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const value of newDictionaryData.dictFactoryNameList) {
        // eslint-disable-next-line no-await-in-loop
        await createDictFactoryNameApi({ value });
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const value of newDictionaryData.dictElementTypeList) {
        // eslint-disable-next-line no-await-in-loop
        await createDictElementTypeApi({ value });
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const value of newDictionaryData.dictStandardDocumentList) {
        // eslint-disable-next-line no-await-in-loop
        await createDictStandardDocumentApi({ value });
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const value of newDictionaryData.dictSteelGradeList) {
        // eslint-disable-next-line no-await-in-loop
        await createDictSteelGradeApi({ value });
    }

    return isNewValues;
};

export const detectWorkGroupByStage = (data: JobResponseType[]): string => {
    let job: JobResponseType | null;
    if (
        data.filter(
            (j: JobResponseType) => j.stage === 'cut' && j.workgroup_item
        )[0]
    ) {
        job = data.filter(
            (j: JobResponseType) => j.stage === 'cut' && j.workgroup_item
        )[0];
        return `${job.workgroup_item.name} - ${job.workgroup_item.brigadier}`;
    } else if (
        data.filter(
            (j: JobResponseType) => j.stage === 'repair' && j.workgroup_item
        )[0]
    ) {
        job = data.filter(
            (j: JobResponseType) => j.stage === 'repair' && j.workgroup_item
        )[0];
        return `${job.workgroup_item.name} - ${job.workgroup_item.brigadier}`;
    } else if (
        data.filter(
            (j: JobResponseType) => j.stage === 'first' && j.workgroup_item
        )[0]
    ) {
        job = data.filter(
            (j: JobResponseType) => j.stage === 'first' && j.workgroup_item
        )[0];
        return `${job.workgroup_item.name} - ${job.workgroup_item.brigadier}`;
    } else {
        return '';
    }
};

export const filterWbsByWg = (data: any, workgroupId: string): any[] => {
    const filteredData: any[] = [];
    data.forEach((w: any) => {
        if (
            w?.job?.some(
                (j: JobResponseType) =>
                    j.workgroup_item &&
                    ((j.status === 'cut' &&
                        j.workgroup_item.item_uuid === workgroupId) ||
                        (j.status === 'repair' &&
                            j.workgroup_item.item_uuid === workgroupId) ||
                        (j.status === 'fit' &&
                            j.workgroup_item.item_uuid === workgroupId))
            )
        ) {
            filteredData.push(w);
        }
    });
    return filteredData;
};

export const generateNumberByUser = (
    weldbookList: WeldbookCumulativeType[]
): any => {
    // Если список КСС пустой, то вернуть "1"
    if (weldbookList.length === 0) {
        return '1';
    } else {
        const lastWB = weldbookList[weldbookList.length - 1];
        // Если строка пустая, то вернуть "1"
        if (!lastWB.number_by_user) {
            return '1';
        } else {
            // Если строка состоит только из числовых символов, то прибавить 1
            if (/^\d+$/.test(lastWB.number_by_user)) {
                return (parseInt(lastWB.number_by_user, 10) + 1).toString();
                // Если в строке  содержатся не только числовые символы, но и буквенные,
                // то выделяем последнее целое число, прибавляем к нему 1
            } else if (lastWB.number_by_user.match(/(\d+)/g)) {
                let lastNumber: any = lastWB!.number_by_user!.match(/(\d+)/g);
                lastNumber = lastNumber[lastNumber.length - 1];
                return lastWB.number_by_user.replace(
                    lastNumber,
                    (parseInt(lastNumber, 10) + 1).toString()
                );
                // Если строка состоит только из букв, то добавлять к этой строке "-1"
            } else if (/^[a-zA-Zа-яА-Я]+$/.test(lastWB.number_by_user)) {
                return lastWB.number_by_user + '-1';
            } else {
                return '';
            }
        }
    }
};

export const displayNumberByUser = (
    weldBook: WeldBookResponseType | WeldbookCumulativeType | null
): string => {
    if (weldBook?.number_by_user) {
        if (weldBook.suffix === 'р') {
            return `Р ${weldBook.number_by_user}`;
        } else {
            return `${weldBook.number_by_user}`;
        }
    } else {
        return '';
    }
};
