import { format } from 'date-fns';

/**
 * Функция перевода Date в нужный формат
 *
 * @param date - объект Date или строка
 * @param dateFormat - нужный формат (например: 'dd.MM.yyyy', 'dd.MM.yyyy HH:mm:ss')
 */

export function formatDate(date: any, dateFormat: string) {
    if (!date) return '-';

    return format(new Date(date), dateFormat);
}

export function compareDates(
    date_1: string,
    date_2: string,
    compareType: string = ''
): boolean {
    const date_1_ar = date_1.split('.');
    const date_2_ar = date_2.split('.');
    const d1 = Number(date_1_ar[2] + date_1_ar[1] + date_1_ar[0]);
    const d2 = Number(date_2_ar[2] + date_2_ar[1] + date_2_ar[0]);
    return compareType === 'start_date' ? d1 >= d2 : d1 > d2;
}

export function converDateToUTCZ(date: Date) {
    let hours, minutes, seconds, milliseconds;
    const time = date;
    const _ref2 = [
        time.getHours(),
        time.getMinutes(),
        time.getSeconds(),
        time.getMilliseconds(),
    ];
    hours = _ref2[0];
    minutes = _ref2[1];
    seconds = _ref2[2];
    milliseconds = _ref2[3];

    date.setHours(hours + 4);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    date.setMilliseconds(milliseconds);
    return date;
}
