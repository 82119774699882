import request from 'utils/http/request';
import { ResponseChainType, SendChainType } from './types';
import { getUrl } from '../utils';
import options from './options';

const {apiUrl} = options
const url = getUrl(apiUrl);

export const getChainStatusApi = async (chain_uuid:string): Promise<any> => {
    return request.get(url(`get-chain-status/${chain_uuid}/`));
}

export const stopChainApi = async (chain_uuid:string): Promise<any> => {
    return request.get(url(`stop-chain/${chain_uuid}/`));
};

export const sendChainRadiographyApi = async (body: SendChainType): Promise<ResponseChainType> => {
    return request.post(url('send-chain-radiography/'), body);
}

export const getAvailableTasksApi = async (): Promise<any> => {
    return request.get(url(`get-available-tasks/`));
};

export const storeChainResultApi = async (chain_uuid:string): Promise<any> => {
    return request.get(url(`store-chain-result/${chain_uuid}/`));
};