import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { DictTechnicalSheetResponseType } from './types';
import {
    getDictTechnicalSheetList,
    getDictTechnicalSheetById,
    createDictTechnicalSheet,
    updateDictTechnicalSheet,
    deleteDictTechnicalSheet,
} from './actions';
import { IPagingSearch } from '../../../interfaces';

const { name } = options;

type DictTechnicalSheetState = {
    dictTechnicalSheetList: DictTechnicalSheetResponseType[];
    isLoading: boolean;
    error: string;
};

const initialState: DictTechnicalSheetState = {
    dictTechnicalSheetList: [],
    isLoading: false,
    error: '',
};

export const dictTechnicalSheetSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearDictTechnicalSheetListList(state) {
            state.dictTechnicalSheetList = [];
        },
    },
    extraReducers: {
        [getDictTechnicalSheetList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictTechnicalSheetList.fulfilled.type]: (
            state,
            {
                payload,
            }: PayloadAction<IPagingSearch<DictTechnicalSheetResponseType>>
        ) => {
            state.isLoading = false;
            state.dictTechnicalSheetList = payload.results;
            state.error = '';
        },
        [getDictTechnicalSheetList.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.dictTechnicalSheetList = [];
            state.error = payload;
        },
        [getDictTechnicalSheetById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictTechnicalSheetById.fulfilled.type]: (
            state,
            { payload }: PayloadAction<DictTechnicalSheetResponseType>
        ) => {
            state.isLoading = false;
            state.error = '';
        },
        [getDictTechnicalSheetById.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createDictTechnicalSheet.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createDictTechnicalSheet.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [createDictTechnicalSheet.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateDictTechnicalSheet.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateDictTechnicalSheet.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateDictTechnicalSheet.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteDictTechnicalSheet.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteDictTechnicalSheet.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteDictTechnicalSheet.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default dictTechnicalSheetSlice.reducer;
