import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DictRadiographyEquipResponseType } from 'models/dictionaries/dict-radiography-equipment/types';

interface IDictRadiographyEquipDialogProps {
    equipmentData: DictRadiographyEquipResponseType | null;
    onClose: () => void;
    onDelete: (worker: DictRadiographyEquipResponseType) => void;
}

export const RadiographyEquipDeletingDialog: React.FC<
    IDictRadiographyEquipDialogProps
> = ({ equipmentData, onClose, onDelete }) => {
    const [visible, setVisible] = useState<boolean>(false);

    const handleClose = () => {
        setVisible(false);
        onClose();
    };

    useEffect(() => {
        if (equipmentData) {
            setVisible(true);
        }
    }, [equipmentData]);

    const footerContent = (
        <div className="flex w-full justify-content-end">
            <Button
                severity="danger"
                text
                label="Удалить"
                className="mr-3"
                icon="pi pi-trash"
                size="small"
                onClick={() => onDelete(equipmentData!)}
            />
            <Button label="Оставить" onClick={handleClose} size="small" />
        </div>
    );

    return (
        <Dialog
            header="Удаление средства контроля"
            visible={visible}
            style={{ width: '25vw' }}
            onHide={handleClose}
            footer={footerContent}
        >
            {equipmentData && (
                <div className="text-sm font-normal mt-1">
                    Вы уверены, что хотите удалить выбранное средство контроля?
                </div>
            )}
        </Dialog>
    );
};
