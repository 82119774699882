import React, { useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, Theme } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {ConfirmDialog} from '../.';



const useStyles = makeStyles(({palette}: Theme) => ({
    contextMenu: { pointerEvents: 'none' },
    contextMenuPaper: { pointerEvents: 'auto' }
}));

export const ContextMenu = ({data, editElement, removeElement, closeMenu}:any) => {
    const classes = useStyles();

    const handleClose = () => {
        closeMenu()
    };

    const handleEdit = () => {
        editElement(data.row)
    };

    const handleDelete = () => {
        removeElement(data.row)
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Menu
                keepMounted={false}
                open={data?.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    data?.mouseY !== null && data?.mouseX !== null
                        ? { top: data?.mouseY, left: data?.mouseX }
                        : undefined
                }
                PopoverClasses={{ root: classes.contextMenu, paper: classes.contextMenuPaper }}
            >
                <MenuItem onClick={handleEdit}>Редактировать</MenuItem>
                <MenuItem>
                    <ConfirmDialog
                        message="Вы действительно хотите удалить запись?"
                        onConfirm={handleDelete}
                        onCancel={handleClose}
                    >
                    Удалить
                    </ConfirmDialog>
                </MenuItem>
            </Menu>
        </ClickAwayListener>
    );
}