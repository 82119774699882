import React, {ReactElement, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core';

interface IConfirmDialogProps {
  children?: React.ReactNode;
  title?: string; // Заголовок модального окна
  confirmLabel?: string;
  cancelLabel?: string;
  message: string; // Сообщение в модальном окне
  onConfirm?: (e: any) => void; // Функция которая вызовется если нажмём "ОК"
  onCancel?: (e: any) => void; // Функция которая вызовется если нажмём "Отмена"
  disabled?: boolean
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  title: {
    marginBottom: spacing(2),
    marginLeft: spacing(1),
  },
  buttons: {
    margin: spacing(1),
    marginTop: spacing(3),
  },
  leftButton: {
    marginRight: spacing(2),
  },
}));

export const ConfirmDialog = ({
  children,
  title = "Внимание",
  confirmLabel = "Ок",
  cancelLabel = "Отмена",
  message,
  onConfirm,
  onCancel,
  disabled = false,
}: IConfirmDialogProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  function handleClick() {
    !disabled && setOpen(true);
  }

  function handleOk(e: any) {
    onConfirm && onConfirm(e);
    setOpen(false);
  }

  function handleClose(e: any) {
    onCancel && onCancel(e);
    setOpen(false);
  }
  return (
    <>
      <div onClick={handleClick}>
        {children}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <DialogContent style={{minWidth: 340}}>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button
            aria-label="dialog-confirm-button"
            className={classes.leftButton}
            onClick={handleOk}
            color="primary"
            variant="contained"
            autoFocus
          >
            {confirmLabel}
          </Button>
          <Button
            aria-label="dialog-cancel-button"
            onClick={handleClose}
            variant="outlined"
          >
            {cancelLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
