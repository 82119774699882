import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePickerView, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IDatePickerProps {
  label?: string;
  name?: string;
  value: Date | string | null ;
  required?: boolean;
  onChange: (date: MaterialUiPickersDate | string | null, value?: string | null) => void;
  readonly?: boolean;
  inputReadOnly?: boolean;
  disabled?: boolean;
  disableFuture?: boolean;
  format?: string
  width?: any
  errorValue?: string
  helperText?: string
  error?: boolean
  views?: DatePickerView[]
  className?: any
}

function DateField({
    label, name, value, required, onChange, readonly, disabled, disableFuture = true, helperText, error,
    inputReadOnly = false, format = 'dd.MM.yyyy', width = 'inherit', errorValue, views = ['date'], className, ...otherProps
}: IDatePickerProps) {
    const [errorText, setErrorText] = useState<React.ReactNode | string>('');
    const [dateValue, setDateValue] = useState<MaterialUiPickersDate | string>(value);

    const handleError = (error: React.ReactNode, value: ParsableDate) => {
        if (error) {
            setErrorText(error);
        } else {
            errorText && setErrorText('');
        }
    };

    const handleChange = (date: MaterialUiPickersDate | null, value?: string | null) => {
        setDateValue(date);
    };

    useEffect(() => {
        if (onChange instanceof Function && dateValue !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            !errorText ? onChange(dateValue) : onChange('Invalid Date');
        }
    }, [errorText, dateValue]);

    useEffect(() => {
        setDateValue(value);
    }, [value]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                <KeyboardDatePicker
                    className={className}
                    style={{ width }}
                    disableToolbar
                    readOnly={readonly}
                    variant="inline"
                    disabled={disabled}
                    disableFuture={disableFuture}
                    inputVariant="outlined"
                    format={format}
                    views={views}
                    margin="normal"
                    label={label}
                    helperText={errorValue || errorText || helperText}
                    error={Boolean(errorValue) || error || Boolean(errorText)}
                    name={name}
                    value={dateValue || null}
                    required={required}
                    onError={handleError}
                    onChange={handleChange}
                    KeyboardButtonProps={{
                        'aria-label': 'Выберите дату'
                    }}
                    InputProps={{
                        readOnly: inputReadOnly,
                        autoComplete: 'off'
                    }}
                    autoOk
                    invalidDateMessage="Неправильный формат даты"
                    maxDateMessage="Дата больше максимального значения"
                    minDateMessage="Дата меньше минимального значения"
                    {...otherProps}
                />
            </div>
        </MuiPickersUtilsProvider>
    );
}

export default DateField;
