import React, { useState, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';
import { ListBox } from 'primereact/listbox';
import { Sidebar } from 'primereact/sidebar';
import { DashboardModalUploadWindow } from 'pages/home/forms/upload-modal';
import { MenuItem } from 'primereact/menuitem';
import { mainMenuItems } from '../../app';
import { DictionariesComponent } from 'containers/dictionaries/dictionaries-component';

interface IAppDrawerProps {
    open: boolean;
    onClose: () => void;
}

const useStyles = makeStyles(({ mixins, palette, spacing }: Theme) => ({
    drawer: {
        width: '300px !important',
        height: '93%',
        position: 'relative',
        '& > .p-sidebar-header': {
            padding: '.25rem',
        },
    },
    drawerMask: {
        // background: 'linear-gradient(90deg, rgba(199, 226, 255,1) 0%, rgba(28,69,249,0) 30%) !important',
        backgroundColor: 'unset !important',
        top: '57px !important',

        '& > .p-sidebar': {
            boxShadow:
                '20px 10px 49px 0px rgba(49, 63, 112, 0.07), 80px 42px 90px 0px rgba(49, 63, 112, 0.06), 179px 94px 84px 0px rgba(49, 63, 112, 0.04)',
        },
    },

    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...mixins.toolbar,
    },
    drawerTitle: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
    },
    appTitle: {
        cursor: 'pointer',
        color: palette.text.primary,
        fontSize: 18,
    },
    list: {
        minWidth: 320,
    },
    listItem: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
    },
    nestedListItem: {
        paddingLeft: spacing(6),
    },
    menulist: {
        border: 'none',
        '& > div > ul': {
            listStyleType: 'none',
        },
        '& > div > ul > li': {
            padding: '0.75rem 0.25rem !important',
            cursor: 'pointer',
        },
    },
}));

export const AppDrawer: React.FC<IAppDrawerProps> = ({ open, onClose }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [selectedMenuItem, setSelectedMenuItem] = useState<any>(null);
    const [isDictionariesModuleOpen, setIsDictionariesModuleOpen] =
        useState<boolean>(false);

    const onListItemClick =
        (path: string) => (e: React.MouseEvent<HTMLDivElement>) => {
            if (path) {
                // e.preventDefault();
                // e.stopPropagation();
                navigate(path);
            }
        };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const menuItemTemplate = (option: MenuItem) => (
        <div className="flex align-items-center">
            <i className={option.icon} />
            <div className="ml-3 text-sm">{option.label}</div>
        </div>
    );

    const selectItem = (item: MenuItem) => {
        if (!item) {
        } else {
            if (item.label === 'Анализ DCM файла') {
                handleModalOpen();
            } else if (item.label === 'Справочники') {
                setIsDictionariesModuleOpen(true);
            } else {
                navigate(item.url!);
            }
        }
    };

    const closeAndUpdateDictionaries = () => {
        setIsDictionariesModuleOpen(false);
    };

    useEffect(() => {
        setSelectedMenuItem(mainMenuItems[0]);
    }, []);

    return (
        <>
            <Sidebar
                className={classes.drawer}
                visible={open}
                onHide={() => onClose()}
                showCloseIcon={false}
                maskClassName={classes.drawerMask}
            >
                <div
                    role="presentation"
                    onClick={() => onClose()}
                    onKeyDown={() => onClose()}
                >
                    <div className={classes.drawerHeader}>
                        <p className="text-base text-center font-medium text-primary-900 ml-3 mr-3">
                            АС РАСШИФРОВКИ РЕЗУЛЬТАТОВ НК
                        </p>
                    </div>

                    <ListBox
                        value={selectedMenuItem}
                        onChange={(e) => selectItem(e.value)}
                        options={mainMenuItems}
                        optionLabel="name"
                        itemTemplate={menuItemTemplate}
                        className={`w-full ${classes.menulist}`}
                        listStyle={{ maxHeight: '250px' }}
                    />
                </div>
            </Sidebar>
            <DashboardModalUploadWindow
                open={isModalOpen}
                onClose={handleModalClose}
            />
            {isDictionariesModuleOpen && (
                <DictionariesComponent onClose={closeAndUpdateDictionaries} />
            )}
        </>
    );
};
