import {Route} from 'react-router';
import * as Pages from './pages';
import Layout from './containers/layout';
import Private from './containers/private';

export default (
    <Route
        element={(
            <Private redirectPath="/auth">
                <Layout />
            </Private>
        )}
        path="/"
        key="layout"
    >
        {Object.values(Pages)}
    </Route>
);
