import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPagingSearch } from 'interfaces';
import { SeamResponseType } from './types';
import { getSeamByRadiographyId } from './actions';
import options from './options';

const { name } = options;

type SeamState = {
    isLoading: boolean
    seam: SeamResponseType[] | []
    error: string
};

const initialState: SeamState = {
    isLoading: false,
    seam: [],
    error: ''
};

export const seamSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [getSeamByRadiographyId.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getSeamByRadiographyId.fulfilled.type]: (state, action: PayloadAction<IPagingSearch<SeamResponseType>>) => {
            state.isLoading = false;
            state.seam = action.payload.results;
            state.error = '';
        },
        [getSeamByRadiographyId.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.seam = [];
            state.error = action.payload;
        }
    }
});

export default seamSlice.reducer;
