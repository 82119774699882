import request from 'utils/http/request';
import {ElementBaseType, ElementResponseType} from './types';
import { getUrl } from '../../utils';
import options from './options';
import {IPagingSearch} from '../../../interfaces';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getElementsListApi = async (sector_id: string, additionalParams?: string): Promise<IPagingSearch<ElementResponseType>> => {
    return request.get(url(`?sector_id=${sector_id}${additionalParams?? ''}`));
};

export const createElementApi = async (body: ElementBaseType): Promise<ElementResponseType> => {
    return request.post(url(''), body);
}

export const updateElementApi = async (item_uuid:string, body: ElementBaseType): Promise<ElementResponseType> => {
    return request.patch(url(`${item_uuid}/`), body);
}

export const deleteElementApi = async (item_uuid: string): Promise<any> => {
    return request.delete(url(`${item_uuid}/`));
}
