import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectObjectState } from 'models/objects/object/selectors';
import { ObjectCard } from 'pages/gas-pipeline/pages/sectors/forms/object-card';
import { selectSectorState } from 'models/objects/sector/selectors';

import { selectWorkgroupState } from 'models/objects/workgroup/selectors';
import { Divider } from 'primereact/divider';
import { SectorCard } from 'pages/gas-pipeline/pages/weldbook/forms/sector-card';
import { selectWeldBookState } from 'models/weldbooks/weldbook/selectors';

export const ItemDetail: React.FC = () => {
    const { objectById, isLoading: isObjectLoading} = useAppSelector(selectObjectState);
    const { sectorById, isLoading: isSectorLoading} = useAppSelector(selectSectorState);
    const { workgroupById, isLoading: executorIsLoading } = useAppSelector(selectWorkgroupState);
    const { weldBookDetail } = useAppSelector(selectWeldBookState);
    useEffect(() => {
    }, []);

    return (
        <>
            <div className="card ml-2">
                {/* {(objectById || sectorById || workgroupById) && <Divider className="ml-4 mr-4 mb-1 mt-1 w-10" />} */}
                {objectById && window.location.pathname.includes('sectors') && (<ObjectCard selectedObject={objectById} />)}
                {sectorById && window.location.pathname.includes('weldbook') && (<SectorCard selectedSector={sectorById} />)}
            </div>
        </>
       
    );
};
