import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {createElement, deleteElement, getElementsList, updateElement} from './actions';
import {ElementResponseType} from "./types";
import {IPagingSearch} from "../../../interfaces";

const { name } = options;

type ElementState = {
    isLoading: boolean
    elementList: ElementResponseType[],
    error: string
};

const initialState: ElementState = {
    isLoading: false,
    elementList: [],
    error: ''
};

export const elementSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearElementList(state) {
            state.elementList = [];
        }
    },
    extraReducers: {
        [getElementsList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getElementsList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<ElementResponseType>>) => {
            state.isLoading = false;
            state.elementList = payload.results
                .sort((a, b) => a.factory_index.localeCompare(b.factory_index));
            state.error = '';
        },
        [getElementsList.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [createElement.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createElement.fulfilled.type]: (state, { payload }: PayloadAction<ElementResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [createElement.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [updateElement.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateElement.fulfilled.type]: (state, { payload }: PayloadAction<ElementResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateElement.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [deleteElement.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteElement.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteElement.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export default elementSlice.reducer;
