import Private from 'containers/private';
import {Route} from 'react-router-dom';
import Component from './component';

export default <Route
    element={(
        <Private>
            <Component />
        </Private>
    )}
    path="/gas-pipeline/*"
    key="gas-pipeline"
/>;
