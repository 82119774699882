import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDicomData } from './actions';

type FileMetaState = {
    isLoading: boolean
    file_meta: any | null,
    error: string
};

const initialState: FileMetaState = {
    isLoading: false,
    file_meta: null,
    error: ''
};

export const dicomMeta = createSlice({
    name: 'file_data',
    initialState,
    reducers: {},
    extraReducers: {
        [getDicomData.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDicomData.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.file_meta = action.payload;
            state.error = '';
        },
        [getDicomData.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.file_meta = null;
            state.error = action.payload;
        }
    }
});

export default dicomMeta.reducer;
