import { IObject } from 'interfaces';

// проверка, успешный ли диспатч
export const isSuccessRequest = (res: any): boolean => res.meta.requestStatus === 'fulfilled';

// Проверяет Объект {} на пустоту
export const isEmpty = (obj: IObject): boolean => {
    if (obj === null || obj === undefined) return true;
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

// округление чисел
export const cutNumbers = (num: any, digits: number) => {
    if (num === null || num === undefined) {
        return "";
    }
    const reduced = Math.pow(10, -digits);
    // если очень маленькое число - округляем до первой цифры
    return num && num < reduced ? num.toPrecision(1)
        : Math.round(num * (Math.pow(10, digits))) / Math.pow(10, digits);
};

// преобразование массива в объект. нужно для выпадающих списков MaterialTable
export const array2Object = (object: IObject, key: string, value: string) => {
    return object.reduce((reducer: IObject, current: IObject) => (
        {[current[key]]: current[value], ...reducer}), {});
};
