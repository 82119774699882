import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { ObjectResponseType } from './types';
import {
    createObject, deleteObject, getObjectsList, updateObject, getObjectById
} from "./actions";
import { IPagingSearch } from "../../../interfaces";

const { name } = options;

type FishState = {
    isLoading: boolean
    isTreeUpdate:boolean
    objectsList: ObjectResponseType[],
    error: string,
    objectById: null | ObjectResponseType
};

const initialState: FishState = {
    isLoading: false,
    isTreeUpdate: false,
    objectsList: [],
    error: '',
    objectById: null
};

export const objectSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearObjectList(state) {
            state.objectsList = [];
        },
        clearObjectById(state) {
            state.objectById = null;
        }
    },
    extraReducers: {
        [getObjectsList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getObjectsList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<ObjectResponseType>>) => {
            const sortedObjects = payload.results.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else {
                    return a.name.localeCompare(b.name, 'ru', {sensitivity: 'accent', numeric: true});
                }
            });

            state.isLoading = false;
            state.objectsList = sortedObjects;
            state.error = '';
        },
        [getObjectsList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.objectsList = [];
            state.error = payload;
        },
        [getObjectById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getObjectById.fulfilled.type]: (state, { payload }: PayloadAction<ObjectResponseType>) => {
            state.isLoading = false;
            state.objectById = payload;
            state.error = '';
        },
        [getObjectById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.objectById = null;
            state.error = payload;
        },
        [createObject.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false;
        },
        [createObject.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
            state.isTreeUpdate = true;
        },
        [createObject.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateObject.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false;
        },
        [updateObject.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
            state.isTreeUpdate = true;
        },
        [updateObject.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteObject.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false;
        },
        [deleteObject.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
            state.isTreeUpdate = true;
        },
        [deleteObject.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default objectSlice.reducer;
