import request from 'utils/http/request';
import { RegistryType } from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const generateRegistyApi = async (body: RegistryType): Promise<any> => {
    return request.post(url(''), body, { responseType: 'blob' });
};
