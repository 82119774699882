import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWeldbookCumulativeApi } from './api';
import options from './options';
import { WeldbookCumulativeReqType } from './types';

const { name } = options;

export const getWeldbookCumulative = createAsyncThunk(
    `${name}/getWeldbookCumulative`,
    async (params:string, thunkApi) => {
        try {
            return await getWeldbookCumulativeApi(params);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);