import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import rootReducer from '../reducers';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => (process.env.NODE_ENV === 'development'
        ? getDefaultMiddleware({
            serializableCheck: false
        }).concat(logger)
        : getDefaultMiddleware({
            serializableCheck: false
        }))
});

export const setupStore = () => store;

export type RootStateType = ReturnType<typeof rootReducer>;

export type AppDispatchType = typeof store.dispatch;
