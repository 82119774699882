import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataImageResponseType } from './types';
import { getDataImageByRadiographyId } from './actions';
import options from './options';

const { name } = options;

type DataImageState = {
    isLoading: boolean
    dataImages: DataImageResponseType[] | null
    error: string
};

const initialState: DataImageState = {
    isLoading: false,
    dataImages: [],
    error: ''
};

export const dataImageSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [getDataImageByRadiographyId.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDataImageByRadiographyId.fulfilled.type]: (state, action: PayloadAction<DataImageResponseType[]>) => {
            state.isLoading = false;
            state.dataImages = action.payload;
            state.error = '';
        },
        [getDataImageByRadiographyId.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.dataImages = [];
            state.error = action.payload;
        }
    }
});

export default dataImageSlice.reducer;
