import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import {createJobApi, deleteJobApi, getJobByIdApi, getJobsListApi, updateJobApi} from './api';
import {JobBaseType} from './types';

const { name } = options;

export const getJobsList = createAsyncThunk(
    `${name}/getJobsList`,
    async (weldbook_uuid:string, thunkApi) => {
        try {
            return await getJobsListApi(weldbook_uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getJobById = createAsyncThunk(
    `${name}/getJobById`,
    async (item_uuid: string, thunkApi) => {
        try {
            return await getJobByIdApi(item_uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createJob = createAsyncThunk(
    `${name}/createJob`,
    async (body: JobBaseType, thunkApi) => {
        try {
            return await createJobApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateJob = createAsyncThunk(
    `${name}/updateJob`,
    async ({ uuid, body }: { uuid: string, body: JobBaseType | any }, thunkApi) => {
        try {
            return await updateJobApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteJob = createAsyncThunk(
    `${name}/deleteJob`,
    async (item_uuid: string, thunkApi) => {
        try {
            return await deleteJobApi(item_uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
