import React, { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import {
    DictRadiographyEquipBaseType,
    DictRadiographyEquipResponseType,
} from 'models/dictionaries/dict-radiography-equipment/types';
import { InputNumber } from 'primereact/inputnumber';

interface IRadiographyEquipDialogProps {
    formData: any;
    onClose: () => void;
    onCreate?: (item: DictRadiographyEquipResponseType) => void;
    onUpdate?: (
        item_uuid: string,
        item: DictRadiographyEquipResponseType
    ) => void;
}

const dictRadiographyEquipInitialValues: DictRadiographyEquipBaseType = {
    screening_scheme: '',
    brand: '',
    voltage: null,
    radiation_power: null,
    detector_type: '',
    focal_spot: '',
};

export const RadiographyEquipDialog = ({
    formData,
    onClose,
    onCreate,
    onUpdate,
}: IRadiographyEquipDialogProps): ReactElement => {
    const [visible, setVisible] = useState<boolean>(false);
    const detectorTypes = ['Транскан', 'Цифракон'];
    const screeningSchemes = ['Панорамное', 'Фронтальное'];

    const formik = useFormik({
        initialValues: formData || dictRadiographyEquipInitialValues,
        validateOnBlur: true,
        validate: (data) => {
            const errors: any = {};

            if (
                !data.screening_scheme ||
                data.screening_scheme?.trim() === ''
            ) {
                errors.screening_scheme = 'Обязательное поле ввода';
            }
            if (data.screening_scheme.length > 255) {
                errors.screening_scheme =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            if (!data.brand || data.brand?.trim() === '') {
                errors.brand = 'Обязательное поле ввода';
            }
            if (data.brand.length > 255) {
                errors.brand =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            if (!data.voltage) errors.voltage = 'Обязательное поле ввода';
            if (
                data.voltage !== null &&
                (data.voltage! > 999.99 || data.voltage! < 0.01)
            ) {
                errors.voltage =
                    'Значение должно быть в диапазоне от 0.01 до 999.99';
            }

            if (!data.radiation_power)
                errors.radiation_power = 'Обязательное поле ввода';
            if (
                data.radiation_power !== null &&
                (data.radiation_power! > 999.99 || data.radiation_power! < 0.01)
            ) {
                errors.radiation_power =
                    'Значение должно быть в диапазоне от 0.01 до 999.99';
            }

            if (!data.detector_type || data.detector_type?.trim() === '') {
                errors.detector_type = 'Обязательное поле ввода';
            }
            if (data.detector_type.length > 255) {
                errors.detector_type =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            if (!data.focal_spot || data.focal_spot?.trim() === '') {
                errors.focal_spot = 'Обязательное поле ввода';
            }
            if (data.focal_spot.length > 255) {
                errors.focal_spot =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            return errors;
        },
        onSubmit: (values: any) => {
            if (formData) {
                onUpdate && onUpdate(formData.item_uuid, values);
            } else {
                onCreate && onCreate(values);
            }
        },
    });

    const isFormFieldInvalid = (name: any) =>
        !!(
            formik.touched[name as keyof DictRadiographyEquipBaseType] &&
            formik.errors[name as keyof DictRadiographyEquipBaseType]
        );

    const isFormInvalid = () => {
        if (
            formik.values.screening_scheme &&
            !formik.errors.screening_scheme &&
            formik.values.brand &&
            !formik.errors.brand &&
            formik.values.voltage &&
            !formik.errors.voltage &&
            formik.values.detector_type &&
            !formik.errors.detector_type &&
            formik.values.radiation_power &&
            !formik.errors.radiation_power &&
            formik.values.focal_spot &&
            !formik.errors.focal_spot
        ) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        setVisible(true);
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
            <Dialog
                header={`${
                    formData ? 'Редактирование' : 'Добавление'
                } средства контроля`}
                visible={visible}
                style={{ width: '40vw' }}
                onHide={onClose}
            >
                <div className="grid align-items-end">
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="screening_scheme">
                                Схема просвечивания{' '}
                                <span className="required">*</span>
                            </label>
                            <Dropdown
                                id="screening_scheme"
                                name="screening_scheme"
                                value={formik.values.screening_scheme}
                                options={screeningSchemes}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'screening_scheme',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('screening_scheme'),
                                })}
                                tooltip={
                                    formik.errors.screening_scheme as string
                                }
                                tooltipOptions={{
                                    disabled: !formik.errors.screening_scheme,
                                    position: 'bottom',
                                }}
                                appendTo="self"
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="brand">
                                Марка <span className="required">*</span>
                            </label>
                            <InputText
                                id="brand"
                                name="brand"
                                value={formik.values.brand}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'brand',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('brand'),
                                })}
                                tooltip={formik.errors.brand as string}
                                tooltipOptions={{
                                    disabled: !isFormFieldInvalid('brand'),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="voltage">
                                Рабочее напряжение, кВ{' '}
                                <span className="required">*</span>
                            </label>
                            <InputNumber
                                id="voltage"
                                name="voltage"
                                maxFractionDigits={2}
                                minFractionDigits={2}
                                value={formik.values.voltage}
                                onChange={(e) => {
                                    formik.setFieldValue('voltage', e.value);
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('voltage'),
                                })}
                                tooltip={formik.errors.voltage as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.voltage,
                                    position: 'bottom',
                                }}
                                useGrouping={false}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="radiation_power">
                                Мощность излучения, Ма{' '}
                                <span className="required">*</span>
                            </label>
                            <InputNumber
                                id="radiation_power"
                                name="radiation_power"
                                maxFractionDigits={2}
                                minFractionDigits={2}
                                value={formik.values.radiation_power}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'radiation_power',
                                        e.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('radiation_power'),
                                })}
                                tooltip={
                                    formik.errors.radiation_power as string
                                }
                                tooltipOptions={{
                                    disabled: !formik.errors.radiation_power,
                                    position: 'bottom',
                                }}
                                useGrouping={false}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="detector_type">
                                Тип детектора{' '}
                                <span className="required">*</span>
                            </label>
                            <Dropdown
                                id="detector_type"
                                name="detector_type"
                                value={formik.values.detector_type}
                                options={detectorTypes}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'detector_type',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('detector_type'),
                                })}
                                tooltip={formik.errors.detector_type as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.detector_type,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="focal_spot">
                                Фокусное пятно{' '}
                                <span className="required">*</span>
                            </label>
                            <InputText
                                id="focal_spot"
                                name="focal_spot"
                                value={formik.values.focal_spot}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'focal_spot',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('focal_spot'),
                                })}
                                tooltip={formik.errors.focal_spot as string}
                                tooltipOptions={{
                                    disabled: !isFormFieldInvalid('focal_spot'),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-content-end mt-3">
                    <Button
                        outlined
                        onClick={onClose}
                        label="Отмена"
                        className="mr-3"
                    />
                    {!formData && (
                        <Button
                            type="submit"
                            label="Добавить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                    {formData && (
                        <Button
                            type="submit"
                            label="Сохранить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                </div>
            </Dialog>
        </form>
    );
};
