import React, {ReactElement} from 'react';
import { makeStyles} from '@material-ui/core';
import { ProgressBar } from 'primereact/progressbar';

const useStyles = makeStyles(() => ({
    loader: {
        top: '57px',
        left: 0,
        right: 0,
        height: '5px !important',
        zIndex: 0
    },
}));

export const WbLoader = (): ReactElement => {
    const classes = useStyles();

    return (
        <ProgressBar mode="indeterminate" className={`wb-loader fixed ${classes.loader}`} />
    );
};
