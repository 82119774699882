import request from 'utils/http/request';
import { FishType, SectorBaseType, SectorResponseType } from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getSectorsListApi = async (
    object_id: string
): Promise<SectorResponseType[]> => {
    return request.get(url(`?object_item=${object_id}`));
};

export const getSectorByIdApi = async (
    sector_id: string
): Promise<SectorResponseType> => {
    return request.get(url(sector_id));
};

export const createSectorApi = async (
    body: SectorBaseType
): Promise<SectorResponseType> => {
    return request.post(url(''), body);
};

export const updateSectorApi = async (
    uuid: string,
    body: SectorBaseType
): Promise<SectorResponseType> => {
    return request.patch(url(`${uuid}/`), body);
};

export const deleteSectorApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
};

export const getAllSectorsListApi = async (): Promise<SectorResponseType[]> => {
    return request.get(url(''));
};

export const getSectorsListByDictWorkGroup = async (
    dict_wg_id: string
): Promise<SectorResponseType[]> => {
    return request.get(url(`?dict_workgroup_item=${dict_wg_id}`));
};
