import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MenuItem } from 'primereact/menuitem';
import { locale, addLocale } from 'primereact/api';
import locales from './utils/ru-locale';
import { SystemProvider } from './utils/system-provider';
import Routes from './routes';
import { setupStore } from './store';

// установка праймовских стилей
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';

// переназначение праймовских стилей на стили GTM
import '@innouni/ui-kit-styles/outputStyles/elementStyles.css';
import '@innouni/ui-kit-styles/outputStyles/spacingStyles.css';
import '@innouni/ui-kit-styles/outputStyles/vendorStyles.css';
import '@innouni/ui-kit-styles/outputStyles/icons/primeicons.css';

// тема сварки
import 'theme/styles/weld-palette.css';
import 'theme/styles/mainStyles.css';

const store = setupStore();

export type MainMenuItemType = {
    label: string;
    url: string;
    isNested?: boolean;
    disabled?: boolean;
};

export const mainMenuItems: MenuItem[] = [
    {
        label: 'Объекты строительства',
        url: '/gas-pipeline',
        icon: 'pi pi-database',
    },
    {
        label: 'Справочники',
        url: '/dictionaries',
        icon: 'pi pi-database',
    },
];

export const App: React.FC = () => {
    useEffect(() => {
        addLocale('ru', locales.ru);
        locale('ru');
    }, []);
    return (
        <Provider store={store}>
            <BrowserRouter>
                <SystemProvider>
                    <Routes />
                </SystemProvider>
            </BrowserRouter>
        </Provider>
    );
};
