import request from 'utils/http/request';
import {
    DictTechnicalSheetBaseType,
    DictTechnicalSheetResponseType,
} from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;
const url = getUrl(apiUrl);

export const getDictTechnicalSheetListApi = async (): Promise<
    DictTechnicalSheetResponseType[]
> => {
    return request.get(url(''));
};

export const getDictTechnicalSheetByIdApi = async (
    uuid: string
): Promise<DictTechnicalSheetResponseType> => {
    return request.get(url(uuid));
};

export const createDictTechnicalSheetApi = async (
    body: DictTechnicalSheetBaseType
): Promise<DictTechnicalSheetResponseType> => {
    return request.post(url(''), body);
};

export const updateDictTechnicalSheetApi = async (
    uuid: string,
    body: DictTechnicalSheetBaseType
): Promise<DictTechnicalSheetResponseType> => {
    return request.patch(url(`${uuid}/`), body);
};

export const deleteDictTechnicalSheetApi = async (
    uuid: string
): Promise<any> => {
    return request.delete(url(`${uuid}/`));
};
