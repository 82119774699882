import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendChainRadiographyApi, getChainStatusApi, stopChainApi, getAvailableTasksApi, storeChainResultApi } from './api';
import options from './options';
import { SendChainType } from "./types";

const {name} = options

export const sendChainRadiography = createAsyncThunk(
    `${name}/sendChainRadiography`,
    async (body:SendChainType, thunkApi) => {
        try {
            return await sendChainRadiographyApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getChainStatus = createAsyncThunk(
    `${name}/getChainStatus`,
    async (chain_uuid:string, thunkApi) => {
        try {
            return await getChainStatusApi(chain_uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const stopChain = createAsyncThunk(
    `${name}/stopChain`,
    async (chain_uuid:string, thunkApi) => {
        try {
            return await stopChainApi(chain_uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getAvailableTasks = createAsyncThunk(
    `${name}/getAvailableTasks`,
    async (_, thunkApi) => {
        try {
            return await getAvailableTasksApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const storeChainResult = createAsyncThunk(
    `${name}/storeChainResult`,
    async (chain_uuid:string, thunkApi) => {
        try {
            return await storeChainResultApi(chain_uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
