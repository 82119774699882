import {useContext} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import Auth from "../../contexts/auth";

type PrivateType = {
    redirectPath?: string;
    roles?: number[];
    children?: any
};

export default ({
    redirectPath = '/', roles = [], children
}: PrivateType): JSX.Element => {
    const { user } = useContext(Auth);
    const location = useLocation();

    // check roles here

    if (!user) {
        // сохранияем страницу на которой находимся, чтобы после переавторизации вернуться на нее
        return <Navigate to={redirectPath} replace state={{from: `${location.pathname}${location.search || ''}`}} />;
    }

    return children ? children : <Outlet />;
};
