import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getWeldbookReport } from './actions';
import options from './options';

const { name } = options;

type WeldbookReportState = {
    isLoading: boolean
    error: string,
    weldbookReport:any,
};

const initialState: WeldbookReportState = {
    isLoading: false,
    error: '',
    weldbookReport: null
};

export const weldBookReportSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearWeldbookReport(state) {
            state.weldbookReport = null;
        },
    },
    extraReducers: {
        [getWeldbookReport.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getWeldbookReport.fulfilled.type]: (state, {payload}: PayloadAction<Blob>) => {
            state.isLoading = false;
            state.error = '';
            state.weldbookReport = payload
        },
        [getWeldbookReport.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
    }
});

export default weldBookReportSlice.reducer;
