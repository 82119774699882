import { useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Location } from 'history';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

import { Theme, makeStyles } from '@material-ui/core/styles';
import Auth from '../../../contexts/auth';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAuthState } from '../../../models/auth/selectors';
import { authSlice } from '../../../models/auth/slice';

interface IHistoryState extends Location {
    state: { from?: string }
}

const useStyles = makeStyles(({ spacing }:Theme) => ({
    container: {
        height: '100vh',
        maxHeight: '1080px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },

    containerGrid: {
        height: '100vh',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'flex-start',
        // eslint-disable-next-line max-len
        backgroundColor: '#217BD7',
        width: '62.5%'
    },

    containerBackground: {
        height: '100vh',
        backgroundImage: `url(${'assets/images/auth-bg.svg'})`,
        backgroundSize: 'cover',
        width: '37.5%'
    },
    card: {
        width: '470px',
        padding: spacing(2),
        marginTop: '5%'
    },
    title: {
        marginLeft: '3%',
        marginTop: '35%',
        cursor: 'default'
    },
    input: {
        "& .p-password-input": {
            width: '100%'
        }
    },
    authContainer: {
        width: '480px'
    },
    errorMessage: {
        position: 'absolute',
        top: '56%'
    }
}));

export default () => {
    const { user, onLogin } = useContext(Auth);
    const location = useLocation() as IHistoryState;
    const classes = useStyles();
    const [form, setForm] = useState({ username: '', password: '' });
    const [error, setError] = useState('');

    const dispatch = useAppDispatch();

    const { signInError } = useAppSelector(selectAuthState);
    const { clearSignInError } = authSlice.actions;

    const onSend = async (e: any) => {
        e.preventDefault();
        if (!form.username || !form.password) {
            setError('Необходимо указать логин и пароль');
        } else {
            try {
                await onLogin(form);
            } catch (e: any) {
                // if (e?.response?.data?.message) {
                //     setError(e?.response?.data?.message);
                // } else {
                //     setError('');
                // }
                throw e;
            }
        }
    };

    const handleFieldChange = (e: any) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value});
        error && setError('');
        signInError && dispatch(clearSignInError());
    };

    if (user) {
        if (location.state?.from) {
            return <Navigate to={location.state.from} />;
        }

        return <Navigate to="/" />;
    }

    return (
        <div>
            <div className={`flex flex-row ${classes.container}`}>
                <div className={`xl:col-fixed lg:col-fixed md:col-fixed ${classes.containerBackground}`} />
                <div className={`xl:col-fixed lg:col-fixed md:col-fixed ${classes.containerGrid} flex justify-content-center`}>
                    <div className={classes.authContainer}>
                        <div className={classes.title}>
                            <div className="text-3xl text-white text-center mr-4">
                            Автоматизированная система расшифровки результатов НК
                            </div>
                        </div>
                        <Card className={classes.card}>
                            <div className="text-xl font-medium text-center mb-4">
                                Авторизация
                            </div>
                            <form noValidate onSubmit={onSend}>
                                <div className="flex flex-column gap-2 relative">
                                    <label htmlFor="user-name" className="text-sm normal text-color-secondary">Имя пользователя</label>
                                    <InputText
                                        className={`p-inputtext-sm ${classes.input} ${(!!error || !!signInError)? 'p-invalid':''}`}
                                        id="user-name"
                                        name="username"
                                        value={form.username}
                                        onChange={handleFieldChange}
                                        onBlur={handleFieldChange}
                                        required
                                        autoFocus
                                    />

                                    <label htmlFor="password" className="text-sm normal text-color-secondary">Пароль</label>
                                    <Password
                                        inputId="password"
                                        name="password"
                                        value={form.password}
                                        onChange={handleFieldChange}
                                        className={`p-inputtext-sm ${classes.input} ${(!!error || !!signInError)? 'p-invalid':''}`}
                                        feedback={false}
                                        required
                                    />
                                    {(!!error || !!signInError)
                        && (
                            <p className={`text-red-500 text-xs mt-4 ${classes.errorMessage}`}>
                                {error || signInError}
                            </p>
                        )}
                                    <div className="flex justify-content-end">
                                        <Button
                                            style={{ width: '100%', marginTop: 32, textAlign: 'center', display: 'block' }}
                                            color="primary"
                                            disabled={!!error || (form.password.trim() === '' || form.username.trim()=== '')}
                                            type="submit"
                                        >Войти
                                        </Button>
                                    </div>
                                </div>

                            </form>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};
