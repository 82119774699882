import request from 'utils/http/request';
import {DictWorkGroupBaseType, DictWorkGroupResponseType} from './types';
import { getUrl } from '../../utils';
import options from './options';

const {apiUrl} = options
const url = getUrl(apiUrl);

export const getDictWorkGroupListApi = async (): Promise<DictWorkGroupResponseType[]> => {
    return request.get(url(''));
}

export const getDictWorkGroupByIdApi = async (chief_id:string): Promise<DictWorkGroupResponseType> => {
    return request.get(url(chief_id));
};

export const createDictWorkGroupApi = async (body: DictWorkGroupBaseType): Promise<DictWorkGroupResponseType> => {
    return request.post(url(''), body);
}

export const updateDictWorkGroupApi = async (uuid: string, body: DictWorkGroupBaseType): Promise<DictWorkGroupResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteDictWorkGroupApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}