import request from 'utils/http/request';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getWeldbookReportApi = async (body: any): Promise<any> => {
    return request.post(url(''), body, {responseType: 'blob'});
}