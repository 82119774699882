import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import {createObjectApi, deleteObjectApi, getObjectsListApi, updateObjectApi, getObjectByIdApi} from "./api";
import {ObjectBaseType} from "./types";

const { name } = options;

export const getObjectsList = createAsyncThunk(
    `${name}/getObjectsList`,
    async (_, thunkApi) => {
        try {
            return await getObjectsListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getObjectById = createAsyncThunk(
    `${name}/getObjectById`,
    async (object_id:string, thunkApi) => {
        try {
            return await getObjectByIdApi(object_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createObject = createAsyncThunk(
    `${name}/createObject`,
    async (body: ObjectBaseType, thunkApi) => {
        try {
            return await createObjectApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateObject = createAsyncThunk(
    `${name}/updateObject`,
    async ({ uuid, body }: { uuid: string, body: ObjectBaseType }, thunkApi) => {
        try {
            return await updateObjectApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteObject = createAsyncThunk(
    `${name}/deleteObject`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteObjectApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
