import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTaskStatusByRadiographyIdApi } from "./api";
import options from './options';

const { name } = options;

export const getTaskStatusByRadiographyId = createAsyncThunk(
    `${name}/getTaskStatusByRadiographyId`,
    async (radiography_id: string, { rejectWithValue }) => {
        try {
            return await getTaskStatusByRadiographyIdApi(radiography_id);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);