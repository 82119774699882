import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import {createElementApi, deleteElementApi, getElementsListApi, updateElementApi} from './api';
import {ElementBaseType} from './types';

const { name } = options;

export const getElementsList = createAsyncThunk(
    `${name}/getElementsList`,
    async (sector_id: string, thunkApi) => {
        try {
            return await getElementsListApi(sector_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createElement = createAsyncThunk(
    `${name}/createElement`,
    async (body: ElementBaseType, thunkApi) => {
        try {
            return await createElementApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateElement = createAsyncThunk(
    `${name}/updateElement`,
    async ({ uuid, body }: { uuid: string, body: ElementBaseType & { item_uuid?: string | undefined } } , thunkApi) => {
        try {
            return await updateElementApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteElement = createAsyncThunk(
    `${name}/deleteElement`,
    async (item_uuid: string, thunkApi) => {
        try {
            return await deleteElementApi(item_uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
