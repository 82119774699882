import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskStatusType } from './types';
import { getTaskStatusByRadiographyId } from './actions';
import options from './options';

const { name } = options;

type WeldState = {
    isLoading: boolean
    taskStatus: string
    error: string
};

const initialState: WeldState = {
    isLoading: false,
    taskStatus: '',
    error: ''
};

export const weldSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [getTaskStatusByRadiographyId.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getTaskStatusByRadiographyId.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.taskStatus = action.payload;
            state.error = '';
        },
        [getTaskStatusByRadiographyId.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.taskStatus = '';
            state.error = action.payload;
        }
    }
});

export default weldSlice.reducer;
