import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRadiographyCumulativeApi } from './api';
import options from './options';

const { name } = options;

export const getRadiographyCumulative = createAsyncThunk(
    `${name}/getWeldbookCumulative`,
    async (radiographyId:string, thunkApi) => {
        try {
            return await getRadiographyCumulativeApi(radiographyId);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);