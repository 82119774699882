import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {generateConclusion } from "./actions";


const { name } = options;

type ConclusionState = {
    isLoading: boolean
    conclusion: any,
    error: string,
};

const initialState: ConclusionState = {
    isLoading: false,
    error: '',
    conclusion: null
};

export const conclusionSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearConclusion(state) {
            state.conclusion = null;
        },
    },
    extraReducers: {
        [generateConclusion.pending.type]: (state) => {
            state.isLoading = true;
        },
        [generateConclusion.fulfilled.type]: (state, { payload }: PayloadAction<Blob>) => {
            state.isLoading = false;
            state.error = '';
            state.conclusion = payload
        },
        [generateConclusion.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default conclusionSlice.reducer;
