import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDictFactoryNameListApi, getDictFactoryNameByIdApi, createDictFactoryNameApi, updateDictFactoryNameApi, deleteDictFactoryNameApi 
} from './api';
import options from './options';
import {DictFactoryNameBaseType} from "./types";

const {name} = options

export const getDictFactoryNameList = createAsyncThunk(
    `${name}/getDictFactoryNameList`,
    async (_, thunkApi) => {
        try {
            return await getDictFactoryNameListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getDictFactoryNameById = createAsyncThunk(
    `${name}/getDictFactoryNameById`,
    async (pipe_id:string, thunkApi) => {
        try {
            return await getDictFactoryNameByIdApi(pipe_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createDictFactoryName = createAsyncThunk(
    `${name}/createDictFactoryName`,
    async (body: DictFactoryNameBaseType, thunkApi) => {
        try {
            return await createDictFactoryNameApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateDictFactoryName = createAsyncThunk(
    `${name}/updateDictFactoryName`,
    async ({ uuid, body }: { uuid: string, body: DictFactoryNameBaseType }, thunkApi) => {
        try {
            return await updateDictFactoryNameApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDictFactoryName = createAsyncThunk(
    `${name}/deleteDictFactoryName`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteDictFactoryNameApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
