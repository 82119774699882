import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSectorApi, deleteSectorApi, getAllSectorsListApi, getSectorByIdApi, getSectorsListApi, updateSectorApi } from './api';
import options from './options';
import { SectorBaseType } from './types';

const { name } = options;

export const getSectorsList = createAsyncThunk(
    `${name}/getSectorsList`,
    async (object_id:string, thunkApi) => {
        try {
            return await getSectorsListApi(object_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getSectorById = createAsyncThunk(
    `${name}/getSectorById`,
    async (sector_id:string, thunkApi) => {
        try {
            return await getSectorByIdApi(sector_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createSector = createAsyncThunk(
    `${name}/createSector`,
    async (body: SectorBaseType, thunkApi) => {
        try {
            return await createSectorApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateSector = createAsyncThunk(
    `${name}/updateSector`,
    async ({ uuid, body }: { uuid: string, body: SectorBaseType }, thunkApi) => {
        try {
            return await updateSectorApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteSector = createAsyncThunk(
    `${name}/deleteSector`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteSectorApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getAllSectorsList = createAsyncThunk(
    `${name}/getAllSectorsList`,
    async (_, thunkApi) => {
        try {
            return await getAllSectorsListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);