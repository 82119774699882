import React, { ReactElement, useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAppSelector } from 'store/hooks';
import { DictSteelGradeBaseType, DictSteelGradeResponseType } from 'models/dictionaries/dict-steel-grade/types';
import { selectDictSteelGradeState } from 'models/dictionaries/dict-steel-grade/selectors';

interface ISteelGradeDialogProps {
    formData: any;
    onClose: () => void;
    onCreate?: (item: DictSteelGradeBaseType) => void;
    onUpdate?: (item_uuid: string, item: DictSteelGradeResponseType) => void;
}

const dictSteelGradeInitialValues: DictSteelGradeBaseType = {
    value: '',
};

export const SteelGradeDialog = ({
    formData,
    onClose,
    onCreate,
    onUpdate,
}: ISteelGradeDialogProps): ReactElement => {
    const [visible, setVisible] = useState<boolean>(true);
    const { dictSteelGradeList, isLoading } = useAppSelector(selectDictSteelGradeState);

    const formik = useFormik({
        initialValues: formData || dictSteelGradeInitialValues,
        validateOnBlur: true,
        validate: (data) => {
            const errors: any = {};

            if (!data.value || data.value?.trim() === '') {
                errors.value = 'Обязательное поле ввода';
            }
            if (data.value.length > 255) {
                errors.value =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            const filtereredSteelGradeList = formData?
                dictSteelGradeList.filter((f:DictSteelGradeResponseType) => f.item_uuid !== data.item_uuid) :
                dictSteelGradeList
            
            if (data.value?.trim() && filtereredSteelGradeList.some((f:DictSteelGradeResponseType) => f.value === data.value.trim())) {
                errors.value = 'Марка стали с таким значением уже существует';
            }
            return errors;
        },
        onSubmit: (values: any) => {
            if (formData) {
                onUpdate && onUpdate(formData.item_uuid, values);
            } else {
                onCreate && onCreate(values);
            }
        },
    });

    const isFormInvalid = () => {
        if (
            formik.values.value.trim() && formik.values.value.length <= 255 && !formik.errors.value
        ) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
            <Dialog
                header={`${
                    formData ? 'Редактирование' : 'Добавление'
                } марки стали`}
                visible={visible}
                style={{ width: '40vw' }}
                onHide={onClose}
            >
                <div className="grid align-items-end">
                    <div className="col-12">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="second_name">
                                Марка стали <span className="required">*</span>
                            </label>
                            <InputText
                                id="value"
                                name="value"
                                value={formik.values.value}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'value',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                    formik.errors.value,
                                })}
                                tooltip={formik.errors.value as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.value,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-content-end mt-3">
                    <Button
                        outlined
                        onClick={onClose}
                        label="Отмена"
                        className="mr-3"
                    />
                    {!formData && (
                        <Button
                            type="submit"
                            label="Добавить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                    {formData && (
                        <Button
                            type="submit"
                            label="Сохранить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                </div>
            </Dialog>
        </form>
    );
};
