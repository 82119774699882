import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { DictStandardDocumentResponseType } from './types';
import {
    createDictStandardDocument, deleteDictStandardDocument, getDictStandardDocumentById, getDictStandardDocumentList, updateDictStandardDocument
} from "./actions";
import { IPagingSearch } from "../../../interfaces";

const {name} = options

type DictStandardDocumentState = {
    dictStandardDocumentList: DictStandardDocumentResponseType[],
    isLoading: boolean
    error: string
};

const initialState: DictStandardDocumentState = {
    dictStandardDocumentList: [],
    isLoading: false,
    error: ''
};

export const DictStandardDocumentSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearDictStandardDocumentList(state) {
            state.dictStandardDocumentList = [];
        }
    },
    extraReducers: {
        [getDictStandardDocumentList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictStandardDocumentList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<DictStandardDocumentResponseType>>) => {
            state.isLoading = false;
            state.dictStandardDocumentList = payload.results;
            state.error = '';
        },
        [getDictStandardDocumentList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.dictStandardDocumentList = [];
            state.error = payload;
        },
        [getDictStandardDocumentById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictStandardDocumentById.fulfilled.type]: (state, { payload }: PayloadAction<DictStandardDocumentResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [getDictStandardDocumentById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createDictStandardDocument.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createDictStandardDocument.fulfilled.type]: (state, { payload }: PayloadAction<DictStandardDocumentResponseType>) => {
            state.isLoading = false;
            state.dictStandardDocumentList.push(payload);
            state.dictStandardDocumentList = state.dictStandardDocumentList
                .sort((a: DictStandardDocumentResponseType, b: DictStandardDocumentResponseType) => a.value.localeCompare(b.value));
            state.error = '';
        },
        [createDictStandardDocument.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateDictStandardDocument.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateDictStandardDocument.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateDictStandardDocument.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteDictStandardDocument.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteDictStandardDocument.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteDictStandardDocument.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default DictStandardDocumentSlice.reducer;
