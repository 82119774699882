import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { DictFactoryIndexResponseType } from './types';
import {
    createDictFactoryIndex, deleteDictFactoryIndex, getDictFactoryIndexById, getDictFactoryIndexList, updateDictFactoryIndex
} from "./actions";
import { IPagingSearch } from "../../../interfaces";

const {name} = options

type DictFactoryIndexState = {
    dictFactoryIndexList: DictFactoryIndexResponseType[],
    isLoading: boolean
    error: string
};

const initialState: DictFactoryIndexState = {
    dictFactoryIndexList: [],
    isLoading: false,
    error: ''
};

export const DictFactoryIndexSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearDictFactoryIndexList(state) {
            state.dictFactoryIndexList = [];
        }
    },
    extraReducers: {
        [getDictFactoryIndexList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictFactoryIndexList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<DictFactoryIndexResponseType>>) => {
            state.isLoading = false;
            state.dictFactoryIndexList = payload.results;
            state.error = '';
        },
        [getDictFactoryIndexList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.dictFactoryIndexList = [];
            state.error = payload;
        },
        [getDictFactoryIndexById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictFactoryIndexById.fulfilled.type]: (state, { payload }: PayloadAction<DictFactoryIndexResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [getDictFactoryIndexById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createDictFactoryIndex.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createDictFactoryIndex.fulfilled.type]: (state, { payload }: PayloadAction<DictFactoryIndexResponseType>) => {
            state.isLoading = false;
            state.dictFactoryIndexList.push(payload);
            state.dictFactoryIndexList = state.dictFactoryIndexList
                .sort((a: DictFactoryIndexResponseType, b: DictFactoryIndexResponseType) => a.value.localeCompare(b.value));
            state.error = '';
        },
        [createDictFactoryIndex.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateDictFactoryIndex.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateDictFactoryIndex.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateDictFactoryIndex.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteDictFactoryIndex.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteDictFactoryIndex.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteDictFactoryIndex.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default DictFactoryIndexSlice.reducer;
