import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { DictWeldQaResponsibleResponseType } from './types';
import {
    createDictWeldQaResponsible, deleteDictWeldQaResponsible, getDictWeldQaResponsibleById, getDictWeldQaResponsibleList, updateDictWeldQaResponsible
} from "./actions";
import { IPagingSearch } from "../../../interfaces";

const {name} = options

type DictWeldQaResponsibleState = {
    dictWeldQaResponsibleList: DictWeldQaResponsibleResponseType[],
    isLoading: boolean
    error: string
};

const initialState: DictWeldQaResponsibleState = {
    dictWeldQaResponsibleList: [],
    isLoading: false,
    error: ''
};

export const DictWeldQaResponsibleSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearDictWeldQaResponsibleList(state) {
            state.dictWeldQaResponsibleList = [];
        }
    },
    extraReducers: {
        [getDictWeldQaResponsibleList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictWeldQaResponsibleList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<DictWeldQaResponsibleResponseType>>) => {
            state.isLoading = false;
            state.dictWeldQaResponsibleList = payload.results;
            state.error = '';
        },
        [getDictWeldQaResponsibleList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.dictWeldQaResponsibleList = [];
            state.error = payload;
        },
        [getDictWeldQaResponsibleById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictWeldQaResponsibleById.fulfilled.type]: (state, { payload }: PayloadAction<DictWeldQaResponsibleResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [getDictWeldQaResponsibleById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createDictWeldQaResponsible.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createDictWeldQaResponsible.fulfilled.type]: (state, { payload }: PayloadAction<DictWeldQaResponsibleResponseType>) => {
            state.isLoading = false;
            state.dictWeldQaResponsibleList.push(payload);
            state.dictWeldQaResponsibleList = state.dictWeldQaResponsibleList
                .sort((a: DictWeldQaResponsibleResponseType, b: DictWeldQaResponsibleResponseType) => a.second_name.localeCompare(b.second_name));
            state.error = '';
        },
        [createDictWeldQaResponsible.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateDictWeldQaResponsible.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateDictWeldQaResponsible.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateDictWeldQaResponsible.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteDictWeldQaResponsible.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteDictWeldQaResponsible.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteDictWeldQaResponsible.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default DictWeldQaResponsibleSlice.reducer;
