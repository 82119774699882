import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getWorkgroupListApi,
    getWorkgroupByIdApi,
    createWorkgroupApi,
    updateWorkgroupApi,
    deleteWorkgroupApi,
    getAllWorkgroupsListApi,
} from './api';
import options from './options';
import { WorkgroupBaseType } from './types';

const { name } = options;

export const getWorkgroupsList = createAsyncThunk(
    `${name}/geWorkgroupsList`,
    async (sector_id: string, thunkApi) => {
        try {
            return await getWorkgroupListApi(sector_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getWorkgroupById = createAsyncThunk(
    `${name}/getWorkgroupById`,
    async (workgroup_id: string, thunkApi) => {
        try {
            return await getWorkgroupByIdApi(workgroup_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createWorkgroup = createAsyncThunk(
    `${name}/createWorkgroup`,
    async (body: WorkgroupBaseType, thunkApi) => {
        try {
            return await createWorkgroupApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateWorkgroup = createAsyncThunk(
    `${name}/updateWorkgroup`,
    async (
        { uuid, body }: { uuid: string; body: WorkgroupBaseType },
        thunkApi
    ) => {
        try {
            return await updateWorkgroupApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteWorkgroup = createAsyncThunk(
    `${name}/deleteWorkgroup`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteWorkgroupApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getAllWorkgroupsList = createAsyncThunk(
    `${name}/getAllWorkgroupsList`,
    async (_, thunkApi) => {
        try {
            return await getAllWorkgroupsListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
