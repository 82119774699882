import request from 'utils/http/request';
import { DictFactoryNameBaseType, DictFactoryNameResponseType } from './types';
import { IPagingSearch } from 'interfaces';
import { getUrl } from '../../utils';
import options from './options';

const {apiUrl} = options
const url = getUrl(apiUrl);

export const getDictFactoryNameListApi = async (): Promise<IPagingSearch<DictFactoryNameResponseType>> => {
    return request.get(url(''));
}

export const getDictFactoryNameByIdApi = async (dictFactoryName_id:string): Promise<DictFactoryNameResponseType> => {
    return request.get(url(dictFactoryName_id));
};

export const createDictFactoryNameApi = async (body: DictFactoryNameBaseType): Promise<DictFactoryNameResponseType> => {
    return request.post(url(''), body);
}

export const updateDictFactoryNameApi = async (uuid: string, body: DictFactoryNameBaseType): Promise<DictFactoryNameResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteDictFactoryNameApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}
