import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { DictElementTypeResponseType } from './types';
import {
    createDictElementType, deleteDictElementType, getDictElementTypeById, getDictElementTypeList, updateDictElementType
} from "./actions";
import { IPagingSearch } from "../../../interfaces";

const {name} = options

type DictElementTypeState = {
    dictElementTypeList: DictElementTypeResponseType[],
    isLoading: boolean
    error: string
};

const initialState: DictElementTypeState = {
    dictElementTypeList: [],
    isLoading: false,
    error: ''
};

export const DictElementTypeSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearDictElementTypeList(state) {
            state.dictElementTypeList = [];
        }
    },
    extraReducers: {
        [getDictElementTypeList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictElementTypeList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<DictElementTypeResponseType>>) => {
            state.isLoading = false;
            state.dictElementTypeList = payload.results;
            state.error = '';
        },
        [getDictElementTypeList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.dictElementTypeList = [];
            state.error = payload;
        },
        [getDictElementTypeById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictElementTypeById.fulfilled.type]: (state, { payload }: PayloadAction<DictElementTypeResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [getDictElementTypeById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createDictElementType.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createDictElementType.fulfilled.type]: (state, { payload }: PayloadAction<DictElementTypeResponseType>) => {
            state.isLoading = false;
            state.dictElementTypeList.push(payload);
            state.dictElementTypeList = state.dictElementTypeList
                .sort((a: DictElementTypeResponseType, b: DictElementTypeResponseType) => a.value.localeCompare(b.value));
            state.error = '';
        },
        [createDictElementType.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateDictElementType.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateDictElementType.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateDictElementType.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteDictElementType.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteDictElementType.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteDictElementType.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default DictElementTypeSlice.reducer;
