import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDictStandardDocumentListApi, getDictStandardDocumentByIdApi, createDictStandardDocumentApi, updateDictStandardDocumentApi, deleteDictStandardDocumentApi 
} from './api';
import options from './options';
import {DictStandardDocumentBaseType} from "./types";

const {name} = options

export const getDictStandardDocumentList = createAsyncThunk(
    `${name}/getDictStandardDocumentList`,
    async (_, thunkApi) => {
        try {
            return await getDictStandardDocumentListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getDictStandardDocumentById = createAsyncThunk(
    `${name}/getDictStandardDocumentById`,
    async (pipe_id:string, thunkApi) => {
        try {
            return await getDictStandardDocumentByIdApi(pipe_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createDictStandardDocument = createAsyncThunk(
    `${name}/createDictStandardDocument`,
    async (body: DictStandardDocumentBaseType, thunkApi) => {
        try {
            return await createDictStandardDocumentApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateDictStandardDocument = createAsyncThunk(
    `${name}/updateDictStandardDocument`,
    async ({ uuid, body }: { uuid: string, body: DictStandardDocumentBaseType }, thunkApi) => {
        try {
            return await updateDictStandardDocumentApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDictStandardDocument = createAsyncThunk(
    `${name}/deleteDictStandardDocument`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteDictStandardDocumentApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
