export interface IDictionaryItem {
    name: string;
    code: string;
}

export const dictionariesItems: IDictionaryItem[] = [
    { name: 'Сотрудники сварочной бригады', code: 'worker' },
    { name: 'Строительные бригады', code: 'workgroup' },
    { name: 'Заводские номера элементов', code: 'factory_index' },
    { name: 'Заводы-поставщики', code: 'factory_name' },
    { name: 'Названия свариваемых элементов', code: 'element_type' },
    { name: 'Марки стали', code: 'steel_grade' },
    { name: 'Средства контроля', code: 'radiography_equipment' },
    { name: 'ГОСТ и ТУ', code: 'standard_document' },
    { name: 'Ответственные НК', code: 'weld-qa-responsible' },
    { name: 'Технологические карты', code: 'technical_sheet' },
];

export const positions = ['Сварщик', 'Мастер', 'Бригадир'];

export const generatePosition = (values: any) => {
    switch (values.position) {
        case 'Мастер':
            values.position = 'master';
            if (!values.stamp || values.stamp.trim() === '') {
                values.stamp = '';
            }
            break;
        case 'Бригадир':
            values.position = 'brigadier';
            break;
        case 'Сварщик':
            values.position = 'welder';
            break;
        default:
            break;
    }
    return values;
};
