import React, {useRef, useState} from 'react';
import {FileRejection, useDropzone} from 'react-dropzone';
import clsx from 'clsx';
import {
    Avatar,
    Box, Button,
    Card,
    Dialog,
    DialogContent,
    IconButton,
    Link,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {selectDicomData} from '../../../models/dicom-analystic/selectors';
import { useSnackbar } from 'notistack';
import { getDicomData } from 'models/dicom-analystic/actions';
import {isSuccessRequest} from "../../../utils/utils";
import { DicomAnalyticsTable } from './dicom-analytics-table';
import { WbLabelLoader } from 'components/wb-label-loader/wb-label-loader';
import { LoadingOverlay } from 'containers/loading-overlay/loading-overlay';

const useStyles = makeStyles(({ palette, spacing, typography }: Theme) => ({
    container: {
        minHeight: 150,
    },
    dialogTitle: {
        padding: spacing(3),
    },
    dropzone: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 150,
        height: "100%",
        flex: 1,
        cursor: "pointer",
        border: `1px solid ${palette.action.hover}`,
        borderStyle: "dashed",
        borderRadius: spacing(1),
    },
    uploadIcon: {
        margin: `${spacing(2)}px auto`,
        color: palette.primary.main,
        background: 'rgba(25, 118, 210, 0.12)',
    },
    errorIcon: {
        margin: `${spacing(2)}px auto`,
        color: palette.error.contrastText,
        background: palette.error.light,
    },
    fileCard: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: 72,
        padding: spacing(0, 2),
        marginBottom: spacing(2)
    },
    fileNameWrapper: {
        display: "flex",
        alignItems: "center",
    },
    fileMetaWrapper: {
        marginLeft: spacing(2)
    },
    fileName: {
        fontSize: typography.fontSize
    },
    fileSize: {
        fontSize: '0.75rem'
    },
    buttonsWrapper: {
        gap: '0.75rem',
        padding: spacing(3)
    },
    active: {
        border: `1px solid ${palette.primary.main}`
    },
    error: {
        border: `1px solid ${palette.error.main}`
    }
}));

const getFileSizeString = (size: number): string => {
    if (size === 0) return "0 Byte";
    const K_UNIT = 1024;
    const SIZES = ["Bytes", "Kb", "Mb", "Gb", "Tb"];

    let i = Math.floor(Math.log(size) / Math.log(K_UNIT));
    return parseFloat((size / Math.pow(K_UNIT, i)).toFixed(2)) + " " + SIZES[i];
};

interface IDashboardModalUploadWindow {
    open: boolean
    onClose: () => void
    fileMetaArray?: any
}

export const DashboardModalUploadWindow: React.FC<IDashboardModalUploadWindow> = ({ open, onClose }) => {
    const classes = useStyles();
    const [file, setFile] = useState<File | undefined>();
    const [fileError, setFileError] = useState('');
    const [isTableOpen, setIsTableOpen] = useState(false);
    const [fileMetaArray, setFileMetaArray] = useState<any>([]);

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { isLoading, file_meta } = useAppSelector(selectDicomData);
    
    // закрытие таблицы с анализом файла
    const handleTableClose = () => {
        setIsTableOpen(false);
    };

    // нажатие кнопки Отмена
    const onCancelClick = () => {
        onClose();
        setFile(undefined);
    };

    // анализ DCM файла
    const handleOpenAnalystic = async () => {
        if (file) {
            enqueueSnackbar('Начало анализа DCM файла, ожидайте', { variant: 'info' });
            const fd = new FormData();
            fd.append('file', file);
            const res = await dispatch(getDicomData(fd));
            if (isSuccessRequest(res)) {
                setFileMetaArray(Object.entries(res.payload).map(([tag, value]) => ({
                    tag,
                    ...(value as object)
                })));
                enqueueSnackbar('Анализ выполнен', { variant: 'success' });
                setIsTableOpen(true);
            } else {
                enqueueSnackbar('Ошибка при анализе', { variant: 'error' });
            }
        } else {
            enqueueSnackbar('Необходимо загрузить файл', { variant: 'error' });
        }
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        multiple: false,
        accept: {
            'application/dicom': ['.dcm']
        },
        onDrop: (acceptedFiles: File[]) => {
            if (acceptedFiles.length) {
                setFile(acceptedFiles[0]);
            }
        },
        onDropRejected: (fileRejections: FileRejection[]) => {
            // если файл не подходит под accept создаем ошибку на 4 сек
            setFileError(`Файл '${fileRejections[0].file?.name || '-'}' не соответствует формату. Разрешенный формат: '*.dcm'.`);
            setTimeout(() => setFileError(''), 4000);
        }
    });
    
    return (
        <Dialog
            open={open}
            onClose={onCancelClick}
            maxWidth="sm"
            fullWidth
        >
            <Box className={classes.dialogTitle}>
                <Typography align="left" color="textPrimary" variant="h4">
                    Выберете файл для анализа
                </Typography>
            </Box>
            <DialogContent className={classes.container}>

                <Box>
                    {file ? (
                        <Card className={classes.fileCard}>
                            <Box className={classes.fileNameWrapper}>
                                <Avatar className={classes.uploadIcon}>
                                    <DescriptionOutlinedIcon />
                                </Avatar>
                                <Box className={classes.fileMetaWrapper}>
                                    <Typography className={classes.fileName}>
                                        {file.name}
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <Typography className={classes.fileSize} color="textSecondary">
                                            {getFileSizeString(file.size)}
                                        </Typography>
                                        <LoadingOverlay loading={isLoading} />
                                    </Box>
                                </Box>
                            </Box>
                            <IconButton
                                size="small"
                                onClick={onCancelClick}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Card>
                    ) : (
                        <Box {...getRootProps({
                            className: clsx(classes.dropzone,
                                { [classes.active]: isDragActive },
                                { [classes.error]: fileError },
                            )})}
                        >
                            <input
                                {...getInputProps()}
                                aria-label="dicom-input"
                            />
                            {
                                isDragActive ? (
                                    <Typography align="center" color="primary" variant="h5">
                                        Отпустите для загрузки
                                    </Typography>
                                ) : fileError ? (
                                    <Box>
                                        <Avatar className={classes.errorIcon}>
                                            <ErrorOutlineIcon />
                                        </Avatar>
                                        <Typography align="center" variant="body2" color="error" gutterBottom>
                                            {fileError}
                                        </Typography>
                                    </Box>
                                ) : file ? (
                                    <Box />
                                ) : (
                                    <Box>
                                        <Avatar className={classes.uploadIcon}>
                                            <CloudUploadOutlinedIcon />
                                        </Avatar>
                                        <Typography align="center">
                                            <Link underline="always">Нажмите для загрузки</Link> или перетащите файл
                                        </Typography>
                                        <Typography align="center" variant="body2" color="textSecondary" gutterBottom>
                                            Для загрузки доступен только формат .dcm
                                        </Typography>
                                    </Box>
                                )
                            }
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <Box className={classes.buttonsWrapper} display="flex" justifyContent="end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenAnalystic}
                >
                    Анализ
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancelClick}
                >
                    Отмена
                </Button>
            </Box>
            {isTableOpen ? (
                <DicomAnalyticsTable 
                    open={isTableOpen}
                    onClose={handleTableClose}
                    file={file}
                    getFileSizeString={getFileSizeString}
                    tableData={fileMetaArray}
                    isLoading={isLoading}
                />
            ) : (
                null
            )}
        </Dialog>
    );
};