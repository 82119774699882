import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWorkerListApi, getWorkerByIdApi, createWorkerApi, updateWorkerApi, deleteWorkerApi 
} from './api';
import options from './options';
import {DictWorkerBaseType} from "./types";

const {name} = options

export const getWorkerList = createAsyncThunk(
    `${name}/getWorkerList`,
    async (_, thunkApi) => {
        try {
            return await getWorkerListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getWorkerById = createAsyncThunk(
    `${name}/getWorkerById`,
    async (worker_id:string, thunkApi) => {
        try {
            return await getWorkerByIdApi(worker_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createWorker = createAsyncThunk(
    `${name}/createWorker`,
    async (body: DictWorkerBaseType, thunkApi) => {
        try {
            return await createWorkerApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateWorker = createAsyncThunk(
    `${name}/updateWorker`,
    async ({ uuid, body }: { uuid: string, body: DictWorkerBaseType }, thunkApi) => {
        try {
            return await updateWorkerApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteWorker = createAsyncThunk(
    `${name}/deleteWorker`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteWorkerApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
