import React from 'react';
import clsx from 'clsx';
import { Divider, makeStyles, Tab as TabBase, Tabs as TabsBase } from '@material-ui/core';
import { Tab } from './tab';

interface ITabsProps {
    className?: string;
    tabClassName?: string;
    orientation?: "horizontal" | "vertical";
    children?: React.ReactNodeArray;
    indicatorColor?: "secondary" | "primary" | undefined;
    textColor?: "secondary" | "primary" | "inherit" | undefined;
    tabValue: number;
    onChange: (value: any) => void;
    variant?: "standard" | "scrollable" | "fullWidth";
    scrollButtons?: "auto" | "desktop" | "on" | "off";
    muiTabsId?: string;
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        height: "inherit",
        "& > .MuiTabs-root": {
            minHeight: 40
        }
    },
    flexBox: {
        display: "flex"
    },
    divider: {
        backgroundColor: "#e0e0e0",
    },
    tabs: {
        "borderRight": "1px solid #e0e0e0",
        "paddingTop": "16px",
        "minWidth": 290,
        "& button": {
            // padding: "10px 12px"
        },
    },
    tab: {
        height: "inherit",
    },
}));

export function Tabs({
                  className,
                  tabClassName,
                  orientation = "horizontal",
                  indicatorColor = "primary",
                  textColor,
                  tabValue,
                  onChange,
                  children,
                  variant,
                  scrollButtons,
                  muiTabsId = "mui-tabs"
              }: ITabsProps) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className, {[classes.flexBox]: orientation === "vertical"})}>
            <TabsBase
                value={tabValue || 0}
                orientation={orientation}
                variant={variant}
                scrollButtons={scrollButtons}
                onChange={(e, newValue) => onChange(newValue)}
                indicatorColor={indicatorColor}
                textColor={textColor}
                className={clsx({[classes.tabs]: orientation === "vertical"})}
            >
                {children && children.map((childrenItem: any, index: number) => (
                    <TabBase
                        key={`tab-${index}`}
                        id={`${muiTabsId}-${index}`}
                        label={childrenItem.props.label}
                        disabled={childrenItem.props.disabled}
                    />
                ))}
            </TabsBase>
            <Divider className={classes.divider} />
            {children && children.map((childrenItem: any, index: number) => {
                return (
                    <Tab
                        key={`tab-panel-${index}`}
                        active={tabValue === index}
                        className={clsx(classes.tab, tabClassName)}
                    >
                        {childrenItem}
                    </Tab>
                );
            })}
        </div>
    );
}
