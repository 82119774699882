import request from 'utils/http/request';
import { DataImageBaseType, DataImageResponseType } from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getDataImageByRadiographyIdApi = async (radiographyId: string): Promise<any> => {
    return request.get(url(`?radiography_item=${radiographyId}`));
}

export const createDataImageApi = async (body: DataImageBaseType): Promise<DataImageResponseType> => {
    return request.post(url(''), body);
}

export const deleteDataImageApi = async (dataImage: string): Promise<any> => {
    return request.delete(url(`${dataImage}/`));
}