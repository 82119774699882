import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth/slice';
import elementReducer from './weldbooks/element/slice';
import workgroupReducer from './objects/workgroup/slice';
import objectReducer from './objects/object/slice';
import dictFactoryNameReducer from './dictionaries/dict-factory-name/slice';
import dictFactoryIndexReducer from './dictionaries/dict-factory-index/slice';
import dictElementTypeReducer from './dictionaries/dict-element-type/slice';
import weldbookCumulativeReducer from './cumulative/weldbook_cumulative/slice';
import radiographyCumulativeReducer from './cumulative/radiography_cumulative/slice';
import sectorReducer from './objects/sector/slice';
import weldBookReducer from './weldbooks/weldbook/slice';
import jobReducer from './weldbooks/job/slice';
import workerReducer from './dictionaries/dict-worker/slice';
import radiographyEquipmentReducer from './dictionaries/dict-radiography-equipment/slice';
import dictWorkgrouReducer from './dictionaries/dict-workgroup/slice';
import radiographyReducer from './weldbooks/radiography/slice';
import defectReducer from './weldbooks/defect/slice';
import seamReducer from './weldbooks/seam/slice';
import referencesReducer from './weldbooks/references/slice';
import taskStatusReducer from './weldbooks/task-status/slice';
import weldbookReportReducer from './report/weldbook/slice';
import conclusionReducer from './report/conclusion/slice';
import registryReducer from './report/registry/slice';
import dicomMetaReducer from './dicom-analystic/slice';
import dataImageReducer from './weldbooks/data-image/slice';
import tasksReducer from './tasks/slice';
import dictStandardDocumentReducer from './dictionaries/dict-standard-document/slice';
import dictSteelGradeReducer from './dictionaries/dict-steel-grade/slice';
import dictWeldQaResponsibleReducer from './dictionaries/dict-weld-qa-responsible/slice';
import dictTechnicalSheetReducer from './dictionaries/dict-technical-sheet/slice';

const modelReducers = combineReducers({
    auth: authReducer,
    element: elementReducer,
    workgroup: workgroupReducer,
    object: objectReducer,
    dictFactoryName: dictFactoryNameReducer,
    dictFactoryIndex: dictFactoryIndexReducer,
    dictElementType: dictElementTypeReducer,
    dictStandardDocument: dictStandardDocumentReducer,
    dictSteelGrade: dictSteelGradeReducer,
    dictWeldQaResponsible: dictWeldQaResponsibleReducer,
    dictTechnicalSheet: dictTechnicalSheetReducer,
    sector: sectorReducer,
    weldBook: weldBookReducer,
    job: jobReducer,
    worker: workerReducer,
    radiographyEquipment: radiographyEquipmentReducer,
    dictWorkgroup: dictWorkgrouReducer,
    radiography: radiographyReducer,
    defects: defectReducer,
    seam: seamReducer,
    references: referencesReducer,
    taskStatus: taskStatusReducer,
    weldbookReport: weldbookReportReducer,
    conclusion: conclusionReducer,
    file_data: dicomMetaReducer,
    weldbookCumulative: weldbookCumulativeReducer,
    radiographyCumulative: radiographyCumulativeReducer,
    dataImage: dataImageReducer,
    tasks: tasksReducer,
    registry: registryReducer,
});

export default modelReducers;
