import * as React from 'react';
import { Theme, TextField as TextFieldBase } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

interface ITextFieldProps {
  className?: string;
  inputRef?: any;
  autoComplete?: string;
  label?: string;
  value?: any;
  name?: string;
  type?: string;
  variant?: 'outlined' | 'filled' | 'standard' | undefined;
  margin?: 'none' | 'dense' | 'normal';
  placeholder?: string;
  width?: number|string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  multiline?: boolean;
  rows?: number|string;
  helperText?: string;
  size?: 'small' | 'medium';
  inputId?: string;
  error?: boolean;
  errorValue?: string;
  InputProps?: any;
}

const useStyles = makeStyles(({ palette }: Theme) => ({
    formControl: {
        '& > div': {
            backgroundColor: palette.background.default
        }
    }
}));

function TextField({
    className,
    inputRef,
    autoComplete = 'off',
    label,
    width = '100%',
    value = '',
    name,
    type,
    variant = 'outlined',
    margin = 'normal',
    placeholder,
    required,
    autoFocus,
    fullWidth,
    onChange,
    onFocus,
    onBlur,
    multiline,
    rows,
    helperText = '',
    size = 'medium',
    inputId = 'mui-textfield',
    error,
    errorValue,
    InputProps,
    ...restProps
}: ITextFieldProps) {
    const classes = useStyles();

    return (
        <TextFieldBase
            aria-label="text-field"
            inputRef={inputRef}
            autoComplete={autoComplete}
            name={name}
            type={type}
            label={label}
            value={value}
            placeholder={placeholder}
            className={clsx(className, classes.formControl)}
            margin={margin}
            variant={variant}
            style={{ width }}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            required={required}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            helperText={errorValue || helperText}
            error={Boolean(errorValue) || error}
            size={size}
            id={inputId}
            InputProps={InputProps}
            {...restProps}
        />
    );
}

export default TextField;
