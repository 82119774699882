import { createContext } from 'react';
import { UserType } from '../models/auth/types';

export interface IAuthContext {
    // checkingSession: boolean;
    // token: string | null;
    // idToken: string | null;
    // expiresAt: number | null;
    // isAuthenticated: boolean;
    // handleAuthentication: () => void;
    user: UserType | null;
    // dispatch: (obj: any) => void
    onLogin: (body: any) => void;
    onLogout: () => void;
}

export const authContextDefaults: IAuthContext = {
    // checkingSession: false,
    // expiresAt: null,
    // token: null,
    // idToken: null,
    // isAuthenticated: false,
    // handleAuthentication: () => null,
    user: null,
    // dispatch: () => null,
    onLogin: () => null,
    onLogout: () => null
};

export default createContext<IAuthContext>(authContextDefaults);
