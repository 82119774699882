import {createTheme} from '@material-ui/core';

import './fonts/iconfont/material-icons.css';
import './fonts/passwordfont/password-font.css'
import './fonts/roboto-v20-latin_cyrillic/roboto-v20-latin_cyrillic.css';
import getTypography from './typography';

export const getAppTheme = () => createTheme({
  typography: getTypography(),
  palette: paletteOverrides,
  spacing: 8,
  overrides: overrides
});

const paletteOverrides = {
  primary: {
    main: '#217BD7'
  },
  text: {
    primary: '#3C4853'
  }
}

const overrides = {
  MuiButton: {
    root: {
      padding: "6px 16px"
    },
    outlined: {
      padding: "6px 16px"
    },
    text: {
      padding: "6px 8px"
    }
  },
  MuiIconButton: {
    root: {
      padding: "12px"
    }
  },
  MuiTab: {
    root: {
      padding: "10px 12px"
    }
  },
  MuiTextField: {
    root: {
      "& > div.MuiAutocomplete-inputRoot": {
        padding: "4px", // было 9px
        paddingLeft: "8px",
        paddingRight: "56px",
        background: "#FFFFFF",
      }
    }
  },

  MuiInputLabel: {
    root: {
      whiteSpace: "nowrap" as "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "80%",
      height: "100%"
    },
    shrink: {
      maxWidth: "109%"
    },
    outlined: {
      transform: "translate(14px, 15px) scale(1)" // было (14px, 20px)
    }
  },
  MuiOutlinedInput: {
    input: {
      padding: "13.5px 14px", // чуть уменьшил, было 18,14
      background: "#FFFFFF"
    }
  },
  MuiTableCell: {
    root: {
      padding: "10px 16px"
    },
    head: {
      padding: "10px 16px"
    }
  },
  MuiToolbar: {
    regular: {
      minHeight: 64
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 12,
    }
  },
  MuiCssBaseline: {
    "@global": {
      "a": {
        cursor: "pointer",
      }
    }
  }
};
