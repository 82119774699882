import {lazy} from 'react';
import {WithRouter} from "../../containers/with-router";

const Dashboard = lazy(() => import('./forms/dashboard'));

export default (): JSX.Element => (
    <WithRouter>
        <Dashboard />
    </WithRouter>
);
