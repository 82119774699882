import React, { useRef, useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import {
    createWorker,
    deleteWorker,
    getWorkerList,
    updateWorker,
} from 'models/dictionaries/dict-worker/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { DictWorkerResponseType } from 'models/dictionaries/dict-worker/types';
import { selectDictWorkerState } from 'models/dictionaries/dict-worker/selectors';
import { WorkerDialog } from './worker-dialog';
import { WorkerDeletingDialog } from './worker-deleting-dialog';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    tableContainer: {
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        height: 'calc(100vh - 200px)',
        '& .p-paginator': {
            position: 'fixed',
            bottom: '0,75rem',
            // right: '40%',
            left: '45%',
            width: 'max-content',
        },
        '& .p-datatable.p-component': {
            height: 'calc(100vh - 200px)',
        },
        '& .p-datatable-wrapper': {
            height: 'calc(100vh - 260px)',
        },
    },
}));

export const DictWorkerTable = () => {
    const [selectedItem, setSelectedItem] =
        useState<DictWorkerResponseType | null>(null);
    const [isWorkerDialogOpen, setIsWorkerDialogOpen] =
        useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const { workerList, isLoading } = useAppSelector(selectDictWorkerState);
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);
    const classes = useStyles();

    const columns = [
        {
            title: 'Фамилия',
            field: 'second_name',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Имя',
            field: 'first_name',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Отчество',
            field: 'third_name',
            frozen: false,
        },
        {
            title: 'Должность',
            field: 'position',
            frozen: false,
        },
        {
            title: 'Удостоверение',
            field: 'documents',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Клеймо',
            field: 'stamp',
            frozen: false,
            sortable: true,
        },
    ];

    const handleSortDocuments = (event: any) => {
        return [...workerList].sort((data1: any, data2: any) => {
            const value1 = data1.documents;
            const value2 = data2.documents;
            if (value1 == null && value2 == null) return 0;
            if (value2 == null) return -1;
            if (value1 == null) return 1;
            if (typeof value1 === 'string' && typeof value2 === 'string') {
                return value1.localeCompare(value2) * event.order;
            }
            if (value1 < value2) return -1 * event.order;
            if (value1 > value2) return 1 * event.order;
            return 0;
        });
    };

    const actionTemplate = (row: any) => (
        <div className="flex">
            <Button
                text
                severity="secondary"
                icon="pi pi-pencil"
                className="mr-4 p-0"
                onClick={(event) => handleEditClick(row)}
            />
            <Button
                text
                severity="secondary"
                icon="pi pi-trash"
                className="p-0"
                onClick={(event) => handleDeleteClick(row)}
            />
        </div>
    );

    const rowTemplate = (data: any, field: string) => {
        if (!data) {
            return <div className="flex">-</div>;
        }
        if (field === 'position') {
            let displayField: string = '';
            switch (data) {
                case 'master':
                    displayField = 'Мастер';
                    break;
                case 'brigadier':
                    displayField = 'Бригадир';
                    break;
                case 'welder':
                    displayField = 'Сварщик';
                    break;
                default:
                    break;
            }
            return <div className="flex">{displayField}</div>;
        } else if (field === 'item_meta') {
            return <div className="flex">{data.documents}</div>;
        } else {
            return <div className="flex">{data}</div>;
        }
    };

    const handleEditClick = (item: DictWorkerResponseType) => {
        setSelectedItem(item);
        setIsWorkerDialogOpen(true);
    };
    const handleDeleteClick = (item: DictWorkerResponseType) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    };

    const handleUpdate = (item_uuid: string, item: DictWorkerResponseType) => {
        dispatch(updateWorker({ uuid: item_uuid, body: item }))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Сотрудник сварочной бригады обновлён',
                        life: 3000,
                    },
                ]);
                dispatch(getWorkerList()).unwrap();
                setSelectedItem(null);
                setIsWorkerDialogOpen(false);
            });
    };
    const handleCreate = (item: DictWorkerResponseType) => {
        dispatch(createWorker(item))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Сотрудник сварочной бригады создан',
                        life: 3000,
                    },
                ]);
                dispatch(getWorkerList()).unwrap();
                setIsWorkerDialogOpen(false);
            });
    };

    const handleDelete = (item: DictWorkerResponseType) => {
        setDeleteDialogOpen(false);
        dispatch(deleteWorker(item.item_uuid))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Сотрудник удалён из базы',
                        life: 3000,
                    },
                ]);
                dispatch(getWorkerList()).unwrap();
                setSelectedItem(null);
            });
    };

    const handleClose = () => {
        setDeleteDialogOpen(false);
        setIsWorkerDialogOpen(false);
        setSelectedItem(null);
    };

    useEffect(() => {
        dispatch(getWorkerList()).unwrap();
    }, []);

    return (
        <div className={classes.tableContainer}>
            <Toast ref={toast} />
            <div className="flex justify-content-between align-items-center">
                <p className="block text-2xl font-bold">
                    Сотрудники сварочной бригады
                </p>
                <div>
                    <Button
                        color="primary"
                        onClick={() => setIsWorkerDialogOpen(true)}
                    >
                        Добавить сотрудника
                    </Button>
                </div>
            </div>

            <DataTable
                value={workerList}
                scrollable
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                selectionMode="single"
                rowHover
                emptyMessage="Работники пока не добавлены"
                className="mt-2"
            >
                {columns.map((col, i) => {
                    if (col.field !== 'item_meta') {
                        return (
                            <Column
                                key={col.field}
                                field={col.field}
                                header={col.title}
                                style={{ minWidth: '100px' }}
                                frozen={!!col.frozen}
                                sortable={col.sortable}
                                body={(row) =>
                                    rowTemplate(row[`${col.field}`], col.field)
                                }
                            />
                        );
                    }
                    if (col.field === 'item_meta') {
                        return (
                            <Column
                                key={col.field}
                                field={col.field}
                                header={col.title}
                                style={{ minWidth: '100px' }}
                                frozen={!!col.frozen}
                                sortable={col.sortable}
                                body={(row) =>
                                    rowTemplate(row[`${col.field}`], col.field)
                                }
                                sortFunction={(row) => handleSortDocuments(row)}
                            />
                        );
                    }
                })}
                <Column header="" body={actionTemplate} />
            </DataTable>
            {Boolean(isWorkerDialogOpen) && (
                <WorkerDialog
                    formData={selectedItem}
                    onClose={handleClose}
                    onUpdate={handleUpdate}
                    onCreate={handleCreate}
                />
            )}
            {Boolean(deleteDialogOpen) && (
                <WorkerDeletingDialog
                    workerData={selectedItem}
                    onDelete={handleDelete}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};
