import React, { ReactElement, useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import {
    DictWorkerBaseType,
    DictWorkerResponseType,
} from 'models/dictionaries/dict-worker/types';
import { generatePosition, positions } from 'containers/dictionaries/constants';
import { useAppSelector } from 'store/hooks';
import { selectDictWorkerState } from 'models/dictionaries/dict-worker/selectors';

interface IWorkerDialogProps {
    formData: any;
    onClose: () => void;
    onCreate?: (item: DictWorkerResponseType) => void;
    onUpdate?: (item_uuid: string, item: DictWorkerResponseType) => void;
}

const dictWorkerInitialValues: DictWorkerBaseType = {
    first_name: '',
    second_name: '',
    third_name: '',
    position: '',
    stamp: '',
    documents: '',
};

export const WorkerDialog = ({
    formData,
    onClose,
    onCreate,
    onUpdate,
}: IWorkerDialogProps): ReactElement => {
    const [visible, setVisible] = useState<boolean>(false);
    const { workerList, isLoading } = useAppSelector(selectDictWorkerState);

    const formik = useFormik({
        initialValues: formData || dictWorkerInitialValues,
        validateOnBlur: true,
        validate: (data) => {
            const errors: any = {};

            if (!data.first_name || data.first_name?.trim() === '') {
                errors.first_name = 'Обязательное поле ввода';
            }
            if (!data.second_name || data.second_name?.trim() === '') {
                errors.second_name = 'Обязательное поле ввода';
            }
            if (!data.position) {
                errors.position = 'Обязательное поле ввода';
            }
            if (!data.documents || data.documents?.trim() === '') {
                errors.documents = 'Обязательное поле ввода';
            }
            if (
                (data.position === 'Сварщик' || data.position === 'Бригадир') &&
                (!data.stamp || data.stamp?.trim() === '')
            ) {
                errors.stamp = 'Обязательное поле ввода';
            }
            const filtereredWorkerList = formData
                ? workerList.filter(
                      (f: DictWorkerResponseType) =>
                          f.item_uuid !== data.item_uuid
                  )
                : workerList;
            const isStampUnique = filtereredWorkerList.some(
                (worker) => worker.stamp === formik.values.stamp
            );
            if ((data.stamp || data.stamp?.trim() !== '') && isStampUnique) {
                errors.stamp = 'Данное клеймо уже существует';
            }
            const isDocumentsUnique = filtereredWorkerList.some(
                (worker) => worker.documents === formik.values.documents
            );
            if (
                (data.documents || data.documents?.trim() !== '') &&
                isDocumentsUnique
            ) {
                errors.documents = 'Данное удостоверение уже существует';
            }

            if (data.first_name.length > 255) {
                errors.first_name =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }
            if (data.second_name.length > 255) {
                errors.second_name =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }
            if (data.third_name.length > 255) {
                errors.third_name =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }
            if (data.position.length > 255) {
                errors.position =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }
            if (data.documents.length > 255) {
                errors.documents =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            if (data.stamp.length > 255) {
                errors.stamp =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            return errors;
        },
        onSubmit: (values: any) => {
            const data: any = generatePosition(values);
            if (formData) {
                onUpdate && onUpdate(formData.item_uuid, data);
            } else {
                onCreate && onCreate(data);
            }
        },
    });

    const isFormFieldInvalid = (name: any) =>
        !!(
            formik.touched[name as keyof DictWorkerBaseType] &&
            formik.errors[name as keyof DictWorkerBaseType]
        );

    const isFormInvalid = () => {
        const filtereredWorkerList = formData
            ? workerList.filter(
                  (f: DictWorkerResponseType) =>
                      f.item_uuid !== formData.item_uuid
              )
            : workerList;
        const isDocumentsUnique = filtereredWorkerList.some(
            (worker) => worker.documents === formik.values.documents
        );
        if (
            formik.values.first_name.trim() &&
            formik.values.second_name.trim() &&
            formik.values.position &&
            formik.values.documents.trim() &&
            formik.values.first_name.length <= 255 &&
            formik.values.second_name.length <= 255 &&
            formik.values.documents.length <= 255 &&
            !isDocumentsUnique
        ) {
            if (
                formik.values.position === 'Сварщик' ||
                formik.values.position === 'Бригадир'
            ) {
                const isStampUnique = filtereredWorkerList.some(
                    (worker) => worker.stamp === formik.values.stamp
                );
                if (
                    formik.values.stamp?.trim() &&
                    formik.values.stamp.trim().length <= 255 &&
                    !isStampUnique
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (formData) {
            let displayField: string = '';
            switch (formData.position) {
                case 'master':
                    displayField = 'Мастер';
                    break;
                case 'brigadier':
                    displayField = 'Бригадир';
                    break;
                case 'welder':
                    displayField = 'Сварщик';
                    break;
                default:
                    break;
            }
            formik.setFieldValue('position', displayField);
        }
        setVisible(true);
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
            <Dialog
                header={`${
                    formData ? 'Редактирование' : 'Добавление'
                } сотрудника`}
                visible={visible}
                style={{ width: '40vw' }}
                onHide={onClose}
            >
                <div className="grid align-items-end">
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="second_name">
                                Фамилия <span className="required">*</span>
                            </label>
                            <InputText
                                id="second_name"
                                name="second_name"
                                value={formik.values.second_name}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'second_name',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('second_name'),
                                })}
                                tooltip={formik.errors.second_name as string}
                                tooltipOptions={{
                                    disabled:
                                        !isFormFieldInvalid('second_name'),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="first_name">
                                Имя <span className="required">*</span>
                            </label>
                            <InputText
                                id="first_name"
                                name="first_name"
                                value={formik.values.first_name}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'first_name',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('first_name'),
                                })}
                                tooltip={formik.errors.first_name as string}
                                tooltipOptions={{
                                    disabled: !isFormFieldInvalid('first_name'),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="third_name">Отчество</label>
                            <InputText
                                id="third_name"
                                name="third_name"
                                value={formik.values.third_name}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'third_name',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('third_name'),
                                })}
                                tooltip={formik.errors.third_name as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.third_name,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="position">
                                Должность <span className="required">*</span>
                            </label>
                            <Dropdown
                                value={formik.values.position}
                                onChange={(e) =>
                                    formik.setFieldValue('position', e.value)
                                }
                                options={positions}
                                placeholder="Выберите должность работника"
                                appendTo="self"
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="documents">
                                Удостоверение{' '}
                                <span className="required">*</span>
                            </label>
                            <InputText
                                id="documents"
                                name="documents"
                                value={formik.values.documents}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'documents',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('documents'),
                                })}
                                tooltip={formik.errors.documents as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.documents,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="stamp">
                                Клеймо{' '}
                                {(formik.values.position === 'Сварщик' ||
                                    formik.values.position === 'Бригадир') && (
                                    <span className="required">*</span>
                                )}
                            </label>
                            <InputText
                                id="stamp"
                                name="stamp"
                                value={formik.values.stamp}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'stamp',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required={
                                    formik.values.position === 'Сварщик' ||
                                    formik.values.position === 'Бригадир'
                                }
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('stamp'),
                                })}
                                tooltip={formik.errors.stamp as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.stamp,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-content-end mt-3">
                    <Button
                        outlined
                        onClick={onClose}
                        label="Отмена"
                        className="mr-3"
                    />
                    {!formData && (
                        <Button
                            type="submit"
                            label="Добавить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                    {formData && (
                        <Button
                            type="submit"
                            label="Сохранить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                </div>
            </Dialog>
        </form>
    );
};
