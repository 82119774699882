import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getDictRadiographyEquipListApi,
    getDictRadiographyEquipByIdApi,
    createDictRadiographyEquipApi,
    updateDictRadiographyEquipApi,
    deleteDictRadiographyEquipApi,
} from './api';
import options from './options';
import { DictRadiographyEquipBaseType } from './types';

const { name } = options;

export const getDictRadiographyEquipList = createAsyncThunk(
    `${name}/getDictRadiographyEquipList`,
    async (_, thunkApi) => {
        try {
            return await getDictRadiographyEquipListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getDictRadiographyEquipById = createAsyncThunk(
    `${name}/getDictRadiographyEquipById`,
    async (object_id: string, thunkApi) => {
        try {
            return await getDictRadiographyEquipByIdApi(object_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createDictRadiographyEquip = createAsyncThunk(
    `${name}/createDictRadiographyEquip`,
    async (body: DictRadiographyEquipBaseType, thunkApi) => {
        try {
            return await createDictRadiographyEquipApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateDictRadiographyEquip = createAsyncThunk(
    `${name}/updateDictRadiographyEquip`,
    async (
        { uuid, body }: { uuid: string; body: DictRadiographyEquipBaseType },
        thunkApi
    ) => {
        try {
            return await updateDictRadiographyEquipApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDictRadiographyEquip = createAsyncThunk(
    `${name}/deleteDictRadiographyEquip`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteDictRadiographyEquipApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
