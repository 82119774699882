import request from 'utils/http/request';
import {DictWorkerBaseType, DictWorkerResponseType} from './types';
import { getUrl } from '../../utils';
import options from './options';

const {apiUrl} = options
const url = getUrl(apiUrl);

export const getWorkerListApi = async (): Promise<DictWorkerResponseType[]> => {
    return request.get(url(''));
}

export const getWorkerByIdApi = async (worker_id:string): Promise<DictWorkerResponseType> => {
    return request.get(url(worker_id));
};

export const createWorkerApi = async (body: DictWorkerBaseType): Promise<DictWorkerResponseType> => {
    return request.post(url(''), body);
}

export const updateWorkerApi = async (uuid: string, body: DictWorkerBaseType): Promise<DictWorkerResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteWorkerApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}