import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import {
    createWeldBookApi,
    deleteWeldBookApi,
    getWeldBookByIdApi,
    getWeldBookListByWorkgroupIdApi,
    getWeldBookListBySectorIdApi,
    updateWeldBookApi
} from './api';
import {WeldBookFormType} from './types';

const { name } = options;

export const getWeldBookListByWorkgroupId = createAsyncThunk(
    `${name}/getWeldBookListByWorkgroupId`,
    async (id: string, thunkApi) => {
        try {
            return await getWeldBookListByWorkgroupIdApi(id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
)

export const getWeldBookListBySectorId = createAsyncThunk(
    `${name}/getWeldBookListBySectorId`,
    async (id: string, thunkApi) => {
        try {
            return await getWeldBookListBySectorIdApi(id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
)

export const getWeldBookById = createAsyncThunk(
    `${name}/getWeldBookById`,
    async (uuid: string, thunkApi) => {
        try {
            return await getWeldBookByIdApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
)

export const createWeldBook = createAsyncThunk(
    `${name}/createWeldBook`,
    async (body: WeldBookFormType, thunkApi) => {
        try {
            return await createWeldBookApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
)

export const updateWeldBook = createAsyncThunk(
    `${name}/updateWeldBook`,
    async ({ uuid, body }: { uuid: string, body: WeldBookFormType | any & { item_uuid: string } } , thunkApi) => {
        try {
            return await updateWeldBookApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
)

export const deleteWeldBook = createAsyncThunk(
    `${name}/deleteWeldBook`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteWeldBookApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
)
