import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import {
    createRadiographyApi,
    downloadDcomFileApi,
    getRadiographyListByJobIdApi,
    updateRadiographyApi,
    getRadiographyByIdApi,
    deleteRadiographyApi,
} from './api';
import {
    RadiographyBaseType,
    RadiographyCreateBody,
    RadiographyDownloadBody,
} from './types';

const { name } = options;

export const getRadiographyListByJobId = createAsyncThunk(
    `${name}/getRadiographyListByJobId`,
    async (weldbook_id: string, { rejectWithValue }) => {
        try {
            return await getRadiographyListByJobIdApi(weldbook_id);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);

export const getRadiographyById = createAsyncThunk(
    `${name}/getRadiographyById`,
    async (item_uuid: string, thunkApi) => {
        try {
            return await getRadiographyByIdApi(item_uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createRadiography = createAsyncThunk(
    `${name}/createRadiography`,
    async (body: any, { rejectWithValue, signal }) => {
        try {
            return await createRadiographyApi(body, signal);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);

export const updateRadiography = createAsyncThunk(
    `${name}/updateRadiography`,
    async (
        {
            body,
            radiography_id,
        }: { body: RadiographyCreateBody; radiography_id: string },
        { rejectWithValue, signal }
    ) => {
        try {
            return await updateRadiographyApi(body, radiography_id, signal);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);

export const downloadDcomFile = createAsyncThunk(
    `${name}/downloadDcomFile`,
    async (body: any, { rejectWithValue, signal }) => {
        try {
            return await downloadDcomFileApi(body, signal);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);

export const deleteRadiography = createAsyncThunk(
    `${name}/deleteRadiography`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteRadiographyApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
