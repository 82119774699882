import request from 'utils/http/request';
import { getUrl } from 'models/utils';
import options from './options';
import { WeldbookCumulativeType } from './types';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getWeldbookCumulativeApi = async (params:string): Promise<WeldbookCumulativeType[]> => {
    return request.get(url(params));
}