import request from 'utils/http/request';
import { getUrl } from '../../utils';
import options from './options';
import {WeldBookFormType, WeldBookResponseType} from './types';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getWeldBookListByWorkgroupIdApi = async (workgroup_id: string): Promise<WeldBookResponseType[]> => {
    return request.get(url(`?workgroup_item=${workgroup_id}`));
}

export const getWeldBookListBySectorIdApi = async (sector_id: string): Promise<WeldBookResponseType[]> => {
    return request.get(url(`?sector_item=${sector_id}`));
}

export const getWeldBookByIdApi = async (uuid:string): Promise<WeldBookResponseType> => {
    return request.get(url(uuid));
}

export const createWeldBookApi = async (body: WeldBookFormType): Promise<WeldBookResponseType> => {
    return request.post(url(''), body);
}

export const updateWeldBookApi = async (uuid: string, body: WeldBookFormType): Promise<WeldBookResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteWeldBookApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}
