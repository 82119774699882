import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { useWindowHeight } from 'utils/hooks';

const useStyles = makeStyles(({ palette, spacing, typography }: Theme) => ({
    container: {
        minHeight: 280
        // margin: spacing(2, 6)
    },
    fileDataWrapper: {
        alignItems: "center"
    },
    fileMetaWrapper: {
        margin: spacing(3),
        gap: spacing(2),
        alignItems: "center"
    },
    fileName: {
        fontSize: typography.fontSize
    },
    fileSize: {
        fontSize: '0.75rem'
    },
    buttonsWrapper: {
        gap: '0.75rem',
        padding: spacing(3)
    },
    active: {
        border: `1px solid ${palette.primary.main}`
    },
    error: {
        border: `1px solid ${palette.error.main}`
    }
}));

interface IDicomAnalyticsTable {
    open: boolean
    onClose: any
    file: any
    getFileSizeString: any
    tableData: any
    isLoading: boolean
}

export const DicomAnalyticsTable: React.FC<IDicomAnalyticsTable> = ({ isLoading, open, onClose, file, getFileSizeString, tableData }) => {
    const classes = useStyles();
    const windowHeight = useWindowHeight();

    const getColumns = (expanded: boolean) => [
        { emptyValue: "-", title: "Код параметра", field: "tag" },
        { emptyValue: "-", title: "Тип", field: "vr" },
        { emptyValue: "-", title: "Наименование", field: "title" },
        {
            emptyValue: "-",
            title: "Значение",
            field: "Value",
            render: (rowData: any) => {
                if (Array.isArray(rowData.Value) && rowData.Value.length > 0 && typeof rowData.Value[0] === 'object') {
                    const valueData = rowData.Value.flatMap((obj: any) =>
                        Object.entries(obj).map(([tag, value]) => ({ tag, ...(value as object) })));

                    const [expandedTable, setExpandedTable] = useState<boolean>(false);

                    const toggleAccordion = () => {
                        setExpandedTable((prevState) => !prevState);
                    };

                    return expanded ? (
                        <MaterialTable
                            style={{ boxShadow: "none" }}
                            isLoading={isLoading}
                            options={{
                                search: false,
                                toolbar: false,
                                grouping: false,
                                showTitle: false,
                                emptyRowsWhenPaging: false,
                                paging: false,
                                maxBodyHeight: windowHeight - 694,
                                headerStyle: {
                                    position: "sticky",
                                    top: 0
                                }
                            }}
                            columns={getColumns(false)}
                            data={valueData}
                        />
                    ) : (
                        <div>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={toggleAccordion}
                            >
                                <Typography color="primary">{expandedTable ? `Скрыть вложенные данные` : `Показать вложенные данные`}</Typography>
                            </Button>
                            {expandedTable && (
                                <div>
                                    <MaterialTable
                                        style={{ boxShadow: "none" }}
                                        isLoading={isLoading}
                                        options={{
                                            search: false,
                                            toolbar: false,
                                            grouping: false,
                                            showTitle: false,
                                            emptyRowsWhenPaging: false,
                                            paging: false,
                                            maxBodyHeight: windowHeight - 694,
                                            headerStyle: {
                                                position: "sticky",
                                                top: 0
                                            }
                                        }}
                                        columns={getColumns(false)}
                                        data={valueData}
                                    />
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return rowData.Value;
                }
            }
        }
    ];

    return (
        <Dialog
            open={open}
            maxWidth="xl"
            fullWidth
        >
            <Box className={classes.fileMetaWrapper} display="flex">
                <Typography align="left" color="textPrimary">
                    Анализ DCM файла
                </Typography>
                <Box className={classes.fileDataWrapper}>
                    <Typography className={classes.fileName}>
                        {file.name}
                    </Typography>
                    <Typography className={classes.fileSize} color="textSecondary">
                        {getFileSizeString(file.size)}
                    </Typography>
                </Box>
            </Box>
            <DialogContent className={classes.container}>
                <MaterialTable
                    style={{ boxShadow: "none" }}
                    isLoading={isLoading}
                    options={{
                        search: false,
                        toolbar: false,
                        grouping: false,
                        showTitle: false,
                        emptyRowsWhenPaging: false,
                        paging: false,
                        maxBodyHeight: windowHeight - 294,
                        minBodyHeight: 280,
                        headerStyle: {
                            position: "sticky",
                            top: 0
                        }
                    }}
                    title={<Typography variant="h4">Анализ DCM файла</Typography>}
                    columns={getColumns(true)}
                    data={tableData}
                />
            </DialogContent>
            <Box className={classes.buttonsWrapper} display="flex" justifyContent="end">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                >
                    Отмена
                </Button>
            </Box>
        </Dialog>
    );
};