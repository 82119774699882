import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DictFactoryIndexResponseType } from "models/dictionaries/dict-factory-index/types";

interface IDictFactoryIndexDialogProps {
  factoryIndexData: DictFactoryIndexResponseType | null
  onClose: () => void,
  onDelete: (worker: DictFactoryIndexResponseType) => void
}

export const FactoryIndexDeletingDialog: React.FC<IDictFactoryIndexDialogProps> = ({
    factoryIndexData, onClose, onDelete
}) => {
    const [visible, setVisible] = useState<boolean>(false);

    const handleClose = () => {
        setVisible(false);
        onClose();
    };

    useEffect(() => {
        if (factoryIndexData) {
            setVisible(true);
        }
    }, [factoryIndexData]);

    const footerContent = (
        <div className="flex w-full justify-content-end">
            <Button
                severity="danger"
                text
                label="Удалить"
                className="mr-3"
                icon="pi pi-trash"
                size="small"
                onClick={() => onDelete(factoryIndexData!)}
            />
            <Button
                label="Оставить"
                onClick={handleClose}
                size="small"
            />
        </div>
    );

    return (
        <Dialog
            header="Удаление заводского номера элемента"
            visible={visible}
            style={{ width: '25vw' }}
            onHide={handleClose}
            footer={footerContent}
        >
            {(factoryIndexData) && (
                <div className="text-sm font-normal mt-1">
                    Вы уверены, что хотите удалить заводской номер элемента: {factoryIndexData.value} ?
                </div>
            )}
        </Dialog>
    );
};