import React, {ReactElement, useEffect, useState} from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {IconButton, makeStyles, Menu, MenuItem, Theme} from '@material-ui/core';

import {ConfirmDialog} from '../.';

interface ITableActionsProps {
  onEdit: (e: any) => void;
  onDelete?: (e: any) => void;
  showEdit?: boolean
  showDelete?: boolean
  editCaption?: string;
  disabled?: boolean;
}

const useStyles = makeStyles(({typography}: Theme) => ({
  button: {
    padding: 0,
    width: "32px",
    height: "32px",
  },
  menuItem: {
    fontSize: typography.fontSize
  }
}));

export const TableActions = ({
  onEdit,
  onDelete,
  showEdit = true,
  showDelete = true,
  editCaption = "Редактировать",
  disabled = false
}: ITableActionsProps): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [onShowEdit, setOnShowEdit] = useState(true);
  const [onShowDelete, setOnShowDelete] = useState(true);
  const classes = useStyles();

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleEditItemClick = (e: any) => {
    onEdit && onEdit(e);
    setAnchorEl(null);
  };

  const handleDeleteConfirm = (e: any) => {
    onDelete && onDelete(e);
    setAnchorEl(null);
  };

  useEffect(() => {
    setOnShowEdit(Boolean(showEdit && onEdit));
  }, [showEdit]);

  useEffect(() => {
    setOnShowDelete(Boolean(showDelete && onDelete));
  }, [showDelete]);

  return (
    <>
      <IconButton
        className={classes.button}
        aria-label="more-icon"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled || (!onShowEdit && !onShowDelete)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(onShowEdit && onEdit) &&
            <MenuItem
                aria-label="edit-menu-item"
                className={classes.menuItem}
                onClick={handleEditItemClick}
            >
              {editCaption}
            </MenuItem>
        }
        {(onShowDelete && onDelete) &&
            <MenuItem
              aria-label="delete-menu-item"
              className={classes.menuItem}
            >
              <ConfirmDialog
                  message="Вы действительно хотите удалить запись?"
                  onConfirm={handleDeleteConfirm}
                  onCancel={handleClose}
              >
                Удалить
              </ConfirmDialog>
            </MenuItem>
        }
      </Menu>
    </>
  );
}

export default TableActions;
