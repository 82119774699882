import {useEffect, useState} from "react";

const getHeight = () => window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

export function useWindowHeight(delay = 100): number {
    const [height, setHeight] = useState<number>(() => getHeight());

    useEffect(() => {
        let timeoutId: any = null;
        const resizeListener = () => {

            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setHeight(getHeight()), delay);
        };
        window.addEventListener("resize", resizeListener);
        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    return height;
}
