import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import {
    createJob,
    deleteJob,
    getJobsList,
    getJobById,
    updateJob,
} from './actions';
import { JobResponseType } from './types';
import { IPagingSearch } from '../../../interfaces';

const { name } = options;

type JobState = {
    isLoading: boolean;
    jobList: JobResponseType[];
    jobById: JobResponseType | null;
    error: string;
};

const initialState: JobState = {
    isLoading: false,
    jobList: [],
    jobById: null,
    error: '',
};

export const jobSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearJobList(state) {
            state.jobList = [];
        },
        clearJobbyId(state) {
            state.jobById = null;
        },
    },
    extraReducers: {
        [getJobsList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getJobsList.fulfilled.type]: (
            state,
            { payload }: PayloadAction<IPagingSearch<JobResponseType>>
        ) => {
            state.isLoading = false;
            state.jobList = payload.results;
            state.error = '';
        },
        [getJobsList.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [getJobById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getJobById.fulfilled.type]: (
            state,
            { payload }: PayloadAction<JobResponseType>
        ) => {
            state.isLoading = false;
            state.jobById = payload;
            state.error = '';
        },
        [getJobById.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [createJob.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createJob.fulfilled.type]: (
            state,
            { payload }: PayloadAction<JobResponseType>
        ) => {
            state.isLoading = false;
            state.error = '';
        },
        [createJob.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [deleteJob.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteJob.fulfilled.type]: (
            state,
            { payload }: PayloadAction<any>
        ) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteJob.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [updateJob.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateJob.fulfilled.type]: (
            state,
            { payload }: PayloadAction<any>
        ) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateJob.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export default jobSlice.reducer;
