import request from 'utils/http/request';
import { DictWeldQaResponsibleBaseType, DictWeldQaResponsibleResponseType } from './types';
import { IPagingSearch } from 'interfaces';
import { getUrl } from '../../utils';
import options from './options';

const {apiUrl} = options
const url = getUrl(apiUrl);

export const getDictWeldQaResponsibleListApi = async (): Promise<IPagingSearch<DictWeldQaResponsibleResponseType>> => {
    return request.get(url(''));
}

export const getDictWeldQaResponsibleByIdApi = async (dictWeldQaResponsible_id:string): Promise<DictWeldQaResponsibleResponseType> => {
    return request.get(url(dictWeldQaResponsible_id));
};

export const createDictWeldQaResponsibleApi = async (body: DictWeldQaResponsibleBaseType): Promise<DictWeldQaResponsibleResponseType> => {
    return request.post(url(''), body);
}

export const updateDictWeldQaResponsibleApi = async (uuid: string, body: DictWeldQaResponsibleBaseType): Promise<DictWeldQaResponsibleResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteDictWeldQaResponsibleApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}
