import request from 'utils/http/request';
import {
    RadiographyBaseType,
    RadiographyCreateBody,
    RadiographyDownloadBody,
    RadiographyResponseType,
} from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getRadiographyListByJobIdApi = async (
    weldbook_id: string
): Promise<RadiographyResponseType[]> => {
    return request.get(url(`?job_item=${weldbook_id}`));
};

export const getRadiographyByIdApi = async (
    item_uuid: string
): Promise<RadiographyResponseType> => {
    return request.get(url(`${item_uuid}/`));
};

export const createRadiographyApi = async (
    body: any,
    signal: AbortSignal
): Promise<RadiographyResponseType> => {
    const { file, uploadProgress } = body;

    // добавляем возможность следить за прогрессом и отменить запрос
    const config = {
        signal,
        onUploadProgress: (progressEvent: any) =>
            uploadProgress(
                ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)
            ),
    };

    return request.post(url(''), file, config);
};

export const updateRadiographyApi = async (
    body: RadiographyCreateBody,
    radiography_id: string,
    signal: AbortSignal
): Promise<RadiographyResponseType> => {
    const { file, name, weldbook_item, uploadProgress } = body;

    const formData = new FormData();

    formData.append('image_dcm', file);
    formData.append('name', name);
    formData.append('weldbook_item', weldbook_item);

    // добавляем возможность следить за прогрессом и отменить запрос
    const config = {
        signal,
        onUploadProgress: (progressEvent: any) =>
            uploadProgress(progressEvent.loaded),
    };

    return request.patch(url(`${radiography_id}/`), formData, config);
};

export const downloadDcomFileApi = async (
    body: any,
    signal: AbortSignal
): Promise<any> => {
    const { item_uuid, uploadProgress } = body;
    return request.get(url(`${item_uuid}/download_original_dcm/`), {
        signal,
        onDownloadProgress: (progressEvent: any) => {
            uploadProgress(
                ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)
            );
        },
        responseType: 'blob',
    });
};

export const deleteRadiographyApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
};
