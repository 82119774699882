import React, { ReactElement } from 'react';
import {
    Box,
    Card,
    Divider,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { ProgressBar } from 'primereact/progressbar';
import { SectorResponseType } from 'models/objects/sector/types';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    objectCard: {
        padding: spacing(2, 3),
        height: `calc(100% - ${spacing(4)}px)`,
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 64,
        marginBottom: spacing(7),
    },
    listItem: {
        margin: spacing(1.5, 2),
    },
}));

interface ISectorCardPropsProps {
    selectedSector: SectorResponseType | null;
}

export const SectorCard = ({ selectedSector }: ISectorCardPropsProps) => {
    const classes = useStyles();

    return (
        <div className={classes.objectCard}>
            <div>
                <Divider className="mr-4 mb-1 mt-1 w-12" />
                <p className="text-base font-bold mb-3">
                    Участок: {selectedSector?.begin! / 1000} -{' '}
                    {selectedSector?.end! / 1000 || ''} км
                </p>

                <div className="mb-3">
                    <p className="text-sm font-normal text-content-secondary">
                        Проектная протяженность участка, км
                    </p>
                    <p className="text-base font-normal text-content-primary">
                        {selectedSector?.project_length
                            ? (selectedSector.project_length / 1000).toFixed(3)
                            : 0}
                    </p>
                </div>

                <div className="mb-3">
                    <p className="text-sm font-normal text-content-secondary">
                        Текущая протяженность, км
                    </p>
                    <p className="text-base font-normal text-content-primary mb-1">
                        {selectedSector?.real_length
                            ? (selectedSector.real_length / 1000).toFixed(3)
                            : 0}
                    </p>
                    <ProgressBar
                        value={
                            selectedSector?.real_length &&
                            selectedSector?.project_length
                                ? (
                                      (+selectedSector.real_length /
                                          +selectedSector.project_length) *
                                      100
                                  ).toFixed(2)
                                : 0
                        }
                    />
                </div>

                <div className="mb-3">
                    <p className="text-sm font-normal text-content-secondary">
                        Проектное количество стыков, шт
                    </p>
                    <p className="text-base font-normal text-content-primary">
                        {selectedSector?.project_join_count || 0}
                    </p>
                </div>

                <div className="mb-3">
                    <p className="text-sm font-normal text-content-secondary">
                        Текущее количество стыков, шт
                    </p>
                    <p className="text-base font-normal text-content-primary mb-1">
                        {selectedSector?.join_count
                            ? +selectedSector.join_count
                            : 0}
                    </p>
                    <ProgressBar
                        value={
                            selectedSector?.join_count &&
                            selectedSector.project_join_count
                                ? (
                                      (+selectedSector.join_count /
                                          +selectedSector.project_join_count) *
                                      100
                                  ).toFixed(2)
                                : 0
                        }
                    />
                </div>
            </div>
        </div>
    );
};
