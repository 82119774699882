import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getWeldbookCumulative } from './actions';
import options from './options';
import { WeldbookCumulativeType } from './types';
import { IPagingSearch } from 'interfaces';

const { name } = options;

type WeldbookCumulativeState = {
    isLoading: boolean;
    error: string;
    weldbookCumulativeList: WeldbookCumulativeType[];
};

const initialState: WeldbookCumulativeState = {
    isLoading: false,
    error: '',
    weldbookCumulativeList: [],
};

export const weldBookCumulativeSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearWeldbookCumulative(state) {
            state.weldbookCumulativeList = [];
        },
    },
    extraReducers: {
        [getWeldbookCumulative.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getWeldbookCumulative.fulfilled.type]: (
            state,
            { payload }: PayloadAction<IPagingSearch<WeldbookCumulativeType>>
        ) => {
            // const sortedWeldbooks = payload.results.sort((a, b) => {
            //     if (a.number === null) {
            //         return 1;
            //     } else if (b.number === null) {
            //         return -1;
            //     } else {
            //         return a.number! - b.number!;
            //     }
            // });
            const enumeratedWeldbookList = payload.results.map((w, index) => {
                w.sort_number = index + 1;
                return w;
            });

            state.isLoading = false;
            state.error = '';
            state.weldbookCumulativeList = enumeratedWeldbookList;
        },
        [getWeldbookCumulative.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default weldBookCumulativeSlice.reducer;
