import React, {ReactElement, useCallback} from 'react';
import {Box, createStyles, LinearProgress, makeStyles, Theme, Typography, withStyles} from '@material-ui/core';

interface IProgressBarProps {
    currentValue: number
    maxValue: number
    label: string
    units: string
}

const useStyles = makeStyles(({spacing}: Theme) => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        margin: spacing(0, 1),
        marginBottom: spacing(1)
    },
    barLabel: {
        margin: spacing(2),
        marginBottom: 0
    },
    barWrapper: {
        flex: 1
    },
    percentValue:{
        width: 46
    }
}));

const BorderLinearProgress = withStyles(({palette}: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: palette.grey[200],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: palette.primary.main
        },
    }),
)(LinearProgress);


export const ProgressBar = ({currentValue, maxValue, label, units}: IProgressBarProps): ReactElement => {
    const classes = useStyles();

    const percentValue = useCallback(() => {
        if ((currentValue !== undefined || currentValue !== null) && maxValue) {
            let value = Math.round((currentValue / maxValue) * 100);
            if (value > 100) {
                value = 100;
            }
            return value;
        }
        return 0;
    }, [currentValue, maxValue])

    const barLabelString = useCallback(() => {
        return `${label} ${currentValue} ${units}`;
    }, [currentValue, label, units])

    return (
        <>
            <Typography variant="subtitle2" className={classes.barLabel}>
                {barLabelString()}
            </Typography>
            <Box className={classes.wrapper}>
                <Box className={classes.barWrapper}>
                    <BorderLinearProgress variant="determinate" value={percentValue()} />
                </Box>
                <Box className={classes.percentValue}>
                    <Typography variant="subtitle2" color="textSecondary" align="right">
                        {percentValue()}%
                    </Typography>
                </Box>
            </Box>
        </>
    );
};
