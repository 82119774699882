import React, {ReactElement} from 'react';
import {makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme} from '@material-ui/core';
import { TableActions } from 'components';

export type WeldTableColumnType = {
    label: string
    field: string
    width?: number
}

interface IDataTableProps {
    columns: WeldTableColumnType[]
    tableData: any[]
    handleRowClick: (uuid: string) => void
    onEdit: (uuid: string) => void
    onDelete: (uuid: string) => void
}

const useStyles = makeStyles(({palette}: Theme) => ({
    row: {
        "&:hover": {
            cursor: "pointer",
            outline: `2px solid ${palette.primary.main}`,
            outlineOffset: -2,
        }
    }
}));

export const DataTable = ({ columns, tableData, handleRowClick, onEdit, onDelete}: IDataTableProps): ReactElement => {
    const classes = useStyles();

    const handleEdit = (uuid: string) => () => {
        if (onEdit && uuid) {
            onEdit(uuid);
        }
    }
    const handleDelete = (uuid: string) => () => {
        if (onDelete && uuid) {
            onDelete(uuid);
        }
    }

    const onRowClick = (uuid: string) => () => {
        if (handleRowClick && uuid) {
            handleRowClick(uuid);
        }
    }

    return (
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    {columns.map(({label, field, width}) => (
                        <TableCell
                            key={String(field)}
                            style={{ minWidth: width || 'auto' }}
                        >{label}</TableCell>
                    ))}
                    <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.map((rowData, index) => (
                    <TableRow
                        key={rowData.item_uuid || index}
                        className={classes.row}
                        onClick={onRowClick(rowData.item_uuid)}
                    >
                        {columns.map(({field}) => (
                            <TableCell key={String(field)}>
                                {rowData[field] ? rowData[field] : rowData[field] === 0 ? 0 : '-'}
                            </TableCell>
                        ))}
                        <TableCell onClick={(e) => e.stopPropagation()}>
                            <TableActions
                                onEdit={handleEdit(rowData.item_uuid)}
                                onDelete={handleDelete(rowData.item_uuid)}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
