import React, { useRef, useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    DictStandardDocumentBaseType,
    DictStandardDocumentResponseType,
} from 'models/dictionaries/dict-standard-document/types';
import { selectDictStandardDocumentState } from 'models/dictionaries/dict-standard-document/selectors';
import {
    createDictStandardDocument,
    deleteDictStandardDocument,
    getDictStandardDocumentList,
    updateDictStandardDocument,
} from 'models/dictionaries/dict-standard-document/actions';
import { StandardDocumentDeletingDialog } from './standard-document-deleting-dialog';
import { StandardDocumentDialog } from './standard-document-dialog';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    tableContainer: {
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        height: 'calc(100vh - 200px)',
        '& .p-paginator': {
            position: 'fixed',
            bottom: '0,75rem',
            // right: '40%',
            left: '45%',
            width: 'max-content',
        },
        '& .p-datatable.p-component': {
            height: 'calc(100vh - 200px)',
        },
        '& .p-datatable-wrapper': {
            height: 'calc(100vh - 260px)',
        },
    },
}));

export const DictStandardDocumentTable = () => {
    const [selectedItem, setSelectedItem] =
        useState<DictStandardDocumentResponseType | null>(null);
    const [isStandardDocumentDialogOpen, setIsStandardDocumentDialogOpen] =
        useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const { dictStandardDocumentList, isLoading } = useAppSelector(
        selectDictStandardDocumentState
    );
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);
    const classes = useStyles();

    const columns = [
        {
            title: 'Наименование',
            field: 'value',
            frozen: false,
            sortable: true,
        },
    ];

    const actionTemplate = (row: any) => (
        <div className="flex">
            <Button
                text
                severity="secondary"
                icon="pi pi-pencil"
                className="mr-4 p-0"
                onClick={(event) => handleEditClick(row)}
            />
            <Button
                text
                severity="secondary"
                icon="pi pi-trash"
                className="p-0"
                onClick={(event) => handleDeleteClick(row)}
            />
        </div>
    );

    const rowTemplate = (data: any) => {
        if (!data) {
            return <div className="flex">-</div>;
        } else {
            return <div className="flex">{data}</div>;
        }
    };

    const handleEditClick = (item: DictStandardDocumentResponseType) => {
        setSelectedItem(item);
        setIsStandardDocumentDialogOpen(true);
    };
    const handleDeleteClick = (item: DictStandardDocumentResponseType) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    };

    const handleUpdate = (
        item_uuid: string,
        item: DictStandardDocumentResponseType
    ) => {
        dispatch(updateDictStandardDocument({ uuid: item_uuid, body: item }))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'ГОСТ и ТУ обновлен',
                        life: 3000,
                    },
                ]);
                dispatch(getDictStandardDocumentList()).unwrap();
                setSelectedItem(null);
                setIsStandardDocumentDialogOpen(false);
            });
    };
    const handleCreate = (item: DictStandardDocumentBaseType) => {
        dispatch(createDictStandardDocument(item))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'ГОСТ и ТУ создан',
                        life: 3000,
                    },
                ]);
                dispatch(getDictStandardDocumentList()).unwrap();
                setIsStandardDocumentDialogOpen(false);
            });
    };

    const handleDelete = (item: DictStandardDocumentResponseType) => {
        setDeleteDialogOpen(false);
        dispatch(deleteDictStandardDocument(item.item_uuid))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'ГОСТ и ТУ удалён из базы',
                        life: 3000,
                    },
                ]);
                dispatch(getDictStandardDocumentList()).unwrap();
                setSelectedItem(null);
            });
    };

    const handleClose = () => {
        setDeleteDialogOpen(false);
        setIsStandardDocumentDialogOpen(false);
        setSelectedItem(null);
    };

    useEffect(() => {
        dispatch(getDictStandardDocumentList()).unwrap();
    }, []);

    return (
        <div className={classes.tableContainer}>
            <Toast ref={toast} />
            <div className="flex justify-content-between align-items-center">
                <p className="block text-2xl font-bold">ГОСТ и ТУ</p>
                <div>
                    <Button
                        color="primary"
                        onClick={() => setIsStandardDocumentDialogOpen(true)}
                    >
                        Добавить ГОСТ и ТУ
                    </Button>
                </div>
            </div>

            <DataTable
                value={dictStandardDocumentList}
                scrollable
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                selectionMode="single"
                rowHover
                emptyMessage="ГОСТ и ТУ пока не добавлены"
                className="mt-2"
            >
                {columns.map((col, i) => (
                    <Column
                        key={col.field}
                        field={col.field}
                        header={col.title}
                        style={{ width: '90%' }}
                        frozen={!!col.frozen}
                        sortable={col.sortable}
                        body={(row) => rowTemplate(row[`${col.field}`])}
                    />
                ))}
                <Column header="" body={actionTemplate} />
            </DataTable>
            {Boolean(isStandardDocumentDialogOpen) && (
                <StandardDocumentDialog
                    formData={selectedItem}
                    onClose={handleClose}
                    onUpdate={handleUpdate}
                    onCreate={handleCreate}
                />
            )}
            {Boolean(deleteDialogOpen) && (
                <StandardDocumentDeletingDialog
                    standardDocumentData={selectedItem}
                    onDelete={handleDelete}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};
