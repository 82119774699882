import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { DictRadiographyEquipResponseType } from './types';
import {
    getDictRadiographyEquipList,
    getDictRadiographyEquipById,
    createDictRadiographyEquip,
    updateDictRadiographyEquip,
    deleteDictRadiographyEquip,
} from './actions';
import { IPagingSearch } from '../../../interfaces';

const { name } = options;

type DictRadiographyEquipState = {
    dictRadiographyEquipList: DictRadiographyEquipResponseType[];
    isLoading: boolean;
    error: string;
};

const initialState: DictRadiographyEquipState = {
    dictRadiographyEquipList: [],
    isLoading: false,
    error: '',
};

export const dictRadiographyEquipSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearDictRadiographyEquipListList(state) {
            state.dictRadiographyEquipList = [];
        },
    },
    extraReducers: {
        [getDictRadiographyEquipList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictRadiographyEquipList.fulfilled.type]: (
            state,
            {
                payload,
            }: PayloadAction<IPagingSearch<DictRadiographyEquipResponseType>>
        ) => {
            state.isLoading = false;
            state.dictRadiographyEquipList = payload.results;
            state.error = '';
        },
        [getDictRadiographyEquipList.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.dictRadiographyEquipList = [];
            state.error = payload;
        },
        [getDictRadiographyEquipById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictRadiographyEquipById.fulfilled.type]: (
            state,
            { payload }: PayloadAction<DictRadiographyEquipResponseType>
        ) => {
            state.isLoading = false;
            state.error = '';
        },
        [getDictRadiographyEquipById.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createDictRadiographyEquip.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createDictRadiographyEquip.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [createDictRadiographyEquip.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateDictRadiographyEquip.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateDictRadiographyEquip.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateDictRadiographyEquip.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteDictRadiographyEquip.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteDictRadiographyEquip.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteDictRadiographyEquip.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default dictRadiographyEquipSlice.reducer;
