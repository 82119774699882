import request from 'utils/http/request';
import { TaskStatusType } from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getTaskStatusByRadiographyIdApi = async (radiography_id: string): Promise<TaskStatusType> => {
    return request.get(url(`?radiography_item=${radiography_id}`));
}
