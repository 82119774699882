import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DictWorkerResponseType } from "models/dictionaries/dict-worker/types";
import { WorkgroupTableDataType } from 'models/dictionaries/dict-workgroup/types';

interface IDictWorkerDialogProps {
  workgroupData: WorkgroupTableDataType | null
  onClose: () => void,
  onDelete: (worker: WorkgroupTableDataType) => void
}

export const WorkgroupDeletingDialog: React.FC<IDictWorkerDialogProps> = ({
    workgroupData, onClose, onDelete
}) => {
    const [visible, setVisible] = useState<boolean>(false);

    const handleClose = () => {
        setVisible(false);
        onClose();
    };

    useEffect(() => {
        if (workgroupData) {
            setVisible(true);
        }
    }, [workgroupData]);

    const footerContent = (
        <div className="flex w-full justify-content-end">
            <Button
                severity="danger"
                text
                label="Удалить"
                className="mr-3"
                icon="pi pi-trash"
                size="small"
                onClick={() => onDelete(workgroupData!)}
            />
            <Button
                label="Оставить"
                onClick={handleClose}
                size="small"
            />
        </div>
    );

    return (
        <Dialog
            header="Удаление строительной бригады"
            visible={visible}
            style={{ width: '25vw' }}
            onHide={handleClose}
            footer={footerContent}
        >
            {(workgroupData) && (
                <div className="text-sm font-normal mt-1">
                    Вы уверены, что хотите удалить строительную бригаду {workgroupData.name} ?
                </div>
            )}
        </Dialog>
    );
};