import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReferencesByWeldQualityIdApi, createReferenceApi, deleteReferenceApi } from "./api";
import options from './options';
import { ReferencesBaseType } from './types';

const { name } = options;

export const getReferencesByWeldQualityId = createAsyncThunk(
    `${name}/getReferences`,
    async (radiography_id: string, { rejectWithValue }) => {
        try {
            return await getReferencesByWeldQualityIdApi(radiography_id);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);

export const createReference = createAsyncThunk(
    `${name}/createReference`,
    async (body: ReferencesBaseType, thunkApi) => {
        try {
            return await createReferenceApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);


export const deleteReference = createAsyncThunk(
    `${name}/deleteReference`,
    async (reference: string, { rejectWithValue }) => {
        try {
            return await deleteReferenceApi(reference);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);