import React, { ReactElement, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import {
    DictTechnicalSheetBaseType,
    DictTechnicalSheetResponseType,
} from 'models/dictionaries/dict-technical-sheet/types';
import { diameters } from 'pages/gas-pipeline/constants';
import { useAppSelector } from 'store/hooks';
import { selectDictTechnicalSheetState } from 'models/dictionaries/dict-technical-sheet/selectors';

interface ITechnicalSheetDialogProps {
    formData: any;
    onClose: () => void;
    onCreate?: (item: DictTechnicalSheetResponseType) => void;
    onUpdate?: (
        item_uuid: string,
        item: DictTechnicalSheetResponseType
    ) => void;
}

const dictTechnicalSheetInitialValues: DictTechnicalSheetBaseType = {
    pressmark: '',
    factory_name: '',
    certification: '',
    d_pipe: null,
    s1_pipe: null,
    s2_pipe: null,
    regulatory_document: '',
};

export const TechnicalSheetDialog = ({
    formData,
    onClose,
    onCreate,
    onUpdate,
}: ITechnicalSheetDialogProps): ReactElement => {
    const [visible, setVisible] = useState<boolean>(false);
    const detectorTypes = ['Транскан', 'Цифракон'];
    const screeningSchemes = ['Панорамное', 'Фронтальное'];
    const { dictTechnicalSheetList, isLoading } = useAppSelector(
        selectDictTechnicalSheetState
    );

    const formik = useFormik({
        initialValues: formData || dictTechnicalSheetInitialValues,
        validateOnBlur: true,
        validate: (data) => {
            const errors: any = {};

            if (!data.pressmark || data.pressmark?.trim() === '') {
                errors.pressmark = 'Обязательное поле ввода';
            }
            if (data.pressmark.length > 255) {
                errors.pressmark =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            } else {
                const filtereredTechnicalSheetList = formData
                    ? dictTechnicalSheetList
                          .filter((d: any) => d.item_uuid !== data.item_uuid)
                          .map(
                              (w: DictTechnicalSheetResponseType) => w.pressmark
                          )
                    : dictTechnicalSheetList.map(
                          (w: DictTechnicalSheetResponseType) => w.pressmark
                      );
                if (
                    data.pressmark?.trim() &&
                    filtereredTechnicalSheetList.some(
                        (d: string) => d === data.pressmark.trim()
                    )
                ) {
                    errors.pressmark =
                        'Такой шифр уже закреплен за другой технологической картой';
                }
            }

            if (!data.factory_name || data.factory_name?.trim() === '') {
                errors.factory_name = 'Обязательное поле ввода';
            }
            if (data.factory_name.length > 255) {
                errors.factory_name =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            if (!data.certification || data.certification?.trim() === '') {
                errors.certification = 'Обязательное поле ввода';
            }
            if (data.certification.length > 255) {
                errors.certification =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }
            if (
                !data.regulatory_document ||
                data.regulatory_document?.trim() === ''
            ) {
                errors.regulatory_document = 'Обязательное поле ввода';
            }
            if (data.regulatory_document.length > 255) {
                errors.regulatory_document =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }
            if (!data.d_pipe) {
                errors.d_pipe = 'Обязательное поле ввода';
            }
            if (
                data.d_pipe !== null &&
                (data.d_pipe! > 9999 || data.d_pipe! < 1)
            ) {
                errors.d_pipe = 'Значение должно быть в диапазоне от 1 до 9999';
            }

            if (!data.s1_pipe) errors.s1_pipe = 'Обязательное поле ввода';
            if (
                data.s1_pipe !== null &&
                (data.s1_pipe! > 999.9 || data.s1_pipe! < 0.1)
            ) {
                errors.s1_pipe =
                    'Значение должно быть в диапазоне от 0.1 до 999.9';
            }

            if (!data.s2_pipe) errors.s2_pipe = 'Обязательное поле ввода';
            if (
                data.s2_pipe !== null &&
                (data.s2_pipe! > 999.9 || data.s2_pipe! < 0.1)
            ) {
                errors.s2_pipe =
                    'Значение должно быть в диапазоне от 0.1 до 999.9';
            }

            return errors;
        },
        onSubmit: (values: any) => {
            if (formData) {
                onUpdate && onUpdate(formData.item_uuid, values);
            } else {
                onCreate && onCreate(values);
            }
        },
    });

    const isFormFieldInvalid = (name: any) =>
        !!(
            formik.touched[name as keyof DictTechnicalSheetBaseType] &&
            formik.errors[name as keyof DictTechnicalSheetBaseType]
        );

    const isFormInvalid = () => {
        if (
            formik.values.certification &&
            !formik.errors.certification &&
            formik.values.pressmark &&
            !formik.errors.pressmark &&
            formik.values.factory_name &&
            !formik.errors.factory_name &&
            formik.values.d_pipe &&
            !formik.errors.d_pipe &&
            formik.values.s1_pipe &&
            !formik.errors.s1_pipe &&
            formik.values.s2_pipe &&
            !formik.errors.s2_pipe &&
            formik.values.regulatory_document &&
            !formik.errors.regulatory_document
        ) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        setVisible(true);
        if (!formData)
            formik.setFieldValue(
                'regulatory_document',
                'СТО Газпром 15-1.3-004-2023'
            );
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
            <Dialog
                header={`${
                    formData ? 'Редактирование' : 'Добавление'
                } технологической карты`}
                visible={visible}
                style={{ width: '40vw' }}
                onHide={onClose}
            >
                <div className="grid align-items-end">
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="pressmark">
                                Шифр <span className="required">*</span>
                            </label>
                            <InputText
                                id="pressmark"
                                name="pressmark"
                                value={formik.values.pressmark}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'pressmark',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('pressmark'),
                                })}
                                tooltip={formik.errors.pressmark as string}
                                tooltipOptions={{
                                    disabled: !isFormFieldInvalid('pressmark'),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="factory_name">
                                Наименование предприятия{' '}
                                <span className="required">*</span>
                            </label>
                            <InputText
                                id="factory_name"
                                name="factory_name"
                                value={formik.values.factory_name}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'factory_name',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('factory_name'),
                                })}
                                tooltip={formik.errors.factory_name as string}
                                tooltipOptions={{
                                    disabled:
                                        !isFormFieldInvalid('factory_name'),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="certification">
                                Свидетельство об аттестации №*{' '}
                                <span className="required">*</span>
                            </label>
                            <InputText
                                id="certification"
                                name="certification"
                                value={formik.values.certification}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'certification',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('certification'),
                                })}
                                tooltip={formik.errors.certification as string}
                                tooltipOptions={{
                                    disabled:
                                        !isFormFieldInvalid('certification'),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="regulatory_document">
                                Нормативный документ{' '}
                                <span className="required">*</span>
                            </label>
                            <InputText
                                id="regulatory_document"
                                name="regulatory_document"
                                value={formik.values.regulatory_document}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'regulatory_document',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid(
                                        'regulatory_document'
                                    ),
                                })}
                                tooltip={
                                    formik.errors.regulatory_document as string
                                }
                                tooltipOptions={{
                                    disabled: !isFormFieldInvalid(
                                        'regulatory_document'
                                    ),
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="d_pipe">
                                Номинальный диаметр трубы (D), мм{' '}
                                <span className="required">*</span>
                            </label>
                            <Dropdown
                                id="d_pipe"
                                onBlur={formik.handleBlur}
                                value={formik.values.d_pipe}
                                options={diameters}
                                placeholder="Выберите диаметр"
                                onChange={(e) =>
                                    formik.setFieldValue('d_pipe', e.value)
                                }
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('d_pipe'),
                                })}
                                tooltip={formik.errors.d_pipe as string}
                                tooltipOptions={{
                                    disabled:
                                        typeof formik.values.d_pipe ===
                                        'number',
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="s1_pipe">
                                Номинальная толщина стенки трубы (S 1), мм{' '}
                                <span className="required">*</span>
                            </label>
                            <InputNumber
                                id="s1_pipe"
                                name="s1_pipe"
                                maxFractionDigits={1}
                                minFractionDigits={1}
                                value={formik.values.s1_pipe}
                                onChange={(e) => {
                                    formik.setFieldValue('s1_pipe', e.value);
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('s1_pipe'),
                                })}
                                tooltip={formik.errors.s1_pipe as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.s1_pipe,
                                    position: 'bottom',
                                }}
                                useGrouping={false}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="s2_pipe">
                                Номинальная толщина стенки трубы (S 2), мм{' '}
                                <span className="required">*</span>
                            </label>
                            <InputNumber
                                id="s2_pipe"
                                name="s2_pipe"
                                maxFractionDigits={1}
                                minFractionDigits={1}
                                value={formik.values.s2_pipe}
                                onChange={(e) => {
                                    formik.setFieldValue('s2_pipe', e.value);
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid': isFormFieldInvalid('s2_pipe'),
                                })}
                                tooltip={formik.errors.s2_pipe as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.s2_pipe,
                                    position: 'bottom',
                                }}
                                useGrouping={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-content-end mt-3">
                    <Button
                        outlined
                        onClick={onClose}
                        label="Отмена"
                        className="mr-3"
                    />
                    {!formData && (
                        <Button
                            type="submit"
                            label="Добавить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                    {formData && (
                        <Button
                            type="submit"
                            label="Сохранить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                </div>
            </Dialog>
        </form>
    );
};
