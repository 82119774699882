import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDictWeldQaResponsibleListApi, getDictWeldQaResponsibleByIdApi, createDictWeldQaResponsibleApi, updateDictWeldQaResponsibleApi, deleteDictWeldQaResponsibleApi 
} from './api';
import options from './options';
import {DictWeldQaResponsibleBaseType} from "./types";

const {name} = options

export const getDictWeldQaResponsibleList = createAsyncThunk(
    `${name}/getDictWeldQaResponsibleList`,
    async (_, thunkApi) => {
        try {
            return await getDictWeldQaResponsibleListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getDictWeldQaResponsibleById = createAsyncThunk(
    `${name}/getDictWeldQaResponsibleById`,
    async (pipe_id:string, thunkApi) => {
        try {
            return await getDictWeldQaResponsibleByIdApi(pipe_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createDictWeldQaResponsible = createAsyncThunk(
    `${name}/createDictWeldQaResponsible`,
    async (body: DictWeldQaResponsibleBaseType, thunkApi) => {
        try {
            return await createDictWeldQaResponsibleApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateDictWeldQaResponsible = createAsyncThunk(
    `${name}/updateDictWeldQaResponsible`,
    async ({ uuid, body }: { uuid: string, body: DictWeldQaResponsibleBaseType }, thunkApi) => {
        try {
            return await updateDictWeldQaResponsibleApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteDictWeldQaResponsible = createAsyncThunk(
    `${name}/deleteDictWeldQaResponsible`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteDictWeldQaResponsibleApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
