import React, { useRef, useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectDictWeldQaResponsibleState } from 'models/dictionaries/dict-weld-qa-responsible/selectors';
import {
    DictWeldQaResponsibleBaseType,
    DictWeldQaResponsibleResponseType,
} from 'models/dictionaries/dict-weld-qa-responsible/types';
import {
    updateDictWeldQaResponsible,
    getDictWeldQaResponsibleList,
    createDictWeldQaResponsible,
    deleteDictWeldQaResponsible,
} from 'models/dictionaries/dict-weld-qa-responsible/actions';
import { WeldQaResponsibleDeletingDialog } from './weld-qa-responsible-deleting-dialog';
import { WeldQaResponsibleDialog } from './weld-qa-responsible-dialog';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    tableContainer: {
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        height: 'calc(100vh - 200px)',
        '& .p-paginator': {
            position: 'fixed',
            bottom: '0,75rem',
            // right: '40%',
            left: '45%',
            width: 'max-content',
        },
        '& .p-datatable.p-component': {
            height: 'calc(100vh - 200px)',
        },
        '& .p-datatable-wrapper': {
            height: 'calc(100vh - 260px)',
        },
    },
}));

export const DictWeldQaResponsibleTable = () => {
    const [selectedItem, setSelectedItem] =
        useState<DictWeldQaResponsibleResponseType | null>(null);
    const [isWeldQaResponsibleDialogOpen, setIsWeldQaResponsibleDialogOpen] =
        useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const { dictWeldQaResponsibleList, isLoading } = useAppSelector(
        selectDictWeldQaResponsibleState
    );
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);
    const classes = useStyles();

    const columns = [
        {
            title: 'Фамилия',
            field: 'second_name',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Имя',
            field: 'first_name',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Отчество',
            field: 'third_name',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Уровень квалификации',
            field: 'prof_level',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Удостоверение',
            field: 'documents',
            frozen: false,
            sortable: true,
        },
    ];

    const actionTemplate = (row: any) => (
        <div className="flex">
            <Button
                text
                severity="secondary"
                icon="pi pi-pencil"
                className="mr-4 p-0"
                onClick={(event) => handleEditClick(row)}
            />
            <Button
                text
                severity="secondary"
                icon="pi pi-trash"
                className="p-0"
                onClick={(event) => handleDeleteClick(row)}
            />
        </div>
    );

    const rowTemplate = (data: any) => {
        if (!data) {
            return <div className="flex">-</div>;
        } else {
            return <div className="flex">{data}</div>;
        }
    };

    const handleEditClick = (item: DictWeldQaResponsibleResponseType) => {
        setSelectedItem(item);
        setIsWeldQaResponsibleDialogOpen(true);
    };
    const handleDeleteClick = (item: DictWeldQaResponsibleResponseType) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    };

    const handleUpdate = (
        item_uuid: string,
        item: DictWeldQaResponsibleResponseType
    ) => {
        dispatch(updateDictWeldQaResponsible({ uuid: item_uuid, body: item }))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Данные об ответственном НК обновлены',
                        life: 3000,
                    },
                ]);
                dispatch(getDictWeldQaResponsibleList()).unwrap();
                setSelectedItem(null);
                setIsWeldQaResponsibleDialogOpen(false);
            });
    };
    const handleCreate = (item: DictWeldQaResponsibleBaseType) => {
        dispatch(createDictWeldQaResponsible(item))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Ответственный НК добавлен',
                        life: 3000,
                    },
                ]);
                dispatch(getDictWeldQaResponsibleList()).unwrap();
                setIsWeldQaResponsibleDialogOpen(false);
            });
    };

    const handleDelete = (item: DictWeldQaResponsibleResponseType) => {
        setDeleteDialogOpen(false);
        dispatch(deleteDictWeldQaResponsible(item.item_uuid))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Ответственный НК удален из базы',
                        life: 3000,
                    },
                ]);
                dispatch(getDictWeldQaResponsibleList()).unwrap();
                setSelectedItem(null);
            });
    };

    const handleClose = () => {
        setDeleteDialogOpen(false);
        setIsWeldQaResponsibleDialogOpen(false);
        setSelectedItem(null);
    };

    useEffect(() => {
        dispatch(getDictWeldQaResponsibleList()).unwrap();
    }, []);

    return (
        <div className={classes.tableContainer}>
            <Toast ref={toast} />
            <div className="flex justify-content-between align-items-center">
                <p className="block text-2xl font-bold">Ответственные НК</p>
                <div>
                    <Button
                        color="primary"
                        onClick={() => setIsWeldQaResponsibleDialogOpen(true)}
                    >
                        Добавить ответственного НК
                    </Button>
                </div>
            </div>

            <DataTable
                value={dictWeldQaResponsibleList}
                scrollable
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                selectionMode="single"
                rowHover
                emptyMessage="Ответственные НК пока не добавлены"
                className="mt-2"
            >
                {columns.map((col, i) => (
                    <Column
                        key={col.field}
                        field={col.field}
                        header={col.title}
                        style={{ width: '18%' }}
                        frozen={!!col.frozen}
                        sortable={col.sortable}
                        body={(row) => rowTemplate(row[`${col.field}`])}
                    />
                ))}
                <Column header="" body={actionTemplate} />
            </DataTable>
            {Boolean(isWeldQaResponsibleDialogOpen) && (
                <WeldQaResponsibleDialog
                    formData={selectedItem}
                    onClose={handleClose}
                    onUpdate={handleUpdate}
                    onCreate={handleCreate}
                />
            )}
            {Boolean(deleteDialogOpen) && (
                <WeldQaResponsibleDeletingDialog
                    weldQaResponsibleData={selectedItem}
                    onDelete={handleDelete}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};
