import {DictWorkerFormType, DictWorkerResponseType} from "./types";

export const convertWorker2Client = (worker: DictWorkerResponseType): DictWorkerFormType => {
    const { first_name, second_name } = worker;
    // добавление поля с именем и кодом
    return { ...worker, caption: `${first_name} - ${second_name}` };
};

export const convertWorkerList2Client = (wList: DictWorkerResponseType[]): DictWorkerFormType[] => {
    return wList.map(convertWorker2Client);
}
