import React, {ReactElement} from 'react';
import { ThemeProvider } from '@material-ui/core/styles';

import AuthProvider from 'containers/authorization';
import ApiProvider from './http/api-provider';
import {SnackbarProvider} from "../containers/snackbar";
import { getAppTheme } from 'theme';

export const SystemProvider: React.FC<{ children: ReactElement }> = ({ children }) => (
    <ThemeProvider theme={getAppTheme()}>
        <SnackbarProvider>
            <ApiProvider>
                <AuthProvider>
                    {children}
                </AuthProvider>
            </ApiProvider>
        </SnackbarProvider>
    </ThemeProvider>
);
