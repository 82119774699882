import React, { ReactElement, useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useAppSelector } from 'store/hooks';
import {
    DictWeldQaResponsibleBaseType,
    DictWeldQaResponsibleResponseType,
} from 'models/dictionaries/dict-weld-qa-responsible/types';
import { selectDictWeldQaResponsibleState } from 'models/dictionaries/dict-weld-qa-responsible/selectors';
import { Dropdown } from 'primereact/dropdown';

interface IWeldQaResponsibleDialogProps {
    formData: any;
    onClose: () => void;
    onCreate?: (item: DictWeldQaResponsibleBaseType) => void;
    onUpdate?: (
        item_uuid: string,
        item: DictWeldQaResponsibleResponseType
    ) => void;
}

const dictWeldQaResponsibleInitialValues: DictWeldQaResponsibleBaseType = {
    first_name: '',
    second_name: '',
    third_name: '',
    documents: '',
    prof_level: '',
};

const profLevels = ['1 уровень', '2 уровень', '3 уровень'];

export const WeldQaResponsibleDialog = ({
    formData,
    onClose,
    onCreate,
    onUpdate,
}: IWeldQaResponsibleDialogProps): ReactElement => {
    const [visible, setVisible] = useState<boolean>(true);
    const { dictWeldQaResponsibleList, isLoading } = useAppSelector(
        selectDictWeldQaResponsibleState
    );

    const formik = useFormik({
        initialValues: formData || dictWeldQaResponsibleInitialValues,
        validateOnBlur: true,
        validate: (data) => {
            const errors: any = {};

            if (!data.first_name || data.first_name?.trim() === '') {
                errors.first_name = 'Обязательное поле ввода';
            }
            if (data.first_name.length > 255) {
                errors.first_name =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            if (!data.second_name || data.second_name?.trim() === '') {
                errors.second_name = 'Обязательное поле ввода';
            }
            if (data.second_name.length > 255) {
                errors.second_name =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            if (!data.third_name || data.third_name?.trim() === '') {
                errors.third_name = 'Обязательное поле ввода';
            }
            if (data.third_name.length > 255) {
                errors.third_name =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            if (!data.prof_level) {
                errors.prof_level = 'Обязательное поле ввода';
            }

            if (!data.documents || data.documents?.trim() === '') {
                errors.documents = 'Обязательное поле ввода';
            }
            if (data.documents.length > 255) {
                errors.documents =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            const filtereredDocumentsList = formData
                ? dictWeldQaResponsibleList
                      .filter((d: any) => d.item_uuid !== data.item_uuid)
                      .map(
                          (w: DictWeldQaResponsibleResponseType) => w.documents
                      )
                : dictWeldQaResponsibleList.map(
                      (w: DictWeldQaResponsibleResponseType) => w.documents
                  );

            if (
                data.documents?.trim() &&
                filtereredDocumentsList.some(
                    (d: string) => d === data.documents.trim()
                )
            ) {
                errors.documents =
                    'Такой документ уже закреплен за другим ответственным НК';
            }
            return errors;
        },
        onSubmit: (values: any) => {
            if (formData) {
                onUpdate && onUpdate(formData.item_uuid, values);
            } else {
                onCreate && onCreate(values);
            }
        },
    });

    const isFormInvalid = () => {
        if (
            formik.values.first_name.trim() &&
            !formik.errors.first_name &&
            formik.values.second_name.trim() &&
            !formik.errors.second_name &&
            formik.values.third_name.trim() &&
            !formik.errors.third_name &&
            formik.values.documents.trim() &&
            !formik.errors.documents &&
            formik.values.prof_level.trim() &&
            !formik.errors.prof_level
        ) {
            return false;
        } else {
            return true;
        }
    };

    const isFormFieldInvalid = (name: any) =>
        !!(
            formik.touched[name as keyof DictWeldQaResponsibleBaseType] &&
            formik.errors[name as keyof DictWeldQaResponsibleBaseType]
        );

    return (
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
            <Dialog
                header={`${
                    formData ? 'Редактирование' : 'Добавление'
                } ответственного НК`}
                visible={visible}
                style={{ width: '40vw' }}
                onHide={onClose}
            >
                <div className="grid align-items-end">
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="second_name">
                                Фамилия <span className="required">*</span>
                            </label>
                            <InputText
                                id="second_name"
                                name="second_name"
                                value={formik.values.second_name}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'second_name',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('second_name'),
                                })}
                                tooltip={formik.errors.second_name as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.second_name,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="first_name">
                                Имя <span className="required">*</span>
                            </label>
                            <InputText
                                id="first_name"
                                name="first_name"
                                value={formik.values.first_name}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'first_name',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('first_name'),
                                })}
                                tooltip={formik.errors.first_name as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.first_name,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="third_name">
                                Отчество <span className="required">*</span>
                            </label>
                            <InputText
                                id="third_name"
                                name="third_name"
                                value={formik.values.third_name}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'third_name',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('third_name'),
                                })}
                                tooltip={formik.errors.third_name as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.third_name,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="prof_level">
                                Уровень квалификации{' '}
                                <span className="required">*</span>
                            </label>
                            <Dropdown
                                id="prof_level"
                                name="prof_level"
                                value={formik.values.prof_level}
                                options={profLevels}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'prof_level',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('prof_level'),
                                })}
                                tooltip={formik.errors.prof_level as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.prof_level,
                                    position: 'bottom',
                                }}
                                appendTo="self"
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="documents">
                                Удостоверение{' '}
                                <span className="required">*</span>
                            </label>
                            <InputText
                                id="documents"
                                name="documents"
                                value={formik.values.documents}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'documents',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                className={classNames({
                                    'p-invalid':
                                        isFormFieldInvalid('documents'),
                                })}
                                tooltip={formik.errors.documents as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.documents,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-content-end mt-3">
                    <Button
                        outlined
                        onClick={onClose}
                        label="Отмена"
                        className="mr-3"
                    />
                    {!formData && (
                        <Button
                            type="submit"
                            label="Добавить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                    {formData && (
                        <Button
                            type="submit"
                            label="Сохранить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                </div>
            </Dialog>
        </form>
    );
};
