import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { ResponseChainType, SendChainType } from './types';
import {
    getAvailableTasks, getChainStatus, sendChainRadiography, stopChain
} from "./actions";
import { IPagingSearch } from "../../interfaces";

const {name} = options

type TasksTypeState = {
    chainTask: any
    taskList: string[]
    isLoading: boolean
    error: string
};

const initialState: TasksTypeState = {
    chainTask: '',
    taskList: [],
    isLoading: false,
    error: ''
};

export const TasksTypeSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearChainTask(state) {
            state.chainTask = '';
            state.taskList = []
        }
    },
    extraReducers: {
        [sendChainRadiography.pending.type]: (state) => {
            state.isLoading = true;
        },
        [sendChainRadiography.fulfilled.type]: (state, { payload }: PayloadAction<ResponseChainType>) => {
            state.isLoading = false;
            state.chainTask = payload;
            state.error = '';
        },
        [sendChainRadiography.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.chainTask = '';
            state.error = payload;
        },
        [getChainStatus.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getChainStatus.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
        },
        [getChainStatus.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [stopChain.pending.type]: (state) => {
            state.isLoading = true;
        },
        [stopChain.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = false;
            state.chainTask = '';
            state.error = '';
        },
        [stopChain.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [getAvailableTasks.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getAvailableTasks.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = false;
            state.taskList = payload;
            state.error = '';
        },
        [getAvailableTasks.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
    }
});

export default TasksTypeSlice.reducer;
