import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { FishType, SectorResponseType } from './types';
import { createSector, deleteSector, getSectorsList, updateSector, getSectorById } from './actions';
import {IPagingSearch} from "../../../interfaces";

const { name } = options;

type FishState = {
    isLoading: boolean
    isTreeUpdate:boolean
    fishs: FishType[],
    error: string,
    sectorsList:SectorResponseType[],
    sectorById: null | SectorResponseType
};

const initialState: FishState = {
    isLoading: false,
    isTreeUpdate: false,
    fishs: [],
    error: '',
    sectorsList: [],
    sectorById: null
};

export const sectorSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearSectorsList(state) {
            state.sectorsList = [];
        },
        clearSectorById(state) {
            state.sectorById = null;
        },
    },
    extraReducers: {
        [getSectorsList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getSectorsList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<SectorResponseType>>) => {
            const sortedSectors = payload.results.sort((a, b) => a.begin! - b.begin!)
            state.isLoading = false;
            state.sectorsList = sortedSectors;
            state.error = '';
        },
        [getSectorsList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.sectorsList = [];
            state.error = payload;
        },
        [getSectorById.pending.type]: (state) => {
            state.isLoading = true;
            state.sectorById = null;
        },
        [getSectorById.fulfilled.type]: (state, { payload }: PayloadAction<SectorResponseType>) => {
            state.isLoading = false;
            state.sectorById = payload;
            state.error = '';
        },
        [getSectorById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.sectorById = null;
            state.error = payload;
        },
        [createSector.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false
        },
        [createSector.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
            state.isTreeUpdate = true
        },
        [createSector.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateSector.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false
        },
        [updateSector.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
            state.isTreeUpdate = true
        },
        [updateSector.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteSector.pending.type]: (state) => {
            state.isLoading = true;
            state.isTreeUpdate = false
        },
        [deleteSector.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
            state.isTreeUpdate = true
        },
        [deleteSector.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default sectorSlice.reducer;
