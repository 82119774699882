import * as React from 'react';
import clsx from 'clsx';
import { Button, makeStyles, Theme } from '@material-ui/core';

interface IPageBottomProps {
  onSave?: () => void;
  onCancel?: () => void;
  saveButtonId?: string;
  saveCaption?: string;
  cancelButtonId?: string;
  requiredValues?: any[];
  children?: React.ReactNode;
  noCancel?: boolean;
  disabled?: boolean;
  cancelDisabled?: boolean;
  noMargin?: boolean;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
    root: {
        margin: spacing(2),
        marginBottom: 0
    },
    noMargin: {
        margin: spacing(0)
    },
    saveButton: {
        marginRight: spacing(3),
        marginBottom: spacing(2)
    },
    button: {
        marginRight: spacing(3),
        marginBottom: spacing(2)
    }
}));

function FormButtons({
    onSave,
    onCancel,
    noCancel,
    saveButtonId = 'form-save-button',
    saveCaption = 'Сохранить',
    cancelButtonId = 'form-cancel-button',
    requiredValues = [],
    children,
    disabled,
    noMargin,
    cancelDisabled = false
}: IPageBottomProps) {
    const classes = useStyles();

    function computeDisabled(values: any[]) {
        for (let i = 0, n = values.length; i < n; i += 1) {
            if (values[i] === undefined || values[i] === null || values[i] === '') {
                return true;
            }
        }

        return false;
    }

    return (
        <div
            aria-label="form-buttons"
            className={clsx(classes.root, { [classes.noMargin]: noMargin })}
        >
            <Button
                id={saveButtonId}
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={onSave}
                disabled={disabled || computeDisabled(requiredValues)}
            >
                {saveCaption}
            </Button>
            {!noCancel && (
                <Button
                    id={cancelButtonId}
                    variant="outlined"
                    className={classes.button}
                    onClick={onCancel}
                    disabled={cancelDisabled}
                >
                  Отмена
                </Button>
            )}

            {children}
        </div>
    );
}

export default FormButtons;
