import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { generateRegisty } from './actions';

const { name } = options;

type RegistryState = {
    isLoading: boolean;
    registry: any;
    error: string;
};

const initialState: RegistryState = {
    isLoading: false,
    error: '',
    registry: null,
};

export const registrySlice = createSlice({
    name,
    initialState,
    reducers: {
        clearConclusion(state) {
            state.registry = null;
        },
    },
    extraReducers: {
        [generateRegisty.pending.type]: (state) => {
            state.isLoading = true;
        },
        [generateRegisty.fulfilled.type]: (
            state,
            { payload }: PayloadAction<Blob>
        ) => {
            state.isLoading = false;
            state.error = '';
            state.registry = payload;
        },
        [generateRegisty.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default registrySlice.reducer;
