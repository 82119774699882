import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDefectsByRadiographyIdApi, createDefectApi, deleteDefectApi } from "./api";
import options from './options';
import { DefectBaseType } from './types';

const { name } = options;

export const getDefectsByRadiographyId = createAsyncThunk(
    `${name}/getDefects`,
    async (radiography_id: string, { rejectWithValue }) => {
        try {
            return await getDefectsByRadiographyIdApi(radiography_id);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);

export const createDefect = createAsyncThunk(
    `${name}/createDefect`,
    async (body: DefectBaseType, thunkApi) => {
        try {
            return await createDefectApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);


export const deleteDefect = createAsyncThunk(
    `${name}/deleteDefect`,
    async (defect: string, { rejectWithValue }) => {
        try {
            return await deleteDefectApi(defect);
        } catch (e: any) {
            return rejectWithValue(e.message);
        }
    }
);