import request from 'utils/http/request';
import { DefectBaseType, DefectResponseType } from './types';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getDefectsByRadiographyIdApi = async (radiography_id: string): Promise<any> => {
    return request.get(url(`?radiography_item=${radiography_id}`));
}

export const createDefectApi = async (body: DefectBaseType): Promise<DefectResponseType> => {
    return request.post(url(''), body);
}

export const deleteDefectApi = async (defect: string): Promise<any> => {
    return request.delete(url(`${defect}/`));
}