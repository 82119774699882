import React, {ReactElement} from 'react';
import {Box, CircularProgress, makeStyles} from '@material-ui/core';

interface ILoadingOverlayProps {
    loading: boolean
    dialog?: boolean // увеличивает zIndex, если нужно использовать на диалоге
    children?: React.ReactElement | React.ReactElement[]
}

const useStyles = makeStyles(() => ({
    panel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: (props: any) => props.dialog ? 1311 : 11,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    }
}));

export const LoadingOverlay = ({ loading, dialog = false, children }: ILoadingOverlayProps): ReactElement => {
    const classes = useStyles(dialog);

    return (
        <>
            {children}
            {loading && (
                <Box className={classes.panel}>
                    <CircularProgress color="primary" />
                </Box>
            )}
        </>
    );
};
