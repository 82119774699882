import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { DictSteelGradeResponseType } from './types';
import {
    createDictSteelGrade, deleteDictSteelGrade, getDictSteelGradeById, getDictSteelGradeList, updateDictSteelGrade
} from "./actions";
import { IPagingSearch } from "../../../interfaces";

const {name} = options

type DictSteelGradeState = {
    dictSteelGradeList: DictSteelGradeResponseType[],
    isLoading: boolean
    error: string
};

const initialState: DictSteelGradeState = {
    dictSteelGradeList: [],
    isLoading: false,
    error: ''
};

export const DictSteelGradeSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearDictSteelGradeList(state) {
            state.dictSteelGradeList = [];
        }
    },
    extraReducers: {
        [getDictSteelGradeList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictSteelGradeList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<DictSteelGradeResponseType>>) => {
            state.isLoading = false;
            state.dictSteelGradeList = payload.results;
            state.error = '';
        },
        [getDictSteelGradeList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.dictSteelGradeList = [];
            state.error = payload;
        },
        [getDictSteelGradeById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictSteelGradeById.fulfilled.type]: (state, { payload }: PayloadAction<DictSteelGradeResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [getDictSteelGradeById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createDictSteelGrade.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createDictSteelGrade.fulfilled.type]: (state, { payload }: PayloadAction<DictSteelGradeResponseType>) => {
            state.isLoading = false;
            state.dictSteelGradeList.push(payload);
            state.dictSteelGradeList = state.dictSteelGradeList
                .sort((a: DictSteelGradeResponseType, b: DictSteelGradeResponseType) => a.value.localeCompare(b.value));
            state.error = '';
        },
        [createDictSteelGrade.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateDictSteelGrade.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateDictSteelGrade.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateDictSteelGrade.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteDictSteelGrade.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteDictSteelGrade.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteDictSteelGrade.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default DictSteelGradeSlice.reducer;
