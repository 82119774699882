import request from 'utils/http/request';
import { DictElementTypeBaseType, DictElementTypeResponseType } from './types';
import { IPagingSearch } from 'interfaces';
import { getUrl } from '../../utils';
import options from './options';

const {apiUrl} = options
const url = getUrl(apiUrl);

export const getDictElementTypeListApi = async (): Promise<IPagingSearch<DictElementTypeResponseType>> => {
    return request.get(url(''));
}

export const getDictElementTypeByIdApi = async (dictElementType_id:string): Promise<DictElementTypeResponseType> => {
    return request.get(url(dictElementType_id));
};

export const createDictElementTypeApi = async (body: DictElementTypeBaseType): Promise<DictElementTypeResponseType> => {
    return request.post(url(''), body);
}

export const updateDictElementTypeApi = async (uuid: string, body: DictElementTypeBaseType): Promise<DictElementTypeResponseType> => {
    return request.patch(url(`${uuid}/`), body);
}

export const deleteDictElementTypeApi = async (uuid: string): Promise<any> => {
    return request.delete(url(`${uuid}/`));
}
