import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { DictFactoryNameResponseType } from './types';
import {
    createDictFactoryName, deleteDictFactoryName, getDictFactoryNameById, getDictFactoryNameList, updateDictFactoryName
} from "./actions";
import { IPagingSearch } from "../../../interfaces";

const {name} = options

type DictFactoryNameState = {
    dictFactoryNameList: DictFactoryNameResponseType[],
    isLoading: boolean
    error: string
};

const initialState: DictFactoryNameState = {
    dictFactoryNameList: [],
    isLoading: false,
    error: ''
};

export const DictFactoryNameSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearDictFactoryNameList(state) {
            state.dictFactoryNameList = [];
        }
    },
    extraReducers: {
        [getDictFactoryNameList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictFactoryNameList.fulfilled.type]: (state, { payload }: PayloadAction<IPagingSearch<DictFactoryNameResponseType>>) => {
            state.isLoading = false;
            state.dictFactoryNameList = payload.results;
            state.error = '';
        },
        [getDictFactoryNameList.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.dictFactoryNameList = [];
            state.error = payload;
        },
        [getDictFactoryNameById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDictFactoryNameById.fulfilled.type]: (state, { payload }: PayloadAction<DictFactoryNameResponseType>) => {
            state.isLoading = false;
            state.error = '';
        },
        [getDictFactoryNameById.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [createDictFactoryName.pending.type]: (state) => {
            state.isLoading = true;
        },
        [createDictFactoryName.fulfilled.type]: (state, { payload }: PayloadAction<DictFactoryNameResponseType>) => {
            state.isLoading = false;
            state.dictFactoryNameList.push(payload);
            state.dictFactoryNameList = state.dictFactoryNameList
                .sort((a: DictFactoryNameResponseType, b: DictFactoryNameResponseType) => a.value.localeCompare(b.value));
            state.error = '';
        },
        [createDictFactoryName.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateDictFactoryName.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateDictFactoryName.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateDictFactoryName.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteDictFactoryName.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteDictFactoryName.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteDictFactoryName.rejected.type]: (state, { payload }: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
});

export default DictFactoryNameSlice.reducer;
