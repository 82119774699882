import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DictSteelGradeResponseType } from 'models/dictionaries/dict-steel-grade/types';

interface IDictSteelGradeDialogProps {
    steelGradeData: DictSteelGradeResponseType | null;
    onClose: () => void;
    onDelete: (worker: DictSteelGradeResponseType) => void;
}

export const SteelGradeDeletingDialog: React.FC<IDictSteelGradeDialogProps> = ({
    steelGradeData,
    onClose,
    onDelete,
}) => {
    const [visible, setVisible] = useState<boolean>(false);

    const handleClose = () => {
        setVisible(false);
        onClose();
    };

    useEffect(() => {
        if (steelGradeData) {
            setVisible(true);
        }
    }, [steelGradeData]);

    const footerContent = (
        <div className="flex w-full justify-content-end">
            <Button
                severity="danger"
                text
                label="Удалить"
                className="mr-3"
                icon="pi pi-trash"
                size="small"
                onClick={() => onDelete(steelGradeData!)}
            />
            <Button label="Оставить" onClick={handleClose} size="small" />
        </div>
    );

    return (
        <Dialog
            header="Удаление марки стали"
            visible={visible}
            style={{ width: '25vw' }}
            onHide={handleClose}
            footer={footerContent}
        >
            {steelGradeData && (
                <div className="text-sm font-normal mt-1">
                    Вы уверены, что хотите удалить марку стали:{' '}
                    {steelGradeData.value} ?
                </div>
            )}
        </Dialog>
    );
};
